import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppContainer from '../../AppContainer';
import PageHead from '../../Common/PageHead/Index';
import { getProductDetailAction } from '../../Vouchers/services/users';
import Index from './Index';


function ProductSummary() {
  let token = sessionStorage.getItem('userToken');
  const dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    dispatch(getProductDetailAction(id, token))
  }, [dispatch, id, token])

  return (
    <AppContainer>
      <PageHead
        title="Product"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          { path: "/", breadcrumbName: "Products" },
          // { path: "/", breadcrumbName: `${Dummy.CartId}` },
        ]}
        bgNone={true}
        subTitle="Summary">
        <div>
          <Index />
        </div>
      </PageHead>
    </AppContainer>
  )
}

export default ProductSummary;