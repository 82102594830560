import { Button, Col, Modal, Row, Typography } from 'antd';
import { createSecretAccessKey, getSecretAccessKey } from '../Vouchers/services/users';
import Notification from '../Common/costant/Notification';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PageHead from '../Common/PageHead/Index';
import { useNavigate } from 'react-router-dom';
import AppContainer from '../AppContainer';
const { Paragraph } = Typography;

const UserKeys = () => {
  let token = sessionStorage.getItem('userToken');
  const [modal2Open, setModal2Open] = useState(false);
  const [tokenData, setTokenData] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userKeys = useSelector((item) => item?.rootReducer?.userKeys);

  useEffect(() => {
    getUserKeys();
  }, []);

  const getUserKeys = async () => {
    let resp = await dispatch(getSecretAccessKey(token));
  };

  useEffect(() => {
    if (Object.keys(userKeys).length) {
      setModal2Open(true);
      setTokenData(userKeys);
    }
  }, [userKeys]);

  // make api call here
  // const handleSubmit = async () => {
  //   // const secketKey = await require('crypto').randomBytes(48).toString('base64');
  //   const resData = await dispatch(createSecretAccessKey(token));

  //   if (resData?.status === 200 && resData?.message === 'Successfully get user keys') {
  //     Notification('success', resData?.message);
  //     setModal2Open(true);
  //     setTokenData(resData?.data);
  //   } else if (resData?.status !== 200) {
  //     Notification('error', resData?.message);
  //   }
  // };

  console.log(tokenData, 'tokenDatatokenData', userKeys);

  return (
    <AppContainer>
      <PageHead
        title="User Keys"
        breadcrumbs={[
          { path: '/', breadcrumbName: 'Home' },
          {
            path: '/',
            breadcrumbName: 'User keys',
          },
        ]}
        bgNone={true}
        subTitle=""
      >
        <div className="">
          {tokenData ? (
            <Modal
              title="Your Secret & Access Key"
              // visible={modal2Open}
              centered
              open={modal2Open}
              // onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
              footer={false}
            >
              <div>
                <Paragraph
                  copyable={{
                    text: tokenData?.accessKey,
                    tooltips: ['click here to copy', 'Copied!!'],
                  }}
                >
                  <h4>Access Key</h4>
                  {tokenData?.accessKey}
                </Paragraph>
                <Paragraph
                  copyable={{
                    text: tokenData?.secretKey,
                    tooltips: ['click here to copy', 'Copied!!'],
                  }}
                >
                  <h4>Secret Key</h4>
                  {tokenData?.secretKey}
                </Paragraph>
                <Col lg={24} sm={24} xl={24}>
                  <div className="flex justify-end mt-4">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => navigate(`/campaign`)}
                      size="large"
                      style={{ background: '#1890ff' }}
                    >
                      Create Voucher
                    </Button>
                  </div>
                </Col>
              </div>
            </Modal>
          ) : (
            <div className="flex items-center justify-center bg-white w-2/4 p-10">
              <p>No key found..</p>
            </div>
          )}
        </div>
      </PageHead>
    </AppContainer>
  );
};

export default UserKeys;
