import React from 'react';
import QRCode from "react-qr-code";

function VoucherQrCode() {
  return (
    <div>
      <div className='my-0 mx-auto max-w-[210px]'>
        <QRCode
          // size={256}
          className='max-w-full'
          value="https://www.google.com/"
        // viewBox={`0 0 256 256`}
        />
      </div>
    </div>
  )
}

export default VoucherQrCode;