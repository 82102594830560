/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// function DiscountToItem({ setSelectedListItems, initialValue, itemKey, visible }) {
function DiscountToItem({ exclued, included, selectedOptions, setSelectedOptions }) {

  const { discountedItems, excludedItems } = useSelector((state) => state?.vouchers?.third);
  const initialValue = useSelector(state => state?.rootReducer?.data);

  // const reducerDataHandler = () => {
  //   if (discountedItems.length > 0) {
  //     return ([...discountedItems])
  //   } else if (excludedItems.length > 0) {
  //     return ([...excludedItems])
  //   } else {
  //     return []
  //   }
  // };

  // const getReducerData = reducerDataHandler();

  // const [checkedList, setCheckedList] = useState(getReducerData);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);


  const userData = [
    {
      checkedItem: false,
      name: "Item1",
      email: "smith123@gmail.com",
      gender: "male",
      nat: "ut"
    },
    {
      checkedItem: false,
      name: "Item2",
      email: "johan123@gmail.com",
      gender: "male",
      nat: "xt"
    },
    {
      checkedItem: false,
      name: "Item3",
      email: "sammy123@gmail.com",
      gender: "male",
      nat: "dy"
    },
    {
      checkedItem: false,
      name: "Item4",
      email: "julie123@gmail.com",
      gender: "female",
      nat: "qi"
    },
    {
      checkedItem: false,
      name: "Item5",
      email: "garry123@gmail.com",
      gender: "male",
      nat: "fo"
    },
    {
      checkedItem: false,
      name: "Item6",
      email: "jackie123@gmail.com",
      gender: "female",
      nat: "lt"
    },
    {
      checkedItem: false,
      name: "perry",
      email: "perry123@gmail.com",
      gender: "female",
      nat: "mw"
    },
  ];

  const onChange = (e) => {
    setCheckedList([...checkedList, e.target.name]);
    if (!e.target.checked) {
      setCheckedList(checkedList.filter(item => item !== e.target.name));
    }
    setSelectedOptions([...selectedOptions, e.target.name]);
    if (!e.target.checked) {
      setSelectedOptions(selectedOptions.filter(item => item !== e.target.name));
    }
  };

  const onCheckAllChange = () => {
    setCheckAll(!checkAll);
    setSelectedOptions(userData?.map(li => li.name))
    if (checkAll) {
      setSelectedOptions([]);
    }
  }

  // useEffect(() => {
  //   setSelectedOptions(checkedList?.filter((item) => { return item !== undefined }));
  // }, [checkedList]);


  // const orderSummaryHandler = () => {
  //   if (checkedList?.length !== 0) {
  //     return checkedList
  //   } else if (checkedList?.length === 0 && included === true) {
  //     return discountedItems
  //   } else if (checkedList?.length === 0 && exclued === true) {
  //     return excludedItems
  //   }
  // };

  // console.log(orderSummaryHandler(), "orderSummaryHandler")


  return (
    <div>
      <>
        <div>
          <label className="mb-5">
            <input
              type="checkbox"
              onChange={onCheckAllChange}
              checked={checkAll}
            />
            check all
          </label>
        </div>
        <div className="flex">
          <div className="block py-2 px-4 w-full">
            <ul className="w-48 text-sm font-medium">
              {
                userData && userData?.map((item) => (
                  <li className="py-2 px-4 w-full rounded-t-lg">
                    <input
                      className="m-3"
                      name={item.name}
                      type="checkbox"
                      onChange={(e) => onChange(e)}
                      // checked={orderSummaryHandler()?.includes(item?.name)}
                      checked={selectedOptions?.includes(item?.name)}
                    />
                    <span>{item.name}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </>
    </div>
  )
}

export default DiscountToItem;