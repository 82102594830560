import { message } from "antd";
import axios from "../Components/interceptor/interceptor";
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const getAPiUrl = () => {
  if (window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://localhost:3001') {
    return 'https://voucherify-back.enetdefender.com';
  } else {
    return 'https://voucherify-back.enetdefender.com';
  }
}

export default async function request(url, options) {
  const response = await axios({
    method: options.method,
    url: `${getAPiUrl() + url}`,
    data: options?.data,
    headers: { Authorization: `Bearer ${options?.token}` }
  })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data
    });
  return response;
}
