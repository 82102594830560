/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import discount from '../../../assets/icons/discountCoupon.png';
import giftImage from '../../../assets/icons/gift3.png'
import referralImage from '../../../assets/icons/referral.png'
import promotionImg from '../../../assets/icons/promotion.png'
import loyalityImg from '../../../assets/icons/heart1.png'


const CompaignTemplate = () => {
  let navigate = useNavigate();

  const redirectUrl = (e, campaign) => {
    e.preventDefault();
    navigate(`/campaign/create/${campaign.url}`,
      { state: { campaignName: campaign.url }, },
    );
  }

  const campaignType = [
    {
      id: 1,
      title: 'Discount Coupons',
      url: 'discountCoupons',
      imageSrc: discount,
      subTitle: 'Personal use or public channels',
      description: 'Issue one-off, personalised discount codes for personal use (e.g. HELLO4543) or publish a public fixed-code voucher across your messaging channels (e.g. BlackFriday19)'
    },
    {
      id: 2,
      title: 'Gift Vouchers',
      url: 'giftVouchers',
      imageSrc: giftImage,
      subTitle: 'Personal use or public channels',
      description: 'Publish digital gift cards as unique one-off codes aimed at particular customers or launch a public, reusable gift card certificates that act as digital wallets'
    },
    {
      id: 3,
      title: 'Referral Codes',
      url: 'referralCodes',
      imageSrc: referralImage,
      subTitle: 'Friends refer friends',
      description: 'Multi-tiered referral program with rewards for referrers and invited customers (e.g., $20 for you and every invited friend, $50 if you get more than five) '
    },
    {
      id: 4,
      title: 'Promotion',
      url: 'promotion',
      imageSrc: promotionImg,
      subTitle: 'Discounts without code',
      description: 'Automatically applied discount based on shopping cart structure and customer attributes (e.g., 5% off if total order amount exceeds $200)'
    },
    {
      id: 5,
      title: 'Loyalty Program',
      url: 'loyalityProgram',
      imageSrc: loyalityImg,
      subTitle: 'Rewards for points',
      description: 'Use points to motivate action at every stage of your customer journey'
    }
  ];

  return (
    <Row gutter={50} className='row'>
      {campaignType.map(campaign => (
        <Col lg={8} md={12} sm={24} style={{ marginBottom: '30px', paddingLeft: '15px', paddingRight: '15px' }} key={campaign?.id} className="Card">
          <div className="p-6 bg-white border text- border-blue-200 rounded-lg shadow-md">
            <img alt="" src={campaign?.imageSrc} style={{ height: '64px', width: '64px', margin: 'auto', marginBottom: '20px' }} />
            <p className="subTitle mt-2 text-sm font-normal tracking-tight text-[#646464]">{campaign.subTitle}</p>
            <Link to="#">
              <h5 className=" mt-4 mb-2 text-2xl font-medium tracking-tight text-[#0560aa]">{campaign.title}</h5>
            </Link>
            <p className=" para mb-3 font-normal text-[#646464]" style={{ height: '130px' }}>{campaign.description}</p>
            {/* <div className='cursor-pointer' style={{ position: 'relative', top: '12px' }} > */}
            <button onClick={(e) => redirectUrl(e, campaign)} className="inline-flex items-center float-right px-3 py-2 -mt-[20px] -mr-[14px] text-sm font-medium text-right border-dashed border-[1px] border-blue-500 text-blue-500 bg-white rounded-sm hover:bg-blue-500 hover:text-white">
              Create
              <svg className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
            </button>
            {/* </div> */}
          </div>
        </Col>
      )
      )}
    </Row>
  )
}

export default CompaignTemplate
