import moment from 'moment';

/**======== selected campaign */
export const campaignNameHandle = (campaign) => {
  if (campaign === 'discountCoupons') {
    return 'Discount Coupons'
  } else if (campaign === 'giftVouchers') {
    return 'Gift Vouchers'
  } else if (campaign === 'referralCodes') {
    return 'Referral Codes'
  } else if (campaign === 'promotion') {
    return 'Promotion'
  } else {
    return 'Loyalty Program'
  }
}
/*-------- program size defaultValue function.-------*/

export const getProgramType = (value, coupne) => {
  if (value && value?.programSize?.programType === 'bulk') {
    return { bulk: coupne }
  } else if (value && value?.programSize?.programType === 'standalone') {
    return { standard: coupne }
  } else if (value === 'undefined') {
    return
  }
}
/*===== common handler for first campaign ======= */

export const getInitialValue = (value) => {
  if (value) {
    return value
  } else {
    return
  }
}
export const getJoiningHandle = (value) => {
  if (value?.programSize?.joiningAllowedOnce === true) {
    return true
  } else {
    return false
  }
}

export const voucherTypeHandle = (value) => {
  if (value === 'alphabetic' || !value) {
    return {
      character: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      type: 'alphabetic',
      types: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    }
  }
  else if (value === 'alphabeticUppercase') {
    return {
      character: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      type: 'alphabetic',
      types: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    }
  }
  else if (value === 'alphabeticLowercase') {
    return {
      character: 'abcdefghijklmnopqrstuvwxyz',
      type: 'alphabetic',
      types: 'abcdefghijklmnopqrstuvwxyz',
    }
  }
  else if (value === 'alphanumeric') {
    return {
      character: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      type: 'alphanumeric',
      types: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    }
  } else if (value === 'numbers') {
    return {
      character: '0123456789',
      type: 'numbers',
      types: '0123456789',
    }
  }
};
export const checkPattern = (pattern, codeCount) => {
  let flag = false;
  if (pattern) {
    for (let i = 0; i < pattern.length; i++) {
      if (/^[#-]+$/.test(pattern[i])) {
        flag = true;
      } else if (codeCount > 1) {
        return false;
      } else {
        return false;
      }
    }
  }
  return flag;
}

export const errorHandler = () => {
  return (
    <p style={{ color: 'red' }}> This Field Is Required</p>
  )
}

/*================= time frame defaultValue function. ==============*/

export const getInitialTimeframe = (value) => {
  let demo = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
  if (demo) {
    return demo
  } else {
    return
  }
}
export const getValidTimeFrame = (value, reducerData, Frame) => {
  if (value?.timeFrame?.validTimeFrame?.duration || value?.timeFrame?.validTimeFrame?.time || value?.timeFrame?.validTimeFrame?.redemption) {
    return !Frame
    // } else if (reducerData?.duration || reducerData?.time || reducerData?.redemption) {
    //   return !Frame
  } else {
    return Frame
  }
};
export const getspecificTiming = (value, reducerData, check) => {
  if (value?.timeFrame?.specificTiming?.activeTime || value?.timeFrame?.specificTiming?.timeTypes) {
    return !check
    // } else if (reducerData?.activeTime || reducerData?.timeTypes) {
    //   return !check
  } else {
    return check
  }
};
export const getParticularDay = (value, reducerData, check) => {
  if (value?.timeFrame?.onParticularDay?.particularDays?.length >= 1) {
    return !check
    // } else if (reducerData?.particularDays) {
    //   return !check
  } else {
    return check
  }
};
export const getFormatDate = (value) => {
  let demo = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
  if (demo) {
    return demo
  } else {
    return
  }
}
export const getStartTime = (value) => {
  if (value?.timeFrame?.startTime) {
    return value?.timeFrame?.startTime
  } else {
    return
  }
}
// export const getEndDate = (value) => {
//   let getDate = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
//   if (getDate) {
//     return getDate
//   } else {
//     return
//   }
// }
export const getEndTime = (value) => {
  if (value?.timeFrame?.endTime) {
    return value?.timeFrame?.endTime
  } else {
    return
  }
}
export const getTimeDuration = (value) => {
  if (value?.timeFrame?.validTimeFrame?.duration) {
    return value?.timeFrame?.validTimeFrame?.duration
  } else {
    return
  }
}
export const getFrameTime = (value) => {
  if (value?.timeFrame?.validTimeFrame?.time) {
    return value?.timeFrame?.validTimeFrame?.time
  } else {
    return
  }
}
export const getFrameRedemption = (value) => {
  if (value?.timeFrame?.validTimeFrame?.redemption) {
    return value?.timeFrame?.validTimeFrame?.redemption
  } else {
    return
  }
}
export const getSelectedDay = (initialValue, secondStep, particular) => {
  if (initialValue && initialValue?.timeFrame?.onParticularDay?.particularDays?.length > 0) {
    let myArr = []
    const initialData = particular?.filter((d) => (initialValue?.timeFrame?.onParticularDay?.particularDays?.includes(d.label)) && myArr.push(d.label));
    return initialData
    // } else if (secondStep && secondStep?.particularDays?.length > 0) {
    //   let myArr = []
    //   const data = particular?.filter((d) => (secondStep?.particularDays?.includes(d.label)) && myArr.push(d.label));
    //   return data
  } else {
    return
  }
}
export const getActiveTime = (value) => {
  if (value?.timeFrame?.specificTiming?.activeTime) {
    return value?.timeFrame?.specificTiming?.activeTime
  } else {
    return
  }
}
export const getActiveType = (value) => {
  if (value?.timeFrame?.specificTiming?.timeTypes) {
    return value?.timeFrame?.specificTiming?.timeTypes
  } else {
    return
  }
}
export const checkTimeframeHandle = (value) => {
  if (value?.startDate !== undefined || value?.startTime !== undefined || value?.endDate !== undefined ||
    value?.endTime !== undefined || value?.activeTime !== undefined || value?.timeTypes !== undefined ||
    value?.duration !== undefined || value?.redemption !== undefined || value?.time !== undefined ||
    value?.particularDays !== undefined) {
    return value
  } else {
    return
  }
}

/*-----error handler function----*/

export const timeframeErrorHandler = (interval, time, duration, value) => {
  // console.log(interval, 'interval', time, 'time', duration, 'duration', value, 'aaaaaaa')
  if (interval && time === 'Day' && duration >= interval && value === 'Day') {
    return true
  } else if (interval && time === 'Hour' && duration && value === 'Day') {
    return true
  } else if (interval && time === 'Hour' && duration >= interval && value === 'Hour') {
    return true
  } else if (interval && time === 'Day' && duration >= 24 * interval && value === 'Hour') {
    return true
  }
  else {
    return false
  }
}
// export const timeframeShowErrorHandle = () => {

// }
/*=------- amount type defaultvalue function ------*/

export const discountTypeHandler = (initialValue, campaign, updateVoucher) => {
  if (campaign && !updateVoucher) {
    return 'Amount type'
  } else if (updateVoucher && initialValue?.discountType === 'amountType') {
    return 'Amount type'
  } else if (updateVoucher && initialValue?.discountType === 'percentType') {
    return 'Percentage type'
  }
  // if (campaign === 'Discount Coupons') {
  //   if (initialValue?.discountType === "Amount type") {
  //     return 'Amount type'
  //   } else if (initialValue?.discountType === 'Percentage type') {
  //     return 'Percentage type'
  //   } else if (!isNaN(initialValue?.fixedAmount?.fixedAmount)) {
  //     return 'Fixed amount'
  //   } else if (initialValue?.unitType?.unitDiscountTo) {
  //     return 'Unit type'
  //   } else if (initialValue?.freeShipping?.shippingDiscountTo) {
  //     return 'Free shipping'
  //   } else {
  //     return 'Amount type'
  //   }
  // } else if (campaign === 'Gift Vouchers') {
  // } else if (campaign === 'Referral Codes') {
  //   return 'Amount type'
  // } else if (campaign === 'Promotion') {
  // } else if (campaign === 'Loyalty Program') {
  // }
}

export const selectDiscountType = (campaign) => {
  if (campaign === 'Discount Coupons') {
    return 'Amount type'
  } else if (campaign === 'Referral Codes') {
    return 'Amount type'
  }

}
// export const discountedProductHandler=(initialValue)=>{
//  if(initialValue.discountValue.wholecart)
// }
export const getAmountAttributeData = (value) => {
  if (value?.discountValue?.wholecart?.attributeMatch?.orderMeteData) {
    return { data: value?.discountValue?.wholecart?.attributeMatch?.orderMeteData }
  } else {
    return { data: value?.discountValue?.wholecart?.attributeMatch?.redemptionMetaData }
  }
};
export const customerActivityHandler = (value, reducerData) => {
  if (value && value?.discountValue?.wholecart?.customActivity?.acitivityCondition === true) {
    return true
  } else if (value && value?.discountValue?.wholecart?.customActivity?.acitivityCondition === false) {
    return false
    // } else if (reducerData && reducerData?.acitivityCondition === 'true') {
    //   return ('true')
    // } else if (reducerData && reducerData?.acitivityCondition === 'false') {
    //   return ('false')
  } else {
    return
  }
}
export const getDiscountValue = (value, reducerData) => {
  if (value.campaignType === "referralCodes") {
    return value.referrerAmount
  } else if (value.campaignType !== "referralCodes" && value?.maxDiscountAmount) {
    return value?.maxDiscountAmount
    // } else if (reducerData?.discountValue) {
    //   return reducerData?.discountValue
  } else {
    return
  }
}

export const inputLabelHandle = (type, campaign, initial) => {
  if (type === 'amount' && campaign === 'referralCodes' || initial.campaignType === "referralCodes") {
    return 'Referee amount (USD)'
  } else if (type === 'amount' && campaign === 'discountCoupons' || initial.campaignType === "discountCoupons") {
    return 'Discount value (USD)'
  } else if (type === 'amount' && campaign === 'giftVouchers' || initial.campaignType === "giftVouchers") {
    return 'Initial amount (USD)'
  }
}

export const getDiscountAmount = (value, reducerData) => {
  if (value?.maxDiscountAmount) {
    return value?.maxDiscountAmount
    // } else if (reducerData?.discountValue) {
    //   return reducerData?.discountValue
  } else {
    return
  }
}
export const getpercentageDiscount = (value, reducerData) => {
  if (value?.percentageDiscount) {
    return value?.percentageDiscount
    // } else if (reducerData?.discountValue) {
    //   return reducerData?.discountValue
  } else {
    return
  }
}

export const getPercentageDisc = (value, reducerData) => {
  if (value.campaignType === "referralCodes") {
    return value.refereeAmount
  } else if (value.campaignType !== "referralCodes" && value?.percentageDiscount) {
    return value?.percentageDiscount
    // } else if (reducerData?.discountValue) {
    //   return reducerData?.discountValue
  } else {
    return
  }
}

export const getCheckedStatus = (value, reducerData, selectedDiscountTo) => {
  if (value && value?.applyDiscountOn === "quantityProportionally") {
    return { quantity: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountTo === "quantityProportionally") {
    //   return { quantity: selectedDiscountTo }
  } else if (value && value?.applyDiscountOn === "wholeCart") {
    return { whole: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountTo === "wholeCart") {
    //   return { whole: selectedDiscountTo }
  } else if (value && value?.applyDiscountOn === "items") {
    return { items: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountTo === "items") {
    //   return { items: selectedDiscountTo }
  } else if (value && value?.applyDiscountOn === "amountProportionally") {
    return { amount: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountTo === "amountProportionally") {
    //   return { amount: selectedDiscountTo }
  } else {
    return { whole: selectedDiscountTo }
  }
}
export const getBudgetTotalOrder = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.totalOrderValue) {
    return value?.discountValue?.wholecart?.budget?.totalOrderValue
    // } else if (reducerData?.totalOrderValue) {
    //   return reducerData?.totalOrderValue
  } else {
    return
  }
}
export const getBudgetDiscount = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.totalDiscountAmount) {
    return value?.discountValue?.wholecart?.budget?.totalDiscountAmount
    // } else if (reducerData?.totalDiscountAmount) {
    //   return reducerData?.totalDiscountAmount
  } else {
    return
  }
}
export const getBudgetRedemption = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.totalNumberOfRedemption) {
    return value?.discountValue?.wholecart?.budget?.totalNumberOfRedemption
    // } else if (reducerData?.totalNumberOfRedemption) {
    //   return reducerData?.totalNumberOfRedemption
  } else {
    return
  }
}
export const getBudgetPercode = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.perCode) {
    return value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.perCode
    // } else if (reducerData?.perCode) {
    //   return reducerData?.perCode
  } else {
    return
  }
}
export const getBudgetPerday = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.perCodePerDay
    // } else if (reducerData?.perCodePerDay) {
    //   return reducerData?.perCodePerDay
  } else {
    return
  }
}
export const getBudgetCampaign = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.discountValue?.wholecart?.budget?.redemptionPerCustomer?.inCampaign
    // } else if (reducerData?.inCampaign) {
    //   return reducerData?.inCampaign
  } else {
    return
  }
}
export const getOrderCondition = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.order?.totalAmount?.condition) {
    return value?.discountValue?.wholecart?.order?.totalAmount?.condition
    // } else if (reducerData?.condition) {
    //   return reducerData?.condition
  } else {
    return
  }
}
export const getOrderValue = (value, reducerData) => {
  if (value?.discountValue?.wholecart?.order?.totalAmount?.value) {
    return value?.discountValue?.wholecart?.order?.totalAmount?.value
    // } else if (reducerData?.value) {
    //   return reducerData?.value
  } else {
    return
  }
}

/*-------- percentage type defaultvalue function ---------*/

export const getCheckPercentageStatus = (value, reducerData, selectedDiscountTo) => {
  if (value && value?.percentageType?.applyDiscountOn === 'wholeCart') {
    return { whole: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountOn === 'wholeCart') {
    //   return { whole: selectedDiscountTo }
  } else if (value && value?.percentageType?.applyDiscountOn === 'items') {
    return { items: selectedDiscountTo }
    // } else if (reducerData && reducerData?.applyDiscountOn === 'items') {
    //   return { items: selectedDiscountTo }
  } else {
    return
  }
}
export const getPercentageAttributeData = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.attributeMatch?.orderMeteData) {
    return { data: value?.percentageType?.wholeCart?.attributeMatch?.orderMeteData }
  } else {
    return { data: value?.percentageType?.wholeCart?.attributeMatch?.redemptionMetaData }
  }
}
export const getPercentageValue = (value, reducerData) => {
  if (value?.percentageType?.percentageValue) {
    return value?.percentageType?.percentageValue
    // } else if (reducerData?.percentageValue) {
    //   return reducerData?.percentageValue
  } else {
    return
  }
}
export const getPercentageLimit = (value, reducerData) => {
  if (value?.percentageType?.percentageLimit) {
    return value?.percentageType?.percentageLimit
    // } else if (reducerData?.percentageLimit) {
    //   return reducerData?.percentageLimit
  } else {
    return
  }
}
export const getPercentageActivity = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.customActivity?.acitivityCondition === true) {
    return true
  } else if (value?.percentageType?.wholeCart?.customActivity?.acitivityCondition === false) {
    return false
    // } else if (reducerData?.acitivityCondition) {
    //   return reducerData?.acitivityCondition
  } else {
    return
  }
}
export const getPercentageBudgetOrder = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.totalOrderValue) {
    return value?.percentageType?.wholeCart?.budget?.totalOrderValue
    // } else if (reducerData?.totalOrderValue) {
    //   return reducerData?.totalOrderValue
  } else {
    return
  }
}
export const getPercentageBudgetDiscount = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.totalDiscountAmount) {
    return value?.percentageType?.wholeCart?.budget?.totalDiscountAmount
    // } else if (reducerData?.totalDiscountAmount) {
    //   return reducerData?.totalDiscountAmount
  } else {
    return
  }
}
export const getPercentageBudgetRedemption = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.totalNumberOfRedemption) {
    return value?.percentageType?.wholeCart?.budget?.totalNumberOfRedemption
    // } else if (reducerData?.totalNumberOfRedemption) {
    //   return reducerData?.totalNumberOfRedemption
  } else {
    return
  }
}
export const getPercentageBudgetPercode = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.perCode) {
    return value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.perCode
    // } else if (reducerData?.perCode) {
    //   return reducerData?.perCode
  } else {
    return
  }
}
export const getPercentageBudgetPerday = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.perCodePerDay
    // } else if (reducerData?.perCodePerDay) {
    //   return reducerData?.perCodePerDay
  } else {
    return
  }
}
export const getPercentageBudgetCampaign = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.percentageType?.wholeCart?.budget?.redemptionPerCustomer?.inCampaign
    // } else if (reducerData?.inCampaign) {
    //   return reducerData?.inCampaign
  } else {
    return
  }
}
export const getPercentageOrderCondition = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.order?.totalAmount?.condition) {
    return value?.percentageType?.wholeCart?.order?.totalAmount?.condition
    // } else if (reducerData?.condition) {
    //   return reducerData?.condition
  } else {
    return
  }
}
export const getPercentageOrderValue = (value, reducerData) => {
  if (value?.percentageType?.wholeCart?.order?.totalAmount?.value) {
    return value?.percentageType?.wholeCart?.order?.totalAmount?.value
    // } else if (reducerData?.value) {
    //   return reducerData?.value
  } else {
    return
  }
}
export const applyDiscountHandler = (initialValue, reducerData, isChecked) => {
  if (initialValue && initialValue?.percentageType?.wholeCart?.discount?.discountOn === "Each") {
    return { each: isChecked }
    // } else if (reducerData && reducerData?.discountOn === "Each") {
    //   return { each: isChecked }
  } else if (initialValue && initialValue?.percentageType?.wholeCart?.discount?.discountOn === "Selected") {
    return { select: isChecked }
    // } else if (reducerData && reducerData?.discountOn === "Selected") {
    //   return { select: isChecked }
  } else {
    return
  }
};
/*---------Fixed Amount default value function ---------*/

export const getCheckedFixedStatus = (value, reducerData, selectedDiscountTo) => {
  if (value && value?.fixedAmount?.fixedDiscountTo === "toOrders") {
    return { order: selectedDiscountTo }
    // } else if (reducerData && reducerData?.fixedDiscountTo === "toOrders") {
    //   return { order: selectedDiscountTo }
  } else if (value && value?.fixedAmount?.fixedDiscountTo === "toItems") {
    return { items: selectedDiscountTo }
    // } else if (reducerData && reducerData?.fixedDiscountTo === "toItems") {
    //   return { items: selectedDiscountTo }
  } else {
    return
  }
}
export const getAttributeData = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.attributeMatch?.orderMeteData) {
    return { data: value?.fixedAmount?.applyEffect?.toItems?.attributeMatch?.orderMeteData }
  } else if (value?.fixedAmount?.applyEffect?.toItems?.attributeMatch?.redemptionMetaData) {
    return { data: value?.fixedAmount?.applyEffect?.toItems?.attributeMatch?.redemptionMetaData }
  } else if (value?.fixedAmount?.applyEffect?.toOrders?.attributeMatch?.orderMeteData) {
    return { data: value?.fixedAmount?.applyEffect?.toOrders?.attributeMatch?.orderMeteData }
  } else if (value?.fixedAmount?.applyEffect?.toOrders?.attributeMatch?.redemptionMetaData) {
    return { data: value?.fixedAmount?.applyEffect?.toOrders?.attributeMatch?.redemptionMetaData }
  }
};
export const getFixedAmountCondition = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.customActivity?.acitivityCondition) {
    return value?.fixedAmount?.applyEffect?.toItems?.customActivity?.acitivityCondition
    // } else if (reducerData?.acitivityCondition) {
    //   return reducerData?.acitivityCondition
  } else {
    return
  }
}
export const getFixedAmountBudgetOrder = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.totalOrderValue) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.totalOrderValue
    // } else if (reducerData?.totalOrderValue) {
    //   return reducerData?.totalOrderValue
  } else {
    return
  }
}
export const getFIxedAmountBudgetDiscount = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.totalDiscountAmount) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.totalDiscountAmount
    // } else if (reducerData?.totalDiscountAmount) {
    //   return reducerData?.totalDiscountAmount
  } else {
    return
  }
}
export const getFixedAmountBudgetRedemption = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.totalNumberOfRedemption) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.totalNumberOfRedemption
    // } else if (reducerData?.totalNumberOfRedemption) {
    //   return reducerData?.totalNumberOfRedemption
  } else {
    return
  }
}
export const getFIxedAmountBudgetPercode = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.perCode) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.perCode
    // } else if (reducerData?.perCode) {
    //   return reducerData?.perCode
  } else {
    return
  }
}
export const getFixedAmountBudgetPerday = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.perCodePerDay
    // } else if (reducerData?.perCodePerDay) {
    //   return reducerData?.perCodePerDay
  } else {
    return
  }
}
export const getFixedAmountBudgetCampaign = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.fixedAmount?.applyEffect?.toItems?.budget?.redemptionPerCustomer?.inCampaign
    // } else if (reducerData?.inCampaign) {
    //   return reducerData?.inCampaign
  } else {
    return
  }
}
export const getFixedOrderCondition = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.order?.totalAmount?.condition) {
    return value?.fixedAmount?.applyEffect?.toItems?.order?.totalAmount?.condition
    // } else if (reducerData?.condition) {
    //   return reducerData?.condition
  } else {
    return
  }
}
export const getFixedOrderValue = (value, reducerData) => {
  if (value?.fixedAmount?.applyEffect?.toItems?.order?.totalAmount?.value) {
    return value?.fixedAmount?.applyEffect?.toItems?.order?.totalAmount?.value
    // } else if (reducerData?.value) {
    //   return reducerData?.value
  } else {
    return
  }
}
export const getFixedAmount = (value, reducerData) => {
  if (value?.fixedAmount?.fixedAmount) {
    return value?.fixedAmount?.fixedAmount
    // } else if (reducerData?.fixedAmount) {
    //   return reducerData?.fixedAmount
  } else {
    return
  }
}

/*---------- unit type defaultValue function -----------*/

export const getCheckedUnitStatus = (value, reducerData, selectedDiscountTo) => {
  if (value && value?.unitType?.unitDiscountTo === "addMissingItems") {
    return { missing: selectedDiscountTo }
    // } else if (reducerData && reducerData?.unitDiscountTo === "addMissingItems") {
    //   return { missing: selectedDiscountTo }
  } else if (value && value?.unitType?.unitDiscountTo === "addNewItems") {
    return { items: selectedDiscountTo }
    // } else if (reducerData && reducerData?.unitDiscountTo === "addNewItems") {
    //   return { items: selectedDiscountTo }
  } else {
    return
  }
};
export const getUnitAttributeData = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addNewItems?.attributeMatch?.orderMeteData) {
    return { data: value?.unitType?.applyEffect?.addNewItems?.attributeMatch?.orderMeteData }
  } else if (value?.unitType?.applyEffect?.addMissingItems?.attributeMatch?.orderMeteData) {
    return { data: value?.unitType?.applyEffect?.addMissingItems?.attributeMatch?.orderMeteData }
  } else if (value?.unitType?.applyEffect?.addNewItems?.attributeMatch?.redemptionMetaData) {
    return { data: value?.unitType?.applyEffect?.addNewItems?.attributeMatch?.redemptionMetaData }
  } else if (value?.unitType?.applyEffect?.addMissingItems?.attributeMatch?.redemptionMetaData) {
    return { data: value?.unitType?.applyEffect?.addMissingItems?.attributeMatch?.redemptionMetaData }
  }
}
export const getUnitValue = (value, reducerData) => {
  if (value?.unitType?.unitValue) {
    return value?.unitType?.unitValue
    // } else if (reducerData?.unitValue) {
    //   return reducerData?.unitValue
  } else {
    return
  }
}
export const getUnitType = (value, reducerData) => {
  if (value?.unitType?.unitType) {
    return value?.unitType?.unitType
    // } else if (reducerData?.unitType) {
    //   return reducerData?.unitType
  } else {
    return
  }
}
export const getUnitCustomerSegment = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.customActivity?.acitivityCondition) {
    return value?.unitType?.applyEffect?.addMissingItems?.customActivity?.acitivityCondition
    // } else if (reducerData?.acitivityCondition) {
    //   return reducerData?.acitivityCondition
  } else {
    return
  }
}
export const getUnitTypeBudgetOrder = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.totalOrderValue) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.totalOrderValue
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.totalOrderValue) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.totalOrderValue
    // } else if (reducerData?.totalOrderValue) {
    //   return reducerData?.totalOrderValue
  } else {
    return
  }
}
export const getUnitTypeBudgetDiscount = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.totalDiscountAmount) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.totalDiscountAmount
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.totalDiscountAmount) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.totalDiscountAmount
    // } else if (reducerData?.totalDiscountAmount) {
    //   return reducerData?.totalDiscountAmount
  } else {
    return
  }
}
export const getUnitTypeBudgetRedemption = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.totalNumberOfRedemption) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.totalNumberOfRedemption
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.totalNumberOfRedemption) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.totalNumberOfRedemption
    // } else if (reducerData?.totalNumberOfRedemption) {
    //   return reducerData?.totalNumberOfRedemption
  } else {
    return
  }
}
export const getUnitTypeBudgetPercode = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCode) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCode
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.perCode) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.perCode
    // } else if (reducerData?.perCode) {
    //   return reducerData?.perCode
  } else {
    return
  }
}
export const getUnitTypeBudgetPerday = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCodePerDay
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.perCodePerDay
    // } else if (reducerData?.perCodePerDay) {
    //   return reducerData?.perCodePerDay
  } else {
    return
  }
}
export const getUnitTypeBudgetCampaign = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.unitType?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.inCampaign
  } else if (value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.unitType?.applyEffect?.addNewItems?.budget?.redemptionPerCustomer?.inCampaign
    // } else if (reducerData?.inCampaign) {
    //   return reducerData?.inCampaign
  } else {
    return
  }
}
export const getUnitTypeCondition = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.order?.totalAmount?.condition) {
    return value?.unitType?.applyEffect?.addMissingItems?.order?.totalAmount?.condition
  } else if (value?.unitType?.applyEffect?.addNewItems?.order?.totalAmount?.condition) {
    return value?.unitType?.applyEffect?.addNewItems?.order?.totalAmount?.condition
    // } else if (reducerData?.condition) {
    //   return reducerData?.condition
  } else {
    return
  }
}
export const getUnitTypeValue = (value, reducerData) => {
  if (value?.unitType?.applyEffect?.addMissingItems?.order?.totalAmount?.value) {
    return value?.unitType?.applyEffect?.addMissingItems?.order?.totalAmount?.value
  } else if (value?.unitType?.applyEffect?.addNewItems?.order?.totalAmount?.value) {
    return value?.unitType?.applyEffect?.addNewItems?.order?.totalAmount?.value
    // } else if (reducerData?.value) {
    //   return reducerData?.value
  } else {
    return
  }
}

/*------------ free shipping defaultValue function -------*/

export const getShippingAttributeData = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.attributeMatch?.orderMeteData) {
    return { data: value?.freeShipping?.applyEffect?.addMissingItems?.attributeMatch?.orderMeteData }
  } else if (value?.freeShipping?.applyEffect?.addMissingItems?.attributeMatch?.redemptionMetaData) {
    return { data: value?.freeShipping?.applyEffect?.addMissingItems?.attributeMatch?.redemptionMetaData }
  }
};
export const getShippingActivityConditin = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.customActivity) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.customActivity?.acitivityCondition
    // } else if (reducerData?.acitivityCondition) {
    //   return reducerData?.acitivityCondition
  } else {
    return
  }
}
export const getShippingBudgetOrder = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalOrderValue) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalOrderValue
    // } else if (reducerData?.totalOrderValue) {
    //   return reducerData?.totalOrderValue
  } else {
    return
  }
}
export const getShippingBudgetDiscount = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalDiscountAmount) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalDiscountAmount
    // } else if (reducerData?.totalDiscountAmount) {
    //   return reducerData?.totalDiscountAmount
  } else {
    return
  }
}
export const getShippingBudgetRedemption = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalNumberOfRedemption) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.totalNumberOfRedemption
    // } else if (reducerData?.totalNumberOfRedemption) {
    //   return reducerData?.totalNumberOfRedemption
  } else {
    return
  }
}
export const getShippingBudgetPercode = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCode) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCode
    // } else if (reducerData?.perCode) {
    //   return reducerData?.perCode
  } else {
    return
  }
}
export const getShippingBudgetPerday = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCodePerDay) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.perCodePerDay
    // } else if (reducerData?.perCodePerDay) {
    //   return reducerData?.perCodePerDay
  } else {
    return
  }
}
export const getShippingBudgetCampaign = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.inCampaign) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.budget?.redemptionPerCustomer?.inCampaign
    // } else if (reducerData?.inCampaign) {
    //   return reducerData?.inCampaign
  } else {
    return
  }
}
export const getShippingOrderCondition = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.order?.totalAmount?.condition) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.order?.totalAmount?.condition
    // } else if (reducerData?.condition) {
    //   return reducerData?.condition
  } else {
    return
  }
}
export const getShippingOrderValue = (value, reducerData) => {
  if (value?.freeShipping?.applyEffect?.addMissingItems?.order?.totalAmount?.value) {
    return value?.freeShipping?.applyEffect?.addMissingItems?.order?.totalAmount?.value
    // } else if (reducerData?.value) {
    //   return reducerData?.value
  } else {
    return
  }
}
export const getShippingUnitValue = (value, reducerData) => {
  if (value?.freeShipping?.shippingUnitValue) {
    return value?.freeShipping?.shippingUnitValue
    // } else if (reducerData?.shippingUnitValue) {
    //   return reducerData?.shippingUnitValue
  } else {
    return
  }
}
export const getShippingUnitType = (value, reducerData) => {
  if (value?.freeShipping?.shippingUnitType) {
    return value?.freeShipping?.shippingUnitType
    // } else if (reducerData?.shippingUnitType) {
    //   return reducerData?.shippingUnitType
  } else {
    return
  }
}
export const nestedObjectHandle = (data) => {
  const dummyData = {}

  for (let key in data) {

    const value = data[key];
    const type = typeof value;

    if (['string', 'boolean'].includes(type) || (type === 'number' && !isNaN(value))) {
      dummyData[key] = value
    } else if (type === 'object' && !Array.isArray(data[key])) {
      Object.assign(dummyData, nestedObjectHandle(value))
    } else if (Array.isArray(data[key])) {
      dummyData[key] = value;
    }
  }
  return dummyData
}


/* ---- voucher expiry days count -----*/

export const expiryDaysCount = () => {
  let expiryDate = new Date("2022/12/01");
  let date = new Date();
  let DifferenceOfTime = date.getTime() - expiryDate;
  let DifferenceOfDays = DifferenceOfTime / (1000 * 3600 * 24);
  if (DifferenceOfDays >= 1) {
    return Math.trunc(DifferenceOfDays)
  } else if (DifferenceOfDays < 1) {
    return
  }
}