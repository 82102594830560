import request from '../api/request'
import { getVoucherPayload } from './preparePayload';

/*========= user login action ==== */
export const loadinHandler = (data) => {
  return {
    type: "LOADING",
    payload: data
  }
}

export const userLoginAction = (value) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    const resp = await request('/userLogin', {
      method: 'post',
      data: {
        email: value.email,
        password: value.password
      },
    })
    if (resp.status === 200) {
      dispatch(loadinHandler(false));
      sessionStorage.setItem('userName', resp.data.userName);
      sessionStorage.setItem('loggedInUser', true);
      sessionStorage.setItem('userToken', resp.data.token);
      dispatch({
        type: 'LOGIN_USER',
        payload: resp?.data,
      });
      resolve(resp)
    } else {
      dispatch(loadinHandler(false));
      reject(resp)
    }
  })
};

/*========== user registration action ====*/

export const userRegisterAction = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const resp = await request('/userSignup', {
      method: 'post',
      data: {
        email: data.email,
        userName: data.username,
        password: data.password
      },
    })
    if (resp.status === 200) {
      sessionStorage.setItem('loggedInUser', true);
      sessionStorage.setItem('userToken', resp?.data);
      dispatch({
        type: 'REGISTER_USER',
        payload: resp?.data,
      });
      resolve(resp)
    } else {
      reject(resp)
    }
  })
};

/*========= create voucher action ===*/

export const createVoucherAction = (data, token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request('/createVoucher', {
        method: 'post',
        data: data,
        token: token,
      })
      resolve(res)
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }
  })
}

/*=========== update voucher action ====*/

export const updateVoucherAction = (data, token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`/editOneVoucher`, {
        method: 'patch',
        data: getVoucherPayload(data),
        token: token
      })
      resolve(res)
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }
  })
}


/*=========== category action ====*/

export const createCategoryAction = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`/category/createCategory`, {
        method: 'post',
        data: data
      })
      resolve(res)
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }
  })
}

export const getCategoryAction = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`/category/allCategory`, {
        method: 'get',
      })
      return resolve(res).then((item) => {
        return dispatch({
          type: 'CATEGORY_LIST',
          payload: item.categoryList,
        });
      })
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }
  })
}