import react from "react";
import AppContainer from "../AppContainer";
import PageHead from "../Common/PageHead/Index";
import CustomersDetail from "./CustomersDetail";

function Customers() {
  return (
    <AppContainer>
      <PageHead
        title="Customers List"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          {
            path: "/", breadcrumbName: "Customers",
          },
        ]}
        bgNone={true}
        subTitle="">
        <div>
          <CustomersDetail />
        </div>
      </PageHead>
    </AppContainer>
  )
}

export default Customers;