/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteVoucherHandle, EditVoucherHandle } from '../../../Common/EditDeleteVoucher/EditDeleteHandle';
import { getVoucherDetailAction } from '../../services/users';
import PageHead from '../../../Common/PageHead/Index';
import AppContainer from '../../../AppContainer';
import { formatDate } from '../../../../helper/DateFormate';
import { useSelector, useDispatch } from 'react-redux';
import edit from '../../../../assets/icons/edit.png'
import trash from '../../../../assets/icons/delete.png'

import Index from './Index';
import './Index.scss';

function VoucherDetail() {
  const [resp, setResp] = useState();
  let data = useSelector((item) => item?.rootReducer?.voucherDetail)
  let token = sessionStorage.getItem('userToken');
  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();

  // console.log(data, "datadatadata")

  useEffect(() => {
    getvoucherDetail(id, token)
    return () => dispatch({
      type: 'GET_VOUCHER_DETAIL',
      payload: {},
    });
  }, []);

  const getvoucherDetail = async () => {
    let detail = await dispatch(getVoucherDetailAction(id, token))
    setResp(detail)
  }

  const deleteVoucherHandle = async () => {
    DeleteVoucherHandle(id, token, dispatch, navigate)
  }

  const editVoucherHandle = () => {
    EditVoucherHandle(id, navigate)
  }

  // function formatDate(dateString) {
  //   const date = new Date(dateString);

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');

  //   let hours = date.getHours();
  //   const minutes = String(date.getMinutes()).padStart(2, '0');

  //   const isAM = hours < 12;
  //   const period = isAM ? 'AM' : 'PM';
  //   if (hours > 12) {
  //     hours -= 12;
  //   } else if (hours === 0) {
  //     hours = 12;
  //   }

  //   return `${year}-${month}-${day}, ${hours}:${minutes} ${period}`;
  // }

  return (
    <AppContainer>
      <PageHead
        title="Voucher Detail"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          { path: "/vouchers", breadcrumbName: "Vouchers" },
          { path: `/vouchers/detail/${id}`, breadcrumbName: `${data?.couponCode}` },
        ]}
        bgNone={true}
        button={
          // <div className='icons_wrapper'>
          //   <span className='icons_section'>
          //     <Tooltip title="edit voucher"><EditFilled className='icons_btn' onClick={editVoucherHandle} /></Tooltip>
          //   </span>
          //   <span className='icons_section'>
          //     <Tooltip title="delete voucher"><DeleteFilled className='icons_btn' onClick={deleteVoucherHandle} /></Tooltip>
          //   </span>
          // </div>
          <div className='flex gap-4'>
            <span className='cursor-pointer' onClick={editVoucherHandle}>
              <Tooltip title="edit voucher">
                <img src={edit} alt="edit" className='w-5' />
              </Tooltip>
            </span>
            <span className='cursor-pointer' onClick={deleteVoucherHandle}>
              <Tooltip title="delete voucher">
                <img src={trash} alt="edit" className='w-5' />
              </Tooltip>
            </span>
          </div>
        }
        subTitle={`Created at: ${formatDate(data?.createdAt || '')}`}
      >
        <Index resp={resp || {}} />
      </PageHead>
    </AppContainer>
  )
}

export default VoucherDetail;


{/* <div className='icons_wrapper'>
<span className='icons_section'>
  <Tooltip title="edit voucher">
    <img src={edit} alt="edit" onClick={editVoucherHandle} />
  </Tooltip>
</span>
<span className='icons_section'>
  <Tooltip title="delete voucher">
    <img src={edit} alt="edit" onClick={deleteVoucherHandle} />
  </Tooltip>
</span>
</div> */}