import React, { useEffect, useState } from 'react';
import DropdownHandle from '../Common/dropdown/DropdownHandle';
import { getProductAction } from '../Vouchers/services/users';
import Notification from '../Common/costant/Notification';
import { Empty, Spin, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './Products.scss';

function Index() {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const { loading } = useSelector((item) => item.rootReducer)
  const token = sessionStorage.getItem('userToken');

  const getProductHandle = async (token) => {
    let resp = await dispatch(getProductAction(token));
    if (resp.status === 200) {
      setDataSource(resp.data)
    } else {
      Notification('error', resp?.message);
    }
  }

  useEffect(() => {
    getProductHandle(token)
  }, [])

  const columns = [
    {
      title: 'Cart Id',
      dataIndex: 'cartId',
      key: 'cartId',
      render: (index, item) => <div key={index}>
        <div key={index}>{item.cartId ? item.cartId : ""}</div>
      </div>,
    },
    {
      title: 'Sku ID',
      dataIndex: 'skuID',
      key: 'skuID',
      render: (index, item) => <span key={index}>{item.skuID?.length > 0 ? item.skuID.map((sku) => <Tag color="blue">{item?.skuID}</Tag>) : ''}</span>,
    },
    {
      title: 'Custom Label1',
      dataIndex: 'customLabel1',
      key: 'customLabel1',
      render: (index, item) => <span key={index}>{item?.customLabel1}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (index, item) => <span key={index}>{item?.category}</span>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (index, item) => <span key={index}>{<Tag color="green">{item?.price}</Tag> || "---"}</span>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (index, item) => <span key={index}>{<div>
        <p>{new Date(item.createdAt).toLocaleDateString()}</p>
      </div> || "---"}</span>,
    },
    {
      // title: 'Operations',
      // key: 'operation',
      render: (tindex, item) => (
        <span className='operation_section' key={item.id}>
          <DropdownHandle demo={"product"} id={item.id} />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className='total_products'>
        <span>
          You have {dataSource.length > 0 ? dataSource.length : 0} products in your cart
        </span>
      </div>
      <Table
        key={dataSource?._id}
        loading={loading}
        columns={columns}
        dataSource={dataSource || []}
        rowKey={(record) => record._id}
        pagination={true}
        locale={{
          emptyText: (
            <div style={{ textAlign: 'center' }} key={dataSource?._id}>
              <Empty description={false} style={{ fontSize: 20 }} />
              <p>No products Found in your cart</p>
            </div>
          )
        }}
      />
    </>
  )
}

export default Index;