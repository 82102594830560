/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import {
  getActiveTime,
  getActiveType,
  getEndDate,
  getEndTime,
  getFormatDate,
  getFrameRedemption,
  getFrameTime,
  getInitialTimeframe,
  getParticularDay,
  getSelectedDay,
  getspecificTiming,
  getStartDate, getStartTime,
  getTimeDuration,
  getValidTimeFrame,
  timeframeErrorHandler
} from '../../../helper/helper';
import moment from 'moment';

const CampainSecondStep = (props) => {
  const { setFormValues, Error, setError } = props;

  const [timeFrame, setTimeFrame] = useState(false);
  const [particularDay, setParticularDay] = useState(false);
  const [specificAmmount, setSpecificAmmount] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endTime, setEndTime] = useState();
  const [dateInput, setDateInput] = useState();
  const [timeframe, setTimeframe] = useState();
  const [timeframeTime, setTimeframeTime] = useState();
  const [timeframeDuration, setTimeframeDuration] = useState();
  const [timeframeValue, setTimeframeValue] = useState();
  const [showError, setShowError] = useState(false);

  const secondStep = useSelector(state => state.vouchers.second);
  const initialValue = useSelector(state => state?.rootReducer?.voucherDetail);

  // console.log(initialValue, "initialValue2nd")

  /*---all inputs onChange handler & set in setFormValues ----*/

  function onChangeStartDate(e) {
    if (e.target.value) {
      setError('')
    }
    let today = new Date(e.target.value).toISOString().slice(0, 10);
    let getDate = moment(today).format('DD-MM-YYYY');
    setStartDate(getDate);
    setDateInput(e.target.value)
    setFormValues('activeDate', getDate)
  };
  const onChangeStartTime = (e) => {
    setEndTime(e.target.value)
    // setFormValues('startTime', e.target.value)
  };
  const onChangeEndDate = (e) => {
    if (e.target.value) {
      setError('')
    }
    let today = new Date(e.target.value).toISOString().slice(0, 10)
    let getDate = moment(today).format('DD-MM-YYYY');
    setFormValues('expiryDate', getDate)
  };
  const onChangeEndTime = (e) => {
    // setFormValues('endTime', e.target.value)
  };
  const timeframeValidationHandler = (e) => {
    setTimeFrame(!timeFrame);
    // setFormValues('timeFrameChecked', e.target.checked)
  };
  const particularDayValidationHandler = (e) => {
    setParticularDay(!particularDay);
    // setFormValues('onParticularDay', e.target.checked)
  };
  const specificAmountHandler = (e) => {
    setSpecificAmmount(!specificAmmount);
    // setFormValues('specificTime', e.target.checked)
  };
  const handleChangeParticularDay = (e) => {
    // setFormValues('particularDays', e?.map((day) => day?.value))
  };

  /*----valid timeframe handler------*/

  const timeFrameIntervalHandle = (e) => {
    setTimeframe(e.target.value)
  }
  const timeFrameTimeHandle = (e) => {
    setTimeframeTime(e.target.value)
  }
  const timeFrameDurationHandle = (e) => {
    setTimeframeDuration(e.target.value)
  }
  const timeFrameValueHandle = (e) => {
    setTimeframeValue(e.target.value)
  }

  useEffect(() => {
    setShowError(timeframeErrorHandler(timeframe, timeframeTime, timeframeDuration, timeframeValue))
  }, [timeframe, timeframeTime, timeframeDuration, timeframeValue])

  const particular = [
    {
      value: 'Monday',
      label: 'Monday'
    },
    {
      value: 'Tuesday',
      label: 'Tuesday'
    },
    {
      value: 'Wednesday',
      label: 'Wednesday'
    },
    {
      value: 'Thursday',
      label: 'Thursday'
    },
    {
      value: 'Friday',
      label: 'Friday'
    },
    {
      value: 'Saturday',
      label: 'Saturday'
    },
    {
      value: 'Sunday',
      label: 'Sunday'
    }
  ];

  // console.log(getStartDate(initialValue), "kkkkk:::")
  return (
    <div>
      <div className='p-4 bg-white shadow-md'>
        <Row gutter={12} className="mb-5">
          <Col lg={12}>
            <span className={`w-full -mb-4 ${Error?.activeDate && 'text-red-600'}`}>Start Date</span>
            <input
              type="date"
              name="activeDate"
              onKeyDown={(e) => e.preventDefault()}
              className={`w-full outline-none ${Error?.activeDate && 'border border-red-600'}`}
              min={Object.keys(initialValue).length === 0 ? new Date().toISOString().split("T")[0] : null}
              defaultValue={getInitialTimeframe(initialValue?.activeDate)}
              onChange={(e) => onChangeStartDate(e)}
            />
          </Col>
          <Col lg={12}>
            <span className='w-full -mb-4'>Start Time</span>
            <input
              type="time"
              name="startTime"
              min={initialValue.length === 0 ? new Date().getHours() + ":" + new Date().getMinutes() : null}
              defaultValue={getStartTime(initialValue) || secondStep?.startTime}
              onChange={onChangeStartTime}
              className='w-full'
              size='large'
            />
          </Col>
        </Row>
        <Row gutter={12} className="mb-5">
          <Col lg={12}>
            <span className={`w-full -mb-4 ${Error?.expiryDate && 'text-red-600'}`}>End Date</span>
            <input
              type="date"
              name="expiryDate"
              onKeyDown={(e) => e.preventDefault()}
              className={`w-full outline-none ${Error?.expiryDate && 'border border-red-600'}`}
              disabled={Object.keys(initialValue).length === 0 ? dateInput === undefined ? true : false : false}
              min={dateInput ? new Date(dateInput).toISOString().split("T")[0] : ""}
              defaultValue={getInitialTimeframe(initialValue?.expiryDate) || secondStep?.endDate}
              onChange={(e) => onChangeEndDate(e)}
            />
          </Col>
          <Col lg={12}>
            <span className='w-full -mb-4'>End Time</span>
            <input
              type="time"
              name="endTime"
              className='w-full outline-none'
              size='large'
              defaultValue={getEndTime(initialValue) || secondStep?.endTime}
              onChange={onChangeEndTime}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} className="mb-3 flex">
            <input
              type="checkbox"
              name="timeFrameChecked"
              className={`mr-2 outline-none ${dateInput === undefined ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              // disabled={dateInput === undefined ? true : false}
              checked={getValidTimeFrame(initialValue, secondStep, timeFrame)}
              onChange={timeframeValidationHandler}
              disabled
            />
            <span style={{ color: dateInput === undefined ? '#ab9d9d' : '' }}> Valid in this timeframe only</span>
            {getValidTimeFrame(initialValue, secondStep, timeFrame) &&
              <div>
                <Row gutter={12} className="mt-3">
                  <Col lg={9}>
                    <span className='w-full -mb-4'>Repeat every</span>
                    <input
                      name="duration"
                      type="number"
                      min={0}
                      defaultValue={getTimeDuration(initialValue) || secondStep?.duration}
                      // onChange={e => setFormValues('duration', e.target.value)}
                      onChange={e => timeFrameIntervalHandle(e)}
                      className='w-full outline-none'
                      placeholder='value'
                    />
                    {showError === true ? <p style={{ color: 'red' }}>The interval value is invalid !</p> : ''}
                  </Col>
                  <Col lg={3}>
                    <span className='w-full -mb-4'>Time</span>
                    <select
                      name="time"
                      className='w-full border-blue-200 -mb-4 outline-none'
                      defaultValue={getFrameTime(initialValue) || secondStep?.time}
                      // onChange={e => setFormValues('time', e.target.value)}
                      onChange={e => timeFrameTimeHandle(e)}
                    >
                      <option value=''>Active on... </option>
                      <option value="Day">Day(s)</option>
                      <option value="Hour">Hour(s)</option>
                    </select>
                  </Col>
                  <Col lg={9}>
                    <span className='w-full -mb-4'>for a duration of</span>
                    <input
                      name="redemption"
                      className='w-full outline-none'
                      min={0}
                      type="number"
                      placeholder='value'
                      // onChange={e => setFormValues('redemption', e.target.value)}
                      onChange={e => timeFrameDurationHandle(e)}
                      defaultValue={getFrameRedemption(initialValue) || secondStep?.redemption}
                    />
                    {showError === true ? <p style={{ color: 'red' }}>The duration value is invalid !</p> : ''}
                  </Col>
                  <Col lg={3}>
                    <span className='w-full -mb-4'>Time</span>
                    <select
                      name="time"
                      className='w-full border-blue-200 -mb-4 outline-none'
                      defaultValue={getFrameTime(initialValue) || secondStep?.time}
                      // onChange={e => setFormValues('time', e.target.value)}
                      onChange={e => timeFrameValueHandle(e)}
                    >
                      <option value=''>Active on... </option>
                      <option value="Day">Day(s)</option>
                      <option value="Hour">Hour(s)</option>
                    </select>
                  </Col>
                </Row>
              </div>
            }
          </Col>
          <Col lg={24} md={24} sm={24} className="mb-3 flex">
            <input
              type="checkbox"
              className='cursor-pointer mr-2'
              name="onParticularDay"
              checked={getParticularDay(initialValue, secondStep, particularDay)}
              onChange={particularDayValidationHandler}
              disabled
            />
            <span> Valid on particular days only</span>
            {getParticularDay(initialValue, secondStep, particularDay) &&
              <div className='mt-3 lg:mb-6'>
                <Select
                  isMulti
                  name="particularDays"
                  className="w-full basic-multi-select outline-none"
                  classNamePrefix="select"
                  placeholder="Active only on"
                  defaultValue={getSelectedDay(initialValue, secondStep, particular)}
                  onChange={handleChangeParticularDay}
                  options={particular}
                />
              </div>
            }
          </Col>
          <Col lg={24} md={24} sm={24} className="mb-3 flex">
            <input
              type="checkbox"
              className='cursor-pointer mr-2 outline-none'
              name="specificTime"
              checked={getspecificTiming(initialValue, secondStep, specificAmmount)}
              onChange={specificAmountHandler}
              disabled
            />
            <span className='ml-1'>Keep valid for specific amount of time after publishing</span>
            {getspecificTiming(initialValue, secondStep, specificAmmount) &&
              <div>
                <Row gutter={12} className="mt-3">
                  <Col lg={12}>
                    <span className='w-full -mb-4'>Active after publishing for</span>
                    <input
                      name="activeTime outline-none"
                      type="number"
                      min={0}
                      onChange={e => setFormValues('activeTime', e.target.value)}
                      defaultValue={getActiveTime(initialValue) || secondStep?.activeTime}
                      className='w-full'
                      placeholder='Active after publishing for' />
                  </Col>
                  <Col lg={12}>
                    <span className='w-full -mb-4'>Time</span>
                    <select
                      name="timeTypes"
                      className='w-full border-blue-200 outline-none'
                      defaultValue={getActiveType(initialValue) || secondStep?.timeTypes}
                      onChange={(e) => setFormValues("timeTypes", e.target.value)}
                    >
                      <option>Active only on... </option>
                      <option value="Hour">Hour(s)</option>
                      <option value="Day">Day(s)</option>
                    </select>
                  </Col>
                </Row>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CampainSecondStep;