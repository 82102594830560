import React, { useEffect, useState } from 'react'
import { fifthStep, firstStep, fourthStep, secondStep, thirdStep } from '../../../redux/voucherActions';
import { createVoucherAction, updateVoucherAction } from '../../../redux/actions';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Steps, Form, Typography } from 'antd'
import CampainFifthStep from '../FormSteps/CampainFifthStep';
import CampainFirstStep from '../FormSteps/CampainFirstStep';
import CampainFourthStep from '../FormSteps/CampainFourthStep';
import CampainSecondStep from '../FormSteps/CampainSecondStep';
import CampainThirdStep from '../FormSteps/CampainThirdStep';
import Notification from '../../Common/costant/Notification';
import { campaignNameHandle } from '../../../helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import PageHead from '../../Common/PageHead/Index'
import AppContainer from '../../AppContainer'
import '../../Compaign/index.less';
const { Paragraph } = Typography;
const { Step } = Steps;

const CampaignCreator = () => {
  const [current, setCurrent] = useState(0);
  const [value, setValue] = useState(1);
  const [formData, setFromData] = useState({
    campaignType: "",
    couponName: "",
    maxRedeemCount: "",
    activeDate: "",
    expiryDate: "",
    applyDiscountOn: "",
    maxDiscountAmount: "0",
    percentageDiscount: "0",
    refereeAmount: "0",
    referrerAmount: "0",
  });
  const [initialValue, setInitialValue] = useState();
  const [showError, setShowError] = useState();

  const token = sessionStorage.getItem('userToken');
  const initialData = useSelector(state => state?.rootReducer?.voucherDetail);
  const data = useSelector(state => state.rootReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate()
  let location = useLocation();
  let updateVoucher = location?.state?.type;

  let campaignName
  if ('campaignType' in initialData) {
    campaignName = initialData["campaignType"]
  } else {
    campaignName = location.state.campaignName;
  }
  const setFormValues = (name, value) => {
    setFromData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  // console.log("formData-----", data, formData)

  useEffect(() => {
    if (initialData && (initialData.length > 0 || Object.keys(initialData).length > 0)) {
      setFromData(initialData)
    }
  }, [initialData]);

  useEffect(() => {
    if (campaignName === 'discountCoupons' || 'giftVouchers' || 'referralCodes' || 'promotion' || 'loyalityProgram') {
      return () => dispatch({
        type: 'GET_VOUCHER_DETAIL',
        payload: {},
      });

    }
  }, []);

  useEffect(() => {
    setInitialValue(data)
  }, [data, initialValue, formData])

  const onChange = e => {
    setValue(e.target.value);
  };
  const next = (e) => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(0);
  };
  const onFinish = (values) => {
    switch (current) {
      case 0:
        dispatch(firstStep(formData))
        // setFromData({})
        break;
      case 1:
        dispatch(secondStep(formData))
        // setFromData({})
        break;
      case 2:
        dispatch(thirdStep(formData))
        // setFromData({})
        break;
      case 3:
        dispatch(fourthStep(formData))
        // setFromData({})
        break;
      case 4:
        dispatch(fifthStep(formData))
        navigate('/campaign/create/1234/dashboard')
        // setFromData({})
        break;
      case 5:
        break;
      default:
        dispatch(firstStep(values));
        setFromData({})
    }
    // setCurrent(current + 1);
    if (!formData?.couponName) {
      setShowError({ name: 'couponName' })
    } else if (!formData?.maxRedeemCount) {
      setShowError({ maxRedeemCount: 'maxRedeemCount' })
    } else if (current === 1 && !formData?.activeDate) {
      setShowError({ activeDate: 'activeDate' })
    } else if (current === 1 && !formData?.expiryDate) {
      setShowError({ expiryDate: 'expiryDate' })
    } else if (current === 2 && campaignName === "discountCoupons" && !formData?.maxDiscountAmount) {
      setShowError({ maxDiscountAmount: 'maxDiscountAmount' })
    } else if (current === 2 && campaignName === "referralCodes" && !formData?.referrerAmount) {
      setShowError({ maxDiscountAmount: 'referrerAmount' })
    } else if (current === 2 && campaignName === "referralCodes" && !formData?.refereeAmount) {
      setShowError({ percentageDiscount: 'refereeAmount' })
    } else if (current === 2 && campaignName === "discountCoupons" && !formData?.percentageDiscount) {
      setShowError({ percentageDiscount: 'percentageDiscount' })
    } else {
      setCurrent(current + 1);
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setFromData({});
    dispatch(firstStep({}))
    dispatch(secondStep({}))
    dispatch(thirdStep({}))
    if (updateVoucher) {
      const respo = await (dispatch(updateVoucherAction(formData, token)));
      if (respo?.status === 200) {
        Notification('success', respo.message);
        navigate("/");
      } else {
        Notification('error', respo.message);
        navigate("/");
      }
    } else {
      const resp = await (dispatch(createVoucherAction(formData, token)))
      if (resp?.status === 200) {
        Notification('success', resp.message);
        navigate("/");
      } else {
        Notification('error', resp.message);
        navigate("/");
      }
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const renderTitle = () => {
    switch (current) {
      case 0:
        return 'Program size';
      case 1:
        return 'Timeframe'
      case 2:
        return 'Discount value';
      case 3:
        return 'Metadata (optional)';
      case 4:
        return 'Summary';
      default:
        return '';
    }
  }
  const renderFormData = () => {
    switch (current) {
      case 0:
        return <CampainFirstStep setFromData={setFromData} onChange={onChange} value={value} setFormValues={setFormValues} showError={showError} setShowError={setShowError} />
      case 1:
        return <CampainSecondStep setFormValues={setFormValues} formData={formData} setFromData={setFromData} Error={showError} setError={setShowError} />
      case 2:
        return <CampainThirdStep setFormValues={setFormValues} formData={formData} setFromData={setFromData} Error={showError} setError={setShowError} />
      case 3:
        return <CampainFourthStep setFormValues={setFormValues} formData={formData} setFromData={setFromData} />
      case 4:
        return <CampainFifthStep setFormValues={setFormValues} formData={formData} setFromData={setFromData} />
      default:
        return <CampainFirstStep setFormValues={setFormValues} />;
    }
  }
  const secretKey = sessionStorage.getItem('secretKey');
  const [haveToken, setHaveToken] = useState(false);

  useEffect(() => {
    if (secretKey) {
      setHaveToken(true)
    } else {
      setHaveToken(false)
    }
  }, [secretKey])

  return (
    <AppContainer>
      <PageHead
        title="Create Campaign"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          { path: "/", breadcrumbName: "Create Campaign", },
        ]}
        bgNone={true}
        button={haveToken ? <div className='text-lg '><span className='pr-4 font-bold text-gray-600'>Secret Key:</span>
          <Paragraph className='w-52 flex flex-wrap' copyable={{ tooltips: false }}>{secretKey}</Paragraph>
        </div>
          : <Link
            className="flex items-center px-3 py-1 text-sm font-medium text-indigo-500 cursor-pointer hover:text-indigo-600"
            to="/userkey"
          >
            <Button type="primary" size='large' style={{ background: '#1890ff' }}>Create Access & Secret key</Button>
          </Link>}
        subTitle="">
        <div className='h-screen'>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            layout='vertical'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className='pb-4 text-xl text-blue-500'>{current + 1}. {renderTitle()}</div>
            <Row gutter={12}>
              <Col lg={18} sm={24}>
                <div>
                  <div className=''>
                    {renderFormData()}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className='p-4 bg-white shadow-md'>
                  <div className="py-4 mb-6 text-lg font-medium border-b-2 border-blue-400">
                    New {campaignNameHandle(campaignName)}
                  </div>
                  <Steps direction="vertical" current={current}>
                    <Step title={<span className='mb-2 text-lg font-medium'>Program size</span>} description={
                      <div>
                        <ul className="mb-2 list-disc text-[13px]" style={{ listStyleType: 'circle' }}>
                          <li className='mb-2' style={{ listStyleType: 'circle' }}>Bulk codes - up to thousands of random discount codes designed for single use by a limited group of customers (e.g., “ACME-5P13R” gives $25 off for the first 3 purchases, new customers from Warsaw only).</li>
                          <li style={{ listStyleType: 'circle' }}>Standalone code - A fixed-code discount designed for multiple uses (e.g., 10% off with “blackfriday” code).</li>
                        </ul>
                        <div className='mt-4 ml-2'>
                          {current === 0 &&
                            <Button type='' size='middle' onClick={(e) => { next(e); setCurrent(0) }} htmlType="submit">Next Step</Button>
                          }
                        </div>
                      </div>
                    } />
                    <Step title={<span className='mb-2 text-lg font-medium'>Timeframe</span>} description={
                      <div className='mt-4 ml-2'>
                        {current === 1 &&
                          <Button type='' size='middle' onClick={(e) => { next(e); setCurrent(1) }} htmlType="submit">Next Step</Button>
                        }
                      </div>} />
                    <Step title={<span className='mb-2 text-lg font-medium'>Discount value</span>} description={
                      <div className='mt-4 ml-2'>
                        {current === 2 &&
                          <Button type='' size='middle' onClick={(e) => { next(e); setCurrent(2) }} htmlType="submit">Next Step</Button>
                        }
                      </div>} />
                    <Step title={<span className='mb-2 text-lg font-medium'>Metadata (optional)</span>} description={
                      <div className='mt-4 ml-2'>
                        {current === 3 &&
                          <Button type='' size='middle' onClick={(e) => { next(e); setCurrent(3) }} htmlType="submit">Next Step</Button>
                        }
                      </div>} />
                    <Step title={<span className='mb-2 text-lg font-medium'>Summary</span>} description={
                      current === 4 && <div className='flex ml-2 lg:mt-4 md:space-y-1 md:flex-wrap lg:justify-between lg:items-baseline'>
                        <div>
                          <Button type='' size='large' onClick={() => prev()}>Cancel</Button>
                        </div>
                        <div className='lg:-mt-4'>
                          <Button
                            type='primary'
                            htmlType='submit'
                            size='large'
                            className='bg-blue-600'
                            onClick={formSubmitHandler}
                          >
                            {updateVoucher ? 'Update' : 'Submit'}
                          </Button>
                        </div>
                      </div>}
                    />
                  </Steps>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </PageHead>
    </AppContainer>
  )
}

export default CampaignCreator
