import React from "react";
import ErrorPage from "../../../pages/404/ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDrivedStateFromError(_error) {
    // console.log(this.props);
    return { hasError: true }
  }
  // This method is called if any error is encountered
  componentDidCatch(error, errorInfo) {
    this.props.setHasError(true)
  }
  componentDidUpdate(prevProps, _previousState) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({
        hasError: false
      })
    }
  }
  render() {
    if (this.props.hasError) {
      return (
        <div>
          <h2><ErrorPage /></h2>
        </div>
      );
    } else {

      return this.props.children;
    }
  }
}

export default ErrorBoundary