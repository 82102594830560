import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, Modal, Radio } from "antd";
import React, { useState } from "react";
import Info from "../../../../Common/Info/Info";
import FilterDataList from "../FilterDataList/FilterDataList";

const AddOrder = ({ orderType, title, setFormValues, reducerData }) => {
  const [anyOrderVisible, setAnyOrderVisible] = useState(false);
  const [EveryOrderVisible, setEveryOrderVisible] = useState(false);
  const [selectedListItems, setSelectedListItems] = useState([]);

  const modalHandler = () => {
    if (orderType === "Any") {
      setAnyOrderVisible(true)
    } else if (orderType === "Every") {
      setEveryOrderVisible(true)
    }
  }

  const selectedOrderHandler = () => {
    if (anyOrderVisible === true) {
      setFormValues("anyOrderItem", selectedListItems)
    } else if (EveryOrderVisible === true) {
      setFormValues("everyOrderItem", selectedListItems)
    }
  }

  return (
    <>
      <div className="flex space-y-4">
        <div
          className="pr-6 text-lg font-medium"
          style={{ lineHeight: "72px" }}
        >
          {title}
        </div>
        <div>
          <Button type="primary" style={{ background: '#1890ff' }} onClick={() => modalHandler()} size="large">
            Add
          </Button>
        </div>
      </div>
      <Modal
        title={
          <div className="py-4">
            <Radio.Group
              defaultValue="AllProducts"
              size="large"
              buttonStyle="solid"
            >
              <Radio.Button value="AllProducts">All products</Radio.Button>
              <Radio.Button value="SelectCollections">
                Select collections
              </Radio.Button>
            </Radio.Group>
          </div>
        }
        centered
        visible={anyOrderVisible === true ? anyOrderVisible : EveryOrderVisible === true ? EveryOrderVisible : ''}
        onOk={() => {
          setAnyOrderVisible(false);
          setEveryOrderVisible(false);
          selectedOrderHandler()
        }}
        onCancel={() => {
          setAnyOrderVisible(false)
          setEveryOrderVisible(false);
        }}
        width={1000}
      >
        <div>
          <div className="py-4 border-b-2 border-blue-200">
            <div>Filter</div>
          </div>
          <div className="my-4">
            <Info>
              <ArrowDownOutlined />
              Choose products (up to 20) from the list to create static
              collection (manually grouped products). Otherwise the created
              collection will be auto-updated with your product catalog changes
            </Info>
          </div>
          <div>
            <FilterDataList
              setSelectedListItems={setSelectedListItems}
              orderType={orderType}
              reducerData={reducerData}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddOrder;
