import { Alert, Button, Modal } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createCategoryAction } from '../../../redux/actions'

const CreateCategoryModal = ({ isModalOpen, createCategoryHandler }) => {
    const dispatch = useDispatch()
    const [categoryData, setCategoryData] = useState({
        categoryKey: '',
        categoryValue: ''
    })
    const [showError, setShowError] = useState('')
    const categoryChangeHandler = (name, value) => {
        setCategoryData({ ...categoryData, [name]: value })
    }
    const clickHandler = () => {
        createCategoryHandler(false)
    }

    const categoryHandler = () => {
        // console.log("category create!!", categoryData);
        if (categoryData.categoryKey === '') {
            setShowError('key is empty')
            createCategoryHandler(true)
        }
        if (categoryData.categoryValue === '') {
            setShowError('Value is empty')
            createCategoryHandler(true)
        }
        if (categoryData.categoryKey === '' && categoryData.categoryValue === '') {
            setShowError('key and value is empty')
            createCategoryHandler(true)
        }
        else {
            setShowError('')
            dispatch(createCategoryAction(categoryData))
            createCategoryHandler(false)
        }
    }
    return (
        <div>
            <Modal title="Create Category" open={isModalOpen} onOk={clickHandler} onCancel={clickHandler} footer={<Button size='large' style={{ background: '#1890ff' }} type='primary' onClick={() => categoryHandler()}>Create Category</Button>} destroyOnClose>
                <div className='py-2'>
                    {showError !== '' && <Alert message={showError} type="error" showIcon closable />}
                </div>
                <form>
                    <div className='flex justify-center py-0 space-x-4'>
                        <div className='w-full'>
                            <label className="block my-1 ">Key</label>
                            <input
                                name="key"
                                type="text"
                                className='w-full'
                                placeholder="Category Key"
                                onChange={(e) => categoryChangeHandler("categoryKey", e.target.value)}

                            />

                        </div>
                        <div className='w-full'>
                            <label className="block my-1 ">Value</label>
                            <input
                                name="value"
                                type="text"
                                className='w-full'
                                placeholder="Category Value"
                                onChange={(e) => categoryChangeHandler("categoryValue", e.target.value)}

                            />

                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default CreateCategoryModal