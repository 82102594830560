export function formatDate(dateString) {
    const date = new Date(dateString);
    if (!dateString) {
        return '';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const isAM = hours < 12;
    const period = isAM ? 'AM' : 'PM';
    if (hours > 12) {
        hours -= 12;
    } else if (hours === 0) {
        hours = 12;
    }

    return `${year}-${month}-${day}, ${hours}:${minutes} ${period}`;
}