/* eslint-disable jsx-a11y/alt-text */
// import footerImg from '../../assets/undraw.png';
import React from "react";
import { Link } from "react-router-dom";
// import imageUrl from '../../images/minor.png'

const LoginWrapper = ({ children, setValue, value }) => {
  return (
    <div className="flex flex-wrap flex-row-reverse w-full min-h-[750px]">
      {/* Login Section */}
      <div className="flex flex-col w-full h-screen lg:w-1/2 bg-white min-h-[750px]" >
        <div className="flex justify-center pt-12 md:justify-start md:pl-12">
          <Link to="#" className="px-5 py-3 text-xl font-bold text-white bg-blue-600 rounded-md">
            Voucherify
          </Link>
        </div>
        <div style={{ backgroundImage: 'url(../../images/minor.png)' }} className="flex flex-col justify-center px-20 pt-8 my-10 lg:my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
          <p className="py-6 text-3xl font-bold text-center">
            {value === "login" ? "Welcome back!" : "Register here."}
          </p>
          {children}
          <div className="pt-12 pb-12 text-center">
            <p>
              <span className="text-[black]">
                {value !== "login"
                  ? "Already have account?"
                  : "Do not have an account?"}{" "}
              </span>
              <Link
                to="#"
                onClick={() => {
                  if (value === "login") {
                    setValue("signup");
                  } else {
                    setValue("login");
                  }
                }}
                className="font-semibold underline"
              >
                {value !== "login" ? "Login" : "Register here."}
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/*side Image Section */}
      <div className="side_section_wrapeer">
        <div className="side_section">
          <div className="side_section_body">
            <p className="">Voucherify</p>
            <div className="">If you do not want wasting time and money on digital promotions and want build effective promotions
              without overspending.then why don't you join in?.</div>
            <div className="side_section_image">
              <img
                className=""
                src="https://img.freepik.com/free-vector/special-offers-promo-vouchers-templates-set_74855-5580.jpg?w=1380&t=st=1672387096~exp=1672387696~hmac=2acf4a7b6f0f59199b1cbfd32d7f13ed8a8311e0cae98a30124b5dc712ae12ca"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
