import React from 'react'
import MetaData from './DiscountValue/MetaData/MetaData'

const CampainFourthStep = ({ setFormValues }) => {
    return (
        <div>
            {/* <MetaData setFormValues={setFormValues} /> */}
        </div>
    )
}

export default CampainFourthStep
