import React, { useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from "recharts";
import Icon from '../../assets/cardsImage/coupon22.png';
import './DashboardCard.scss'

function DashboardCard03({ pieChartData }) {
  const data = [
    { name: 'Amount discount', value: 1 },
    { name: 'Percent Discount', value: 1 },
    { name: 'Fixed order amount', value: 1 },
    { name: 'Unit Discount', value: 1 },
    { name: 'Free Shipping', value: 1 },
  ];

  return (
    <div className="dashboard_card03">
      <div className="px-5 py-4">
        <header className="flex items-center">
          {/* Icon */}
          <img src={Icon} width="35" height="35" alt="Icon 03" />
          <h2 className="font-normal text-[#787878] text-[20px] ml-3">Create Vouchers by discount type</h2>
        </header>
      </div>
      <div className='discount_list'>
        <ul>
          <li>Amount Discount</li>
          <li>Percent Discount</li>
          <li>Fixed Order Amount</li>
          <li>Unit Discount</li>
          <li>Free Shipping</li>
        </ul>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            // data={pieChartData?.length > 0 ? pieChartData : data}
            data={pieChartData}
            cx="70%"
            cy="40%"
            // innerRadius={65}
            outerRadius={95}
            fill="#3e7ce4"
            label
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DashboardCard03;
