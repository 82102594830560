/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Info from '../../Common/Info/Info';
import {
  checkPattern,
  errorHandler,
  generateVoucherHandle,
  getAccessToken,
  getAdvanceSetting,
  getCategory,
  getCharset,
  getCodeCount,
  getCodeLength,
  getCouponName,
  getDescription,
  getInitialValue,
  getJoiningHandle,
  getMaxRedemption,
  getPattern,
  getPostfix,
  getPrefix,
  getProgramType,
  getRedemption,
  nestedObjectHandle,
  voucherTypeHandle
} from '../../../helper/helper';
import CreateCategoryModal from '../CreateCategoryModal/CreateCategoryModal';
import { getVoucherDetailAction } from '../../Vouchers/services/users';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Col, Row, Tooltip } from 'antd';
var voucher_codes = require('voucher-code-generator');

const CampainFirstStep = ({ onChange, value, setFormValues, setFromData, showError, setShowError }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  let param = useParams();
  let updateVoucher = location?.state?.type;
  let campaignName = location.state.campaignName;

  /* --------get edit data & set in setFromData with changes----- */
  const reducerData = useSelector((state) => state?.vouchers?.first);
  const initialValue = useSelector(state => state?.rootReducer?.voucherDetail);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [categoryListData, setCategoryListData] = useState([])
  const [couponType, setCouponType] = useState(true)
  const [showUpdateInfo, setShowUpdateInfo] = useState(reducerData?.autoUpdate)
  const [showLimit, setShowLimit] = useState(reducerData?.programLimit)
  // const [showAdvanceSetting, setShowAdvanceSetting] = useState(getAdvanceSetting(initialValue) || '')
  const [showAdvanceSetting, setShowAdvanceSetting] = useState(false)
  const [isChecked, setIsChecked] = useState(reducerData?.joiningAllowedOnce);
  const [voucherType, setVoucherType] = useState();
  const [codeLength, setCodeLength] = useState();
  const [prefixData, setPrefixData] = useState();
  const [postfixData, setPostfixData] = useState();
  const [patternData, setPatternData] = useState();
  const [codeCount, setCodeCount] = useState();
  const [type, setType] = useState()

  /*-----edit voucher handle-----*/

  const edithandle = () => {
    const id = sessionStorage.getItem("editVoucher");
    const token = sessionStorage.getItem('userToken')
    dispatch(getVoucherDetailAction(id, token));
  };

  useEffect(() => {
    if (updateVoucher === 'editVoucher') {
      setFromData(initialValue)
    }
    if (updateVoucher) {
      edithandle();
    }
  }, [updateVoucher]);

  // useEffect(() => {
  //   return () => dispatch({
  //     type: 'GET_VOUCHER_DETAIL',
  //     payload: [],
  //   });
  // }, []);

  useEffect(() => {
    setFormValues('campaignType', param.voucherType)
  }, [updateVoucher])
  /*---onChange handler---*/
  const couponTypeHandler = (e) => {
    setCouponType(!couponType);
    // setFormValues("programType", e.target.value)
  };

  const programNameHandle = (e) => {
    if (e.target.value.trim()?.length > 0) {
      setShowError('')
      setFormValues("couponName", e?.target?.value)
    }
  };

  const maxRedeemHandle = (e) => {
    if (e.target.value > 0) {
      setShowError('')
      setFormValues("maxRedeemCount", e.target.value)
    }
  }
  const updateChange = (e) => {
    setShowUpdateInfo(!showUpdateInfo);
    // setFormValues("autoUpdate", e.target.checked);
  }
  const limitChange = (e) => {
    setShowLimit(!showLimit)
    // setFormValues('checkLimit', e.target.checked)
  }
  const customersCanJoinHandler = (e) => {
    setIsChecked(!isChecked);
    // setFormValues("joiningAllowedOnce", e.target.checked)
  };
  const codeCountHandle = (e) => {
    if (e.target.value > 0) {
      setShowError('')
    }
    setCodeCount(e.target.value)
    // setFormValues("codeCount", e.target.value)
  }

  /*----advance setting handler-----*/

  const settingChange = (e) => {
    setShowAdvanceSetting(!showAdvanceSetting);
    if (e.target.checked === true) {
      setShowError('')
    }
    // setFormValues("isAdvanced", e.target.checked)
  }
  const codeLengthHandle = (e) => {
    if (e.target.value > 0) {
      setShowError('')
    }
    setCodeLength(e.target.value)
    // setFormValues("codelength", e.target.value)
  };
  const charsetHandle = (e) => {
    setType(e.target.value)
    if (e.target.value === 'alphabetic' ||
      e.target.value === 'alphabeticUppercase' ||
      e.target.value === 'alphabeticLowercase' ||
      e.target.value === 'number' ||
      e.target.value === 'custom') {
      setShowError('')
    }
    setVoucherType(voucherTypeHandle(e.target.value))
    // setFormValues("charset", e.target.value)
  };
  useEffect(() => {
    setVoucherType(voucherTypeHandle())
  }, []);

  function onChangeSearch(e) {
    // setFormValues("category", e.target.value)
  };
  const patternHandle = (e) => {
    if (codeCount > 1 && e.target.value.includes('#')) {
      setCodeLength(e.target.value.length)
      setPatternData(e.target.value)
    } else if (codeCount > 1 && !e.target.value.includes('#')) {
      setPatternData('')
    } else {
      const patternType = checkPattern(e.target.value, codeCount);
      if (patternType === true) {
        setCodeLength(e.target.value.length)
        setPatternData(e.target.value)
      } else {
        setPatternData()
      }
    }
    if (e.target.value.length > 0) {
      setCodeLength('')
    }
  };

  const prefixHandle = (e) => {
    setPrefixData(e.target.value)
    // setFormValues("prefix", e.target.value)
  }
  const postfixHandle = (e) => {
    setPostfixData(e.target.value)
    // setFormValues("postfix", e.target.value)
  };

  /*----- voucher generater handle--*/

  const generateVoucherHandle = () => {
    return (
      voucher_codes.generate({
        length: codeLength,
        count: codeCount,
        charset: voucher_codes.charset(voucherType?.type),
        prefix: prefixData,
        postfix: postfixData,
        pattern: patternData
      })
    )
  };
  useEffect(() => {
    if (voucherType || codeCount || codeLength) {
      // setFormValues('couponCode', generateVoucherHandle(voucherType, codeLength, prefixData, postfixData, patternData))
      let coupon = generateVoucherHandle(voucherType, codeLength, prefixData, postfixData, patternData)
      if (type === 'alphabeticUppercase') {
        setFormValues('couponCode', coupon.map((item) => item.toUpperCase()))
      } else if (type === 'alphabeticLowercase') {
        setFormValues('couponCode', coupon.map((item) => item.toLowerCase()))
      } else {
        setFormValues('couponCode', coupon)
      }
    }
  }, [voucherType, codeCount, codeLength, prefixData, postfixData, patternData]);

  // create category handler
  const createCategoryHandler = () => {
    setIsModalOpen(!isModalOpen)
  }

  // get all category 
  // useEffect(() => {
  //   const categoryList = dispatch(getCategoryAction())
  //   categoryList.then((res) => {
  //     setCategoryListData(res.categoryList)
  //   })
  // }, []);

  useEffect((e) => {
    // getProgramType() === undefined ? setFormValues('programType', 1) : setFormValues('programType', e.target.value)
  }, []);

  return (
    <div className='mb-5'>
      {campaignName !== 'referralCodes'
        ?
        <div className="flex mb-2 space-x-2">
          <div className='p-3 bg-white shadow-md w-44'>
            <label>Bulk Codes</label>
            <input
              className='float-right'
              type="radio"
              value={'bulk'}
              name="programType"
              onChange={(e) => { onChange(e); couponTypeHandler(e); }}
              checked={getProgramType(initialValue, couponType, reducerData)?.bulk}
              // defaultChecked
              disabled
            />
          </div>
          <div className='p-3 bg-white shadow-md w-44'>
            <label>Standalone code</label>
            <input
              className='float-right'
              type="radio"
              value={'standalone'}
              name="programType"
              onChange={(e) => { onChange(e); couponTypeHandler(e); }}
              checked={getProgramType(initialValue, couponType, reducerData)?.standard}
              disabled
            />
          </div>
        </div>
        :
        ""
      }
      <div className="p-4 bg-white shadow-md">
        <div className='flex justify-between'>
          <div className="w-2/5 lg:mb-6">
            <label className={`block my-1 ${showError?.name && 'text-red-600'}`}>Program Name</label>
            <input
              name="couponName"
              className={`w-full ${showError?.name && 'border border-red-600'}`}
              type="text"
              placeholder="Coupon name"
              // defaultValue={initialValue ? initialValue?.couponName : reducerData?.couponName}
              defaultValue={getInitialValue(initialValue.couponName)}
              onChange={(e) => programNameHandle(e)}
            />
            {/* {showError?.name ? errorHandler() : ''} */}
          </div>
          <div className="w-2/5 mt-6">
            <label className='flex'>
              <input
                checked={showUpdateInfo}
                className='mr-2'
                type="checkbox"
                name="autoUpdate"
                onChange={updateChange}
                disabled
              />
              Auto Update</label>
          </div>
        </div>
        {showUpdateInfo && (
          <Info>
            Auto update option will create a campaign that can be enhanced by
            new vouchers after the time of creation. This functionality is
            useful in case you pu blish multiple vouchers among customers and
            you want to ensure the number of available codes.
          </Info>
        )}
        <div className="lg:mb-8">
          <label className='flex items-center'>
            <input
              type="checkbox"
              name='joiningAllowedOnce'
              className='m-2'
              checked={getJoiningHandle(initialValue) || reducerData?.joiningAllowedOnce}
              onChange={(e) => customersCanJoinHandler(e)}
              disabled
            />
            <span className="">
              Customers will be allowed to join campaign only once
            </span>
          </label>
        </div>
        <div className="lg:mb-6 d-flex">
          <Row>
            <Col lg={21}>
              <label className='my-1'>Select a category</label>
              <select
                name="category"
                className='w-full border-blue-100'
                // defaultValue={getMaxRedemption(initialValue.programSize.category) || reducerData?.category}
                onChange={onChangeSearch}
                disabled
              >
                {categoryListData?.map((item, index) => <option value={item.categoryKey} key={index}>{item.categoryValue}</option>)}
              </select>
            </Col>
            <Col lg={3}>
              <Button type='primary' style={{ background: '#1890ff', width: '90%' }} size='large' className='mt-6 ml-2' onClick={createCategoryHandler}>Create</Button>
            </Col>
          </Row>
        </div>
        <div className="lg:mb-6">
          <label>Description</label>
          <textarea
            name="description"
            placeholder="Description"
            maxLength={100}
            className="w-full"
            onChange={(e) => setFormValues("description", e.target.value)}
            // defaultValue={getMaxRedemption(initialValue.programSize.description) || reducerData?.description}
            disabled
          />
        </div>
        <div className="flex items-center justify-between lg:mb-6">
          {!showLimit ? (
            <div className='w-2/5'>
              <label className={`block my-1 ${showError?.maxRedeemCount && 'text-red-600'}`}>Max Redemption</label>
              <input
                name="maxRedeemCount"
                type="number"
                min={1}
                placeholder="Code redemption limit"
                className={`w-full ${showError?.maxRedeemCount && 'border border-red-600'}`}
                onChange={(e) => maxRedeemHandle(e)}
                // defaultValue={Object.keys(initialValue).length > 0 ? initialValue?.maxRedeemCount : 1}
                defaultValue={getInitialValue(initialValue?.maxRedeemCount) || reducerData?.redemptionLimit}
              />
            </div>
          ) : (
            <div className="pt-2 text-lg font-medium text-blue-400 border-b border-blue-300 border-dashed">
              Code Redemption Limit
            </div>
          )}
          <div className="w-2/5 mt-6">
            <label className='mr-2 inline-flex'>
              <input
                type="checkbox"
                className='mr-2'
                name="checkLimit"
                placeholder='unlimited'
                checked={showLimit}
                onChange={limitChange}
              />
              Unlimited</label>
          </div>
        </div>
        <div className="flex items-center justify-between lg:mb-6">
          <div className='w-2/5'>
            <label className="block my-1">Code Count</label>
            <input
              name="codeCount"
              type="number"
              min={1}
              className={`w-full ${initialValue && Object.keys(initialValue).length !== 0 ? 'cursor-not-allowed' : ''}`}
              placeholder="Code count"
              onChange={(e) => codeCountHandle(e)}
              disabled={initialValue && Object.keys(initialValue).length !== 0}
            // defaultValue={(getInitialValue(initialValue.codeCount) || reducerData?.codeCount)}
            />
            {/* {showError?.count ? errorHandler() : ''} */}
          </div>
          <div className="w-2/5 mt-6">
            <label className='mr-2 inline-flex'>
              <input
                type="checkbox"
                name="isAdvanced"
                className={`mr-2 ${initialValue && Object.keys(initialValue).length !== 0 ? 'cursor-not-allowed' : ''}`}
                checked={showAdvanceSetting || ''}
                onChange={(e) => settingChange(e)}
                disabled={initialValue && Object.keys(initialValue).length !== 0}
              />
              Advanced code settings</label>
            {/* {showError?.advance ? errorHandler() : ''} */}
          </div>
        </div>
      </div>
      {showAdvanceSetting ? (
        <div className="p-4 mt-4 bg-white shadow-md">
          <div className="text-lg font-medium text-blue-500 ">
            Advance Setting
          </div>
          <div className="pt-6">
            {codeLength && (
              <Info>
                you can generate {Math.pow(voucherType?.character?.length, codeLength)} unique coupon code
              </Info>
            )}
            <label className="block my-1">Charset</label>
            <select
              name="charset"
              className='w-full outline-none'
              placeholder="Charset"
              onChange={(e) => charsetHandle(e)}
            // defaultValue={getCharset(initialValue) || reducerData?.charset}
            >
              <option value="alphabetic">Alphabetic</option>
              <option value="alphanumeric">AlphaNumeric</option>
              <option value="alphabeticUppercase">Alphabetic Uppercase</option>
              <option value="alphabeticLowercase">Alphabetic Lowercase</option>
              <option value="numbers">Numbers</option>
            </select>
            {/* {showError?.charset ? errorHandler() : ''} */}
            <div className="mt-1 mb-6">
              <div className="pt-2 text-base text-blue-300 border-b border-blue-300 border-dashed">
                {voucherType?.types}
              </div>
            </div>
            <div className='flex flex-wrap justify-between'>
              <div className='w-2/5'>
                <label className="block my-1 ">Code Length</label>
                <input
                  name="codelength"
                  type="number"
                  min={1}
                  max={100}
                  className='w-full outline-none'
                  placeholder="Code length"
                  value={codeLength}
                  onChange={(e) => codeLengthHandle(e)}
                // defaultValue={getCodeLength(initialValue) || reducerData?.codelength}
                />
                <div className="text-xs text-blue-300">
                  Perfect length of a coupon code is between 8-12 characters long
                </div>
                {showError?.length ? errorHandler() : ''}
              </div>
              <div className='w-2/5'>
                <label className="block my-1">Pattern</label>
                <input
                  name="pattern"
                  type="text"
                  className='w-full outline-none'
                  placeholder="Pattern"
                  onChange={(e) => patternHandle(e)}
                // defaultValue={getPattern(initialValue) || reducerData?.pattern}
                />
                <div className="text-xs text-blue-300">
                  # - is replaced by random char.
                </div>
              </div>
              <div className='w-2/5'>
                <label className="block my-1">Prefix</label>
                <input
                  name="prefix"
                  type="text"
                  id="prefix"
                  className='w-full outline-none'
                  placeholder="Prefix"
                  onChange={(e) => prefixHandle(e)}
                // defaultValue={getPrefix(initialValue) || reducerData?.prefix}
                />
                <div className="text-xs text-blue-300">
                  Your voucher code will start with a given prefix
                </div>
              </div>
              <div className='w-2/5'>
                <label className="block my-1 ">Postfix</label>
                <input
                  name="postfix"
                  type="text"
                  className='w-full outline-none'
                  placeholder="Postfix"
                  onChange={(e) => postfixHandle(e)}
                // defaultValue={getPostfix(initialValue) || reducerData?.postfix}
                />
                <div className="text-xs text-blue-300">
                  Your voucher code will end with a given postfix
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : " "}
      <CreateCategoryModal isModalOpen={isModalOpen} createCategoryHandler={setIsModalOpen} />
    </div>
  );
}
export default CampainFirstStep;