import React, { useEffect, useState } from 'react';
import { Table, Tag, Popconfirm, Button, Empty, Tabs, Switch, Spin, Tooltip } from 'antd';
import { DeleteVoucherHandle } from '../../Common/EditDeleteVoucher/EditDeleteHandle';
import DropdownHandle from '../../Common/dropdown/DropdownHandle';
import { getAllVoucherAction } from '../services/users';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import PageHead from '../../Common/PageHead/Index';
import AppContainer from '../../AppContainer';
import edit from '../../../assets/icons/edit.png'
import trash from '../../../assets/icons/delete.png'

function Users({ total, page: current }) {

  const [dataSource, setDataSource] = useState();
  const [updateModal, setUpdateModal] = useState(false)
  const [editableData, setEditableData] = useState({})
  const [activeCoupon, setActiveCoupon] = useState();
  const [expireCoupon, setExpireCoupon] = useState();
  const { data, loading } = useSelector(state => state.rootReducer);
  const token = sessionStorage.getItem('userToken');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = async (values) => {
    await dispatch(getAllVoucherAction(token));
  }


  useEffect(() => {
    fetchData();
    setEditableData(editableData)
  }, []);

  const activeCouponHandle = (item) => {
    const active = item && item?.filter((coupon) => coupon.activeStatus === 'true')
    setActiveCoupon(active)
  }
  const expireCouponHandle = (item) => {
    const active = item && item?.filter((coupon) => coupon.activeStatus === 'false')
    setExpireCoupon(active)
  }
  useEffect(() => {
    if (data?.length > 0) {
      setDataSource(data)
    }
    activeCouponHandle(data)
    expireCouponHandle(data)
  }, [data]);

  const deleteHandler = async (id) => {
    DeleteVoucherHandle(id?.id, token, dispatch)
  }

  const editHandler = async (id, values) => {
    sessionStorage.setItem("editVoucher", id?.id);
    navigate('/campaign/edit/discountCoupon',
      { state: { type: 'editVoucher' } }
    );
  }

  const voucherStatusHandle = (e) => {
    // setSwitchBtn(!switchBtn)
  };
  const allVoucherHandle = (tab) => {
    setDataSource(data)

  }
  const activeVoucherHandle = () => {
    setDataSource(activeCoupon)
  }
  const expireVoucherHandle = () => {
    setDataSource(expireCoupon)
  }

  const [activeTab, setActiveTab] = useState("Total Vouchers");

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  };


  const columns = [
    {
      title: 'Coupon Code',
      dataIndex: 'couponCode',
      key: 'couponCode',
      width: '170px',
      render: (index, item) => <div key={index}>
        <Link to={`/vouchers/detail/${item.id}`} className="text-[#1253cd]">{item?.couponCode}</Link>
      </div>,
    },
    {
      title: 'Created on',
      dataIndex: 'activeDate',
      key: 'activeDate',
      width: '120px',
      render: (index, item) => <span key={index}>{<Tag color="blue">{item?.activeDate}</Tag> || "---"}</span>,
    },
    {
      title: 'Redeemed quantity',
      dataIndex: 'totalRedeemCount',
      key: 'totalRedeemCount',
      render: (index, item) => <span key={index}>{<Tag color="orange">{item?.totalRedeemCount}</Tag> || "---"}</span>,
    },
    {
      title: 'Redemption limit',
      dataIndex: 'maxRedeemCount',
      key: 'maxRedeemCount',
      render: (index, item) => <span key={index}>{<Tag color="blue">{item?.maxRedeemCount}</Tag> || "---"}</span>,
    },
    {
      title: 'Timeframe',
      dataIndex: 'activeDate',
      key: 'activeDate',
      width: '240px',
      render: (index, item) => <span key={index} className='tableRowSec'>
        <span>{item?.activeDate}</span>
        <span className='mx-2 mb-[7px]'>{<ArrowRightOutlined />}</span>
        <span>{item?.expiryDate}</span>
      </span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (index, item) => <span key={index}>
        <Switch checked={item.activeStatus === "true"} onChange={(e) => voucherStatusHandle(e)} />
        {
          item.activeStatus === "true" ? "voucher is active" : "voucher is expired"
        }
      </span>
    },
    {
      title: 'Operations',
      key: 'operation',
      width: '140px',
      fixed: 'right',
      render: (text, record) => (
        <span className='flex justify-center items-center gap-3' key={text.id}>
          {/* <Tooltip title="prompt text"> */}
          <img src={edit} alt="edit" onClick={() => { editHandler(record, text); setUpdateModal(false); }} className='w-4 h-4 cursor-pointer' />
          {/* </Tooltip> */}
          <Popconfirm title="Confirm to delete?" onConfirm={deleteHandler.bind(text._id, text)}>
            {/* <Tooltip title="delete"> */}
            <img src={trash} alt="delete" className='w-4 h-4 cursor-pointer' />
            {/* </Tooltip> */}
          </Popconfirm>
          {/* <DropdownHandle demo={"voucher"} /> */}
        </span>
      ),
    },
  ];
  return (
    <AppContainer>
      <div>
        <div>
          <PageHead
            title="Vouchers"
            breadcrumbs={[
              {
                path: '/', breadcrumbName: "Home",
              },
              {
                path: "/", breadcrumbName: "Vouchers"
              },
            ]}
            bgNone={true}
            button={
              <Link to="/campaign">
                <Button type="primary" size="medium" className='bg-[#1890ff] rounded-[5px] text-[12px]'>
                  Create Voucher
                </Button>
              </Link>
            }
            subTitle="Get all the vouchers here"
          >
            <div>
              <div className="sm:hidden">
                <label htmlFor="Tab" className="sr-only">Tab</label>
                <select
                  id="Tab"
                  className="w-full rounded-md border-gray-200"
                  value={activeTab}
                  onChange={(e) => handleTabChange(e.target.value)}
                >
                  <option value="Total Vouchers">Total Vouchers</option>
                  <option value="Active Vouchers">Active Vouchers</option>
                  <option value="Expired Vouchers">Expired Vouchers</option>
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex gap-6" aria-label="Tabs">
                    <p
                      onClick={() => { handleTabChange("Total Vouchers"); allVoucherHandle() }}
                      className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === "Total Vouchers"
                        ? "border-sky-500 text-sky-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                    >
                      Total Vouchers
                    </p>
                    <p
                      onClick={() => { handleTabChange("Active Vouchers"); activeVoucherHandle() }}
                      className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === "Active Vouchers"
                        ? "border-sky-500 text-sky-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                    >
                      Active Vouchers
                    </p>
                    <p
                      onClick={() => { handleTabChange("Expired Vouchers"); expireVoucherHandle() }}
                      className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === "Expired Vouchers"
                        ? "border-sky-500 text-sky-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                    >
                      Expired Vouchers
                    </p>
                  </nav>
                </div>
              </div>
            </div>
            {/* <div className='flex'>
              <div className='pl-4'>
                <button className='p-3 w-full border border-[#dce1e5] rounded-md bg-[#1890ff] text-[18px] items-center text-white' onClick={allVoucherHandle}>Total Vouchers :
                  <span className='font-medium rounded-md py-1 px-3 bg-white text-[#4a4a4a] ml-1'>{data ? data.length : 0}</span> </button>
              </div>
              <div className='pl-4'>
                <button className='p-3 w-full border border-[#dce1e5] rounded-md bg-[#1890ff] text-[18px] items-center text-white' onClick={activeVoucherHandle}>Active Vouchers :
                  <span className='font-medium rounded-md py-1 px-3 bg-white text-[#4a4a4a] ml-1'>{activeCoupon ? activeCoupon?.length : 0}</span> </button>
              </div>
              <div className='pl-4'>
                <button className='p-3 w-full border border-[#dce1e5] rounded-md bg-[#1890ff] text-[18px] items-center text-white' onClick={expireVoucherHandle}>Expired Vouchers :
                  <span className='font-medium rounded-md py-1 px-3 bg-white text-[#4a4a4a] ml-1'>{expireCoupon ? expireCoupon?.length : 0}</span> </button>
              </div>
            </div> */}
            <div className="mt-4">
              <Table
                key={dataSource?.id}
                loading={loading}
                columns={columns}
                dataSource={dataSource || []}
                rowKey={(record) => record._id}
                pagination={true}
                scroll={{ x: 1300 }}
                locale={{
                  emptyText: (
                    <div style={{ textAlign: 'center' }} key={dataSource?.id}>
                      <Empty description={false} style={{ fontSize: 20 }} />
                      <p>No Vouchers Found!</p>
                    </div>
                  )
                }}
              />
            </div>
          </PageHead>
        </div>
      </div>
    </AppContainer>
  );
}

export default Users;
