/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDownOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import DiscountCard from '../../../../Common/DiscountCard/DiscoutCard';
import Info from '../../../../Common/Info/Info'
import AddOrder from '../AddOrder/AddOrder';
import AttributeMatch from '../Attribute/AttributeMatch';
import {
  getCheckPercentageStatus,
  getDiscountAmount,
  getDiscountValue,
  getPercentageActivity,
  getPercentageAttributeData,
  getPercentageBudgetCampaign,
  getPercentageBudgetDiscount,
  getPercentageBudgetOrder,
  getPercentageBudgetPercode,
  getPercentageBudgetPerday,
  getPercentageBudgetRedemption,
  getPercentageDisc,
  getpercentageDiscount,
  getPercentageLimit,
  getPercentageOrderCondition,
  getPercentageOrderValue,
  getPercentageValue
} from '../../../../../helper/helper';
import Budget from '../Budget/Budget';
import DiscountToFilter from '../DiscountToFilter/DiscountToFilter';

const PercentageType = ({ setFormValues, Error, setError }) => {

  const [selectedDiscountTo, setSelectedDiscountTo] = useState(true)
  const [segmentSelection, setSegmentSelection] = useState('Customer activity')
  const [createValidation, setCreateValidation] = useState(true)
  const [selectedValidation, setSelectedValidation] = useState('Customer activity')
  const reducerData = useSelector(state => state?.rootReducer?.data);
  const initialValue = useSelector((state) => state?.rootReducer?.voucherDetail);

  const location = useLocation();
  let campaignName = location.state.campaignName;

  const changeDiscount = (e) => {
    setSelectedDiscountTo(!selectedDiscountTo)
    setFormValues('applyDiscountOn', e.target.value)
    if (e.target.value === 'items') {
      setCreateValidation(false)
    } else {
      setCreateValidation(true)
    }
  }
  const customerSegmentHandler = (e) => {
    setFormValues('acitivityCondition', e.target.value);
    setSegmentSelection(e.target.value);
  }
  const percentageOrderHandler = (e) => {
    setFormValues('condition', e.target.value)
  }
  const orderConditionUSDHandler = (e) => {
    setFormValues('value', e.target.value);
  }

  const maxDiscountHandle = (e) => {
    if (e.target.value > 0) {
      setError('')
    }
    setFormValues("maxDiscountAmount", e.target.value)
  }

  const percentageDiscountHandle = (e) => {
    if (e.target.value > 0) {
      setError('')
    }
    setFormValues("percentageDiscount", e.target.value)
  }

  const renderInfoText = useCallback(() => {
    switch (selectedDiscountTo) {
      case 'wholeCart':
        return 'whole Cart – Discount will be applied to the total order amount.';
      case 'items':
        return 'items – Discount will be applied to the products below. Each product subtotal will be discounted equally.';
      default:
        return 'whole Cart – Discount will be applied to the total order amount.'
    }
  }, [selectedDiscountTo]);

  const validationType = [
    {
      imageSrc: 'https://img.icons8.com/ios-filled/50/000000/user.png',
      discountType: 'Customer activity',
      subTitle: '(e.g. cart abandoned)'
    },
    {
      imageSrc: 'https://img.icons8.com/glyph-neue/64/000000/stack.png',
      discountType: 'Attributes match',
      subTitle: '(e.g. 10% off)'
    },
    {
      imageSrc: 'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png',
      discountType: 'Budget',
      subTitle: '(e.g. total order value)'
    },
    {
      imageSrc: 'https://img.icons8.com/dotty/80/000000/create-order.png',
      discountType: 'Order',
      subTitle: '(e.g. total amount)'
    }
  ]

  const renderValidationType = useCallback(() => {
    switch (selectedValidation) {
      case 'Customer activity':
        return (
          <Row gutter={12}>
            <Col lg={12}>
              <span className="w-full">Condition</span>
              <select
                name="acitivityCondition"
                className="w-full border-blue-200 outline-none"
                placeholder="Customer segment"
                defaultValue={getPercentageActivity(initialValue, reducerData)}
                onChange={customerSegmentHandler}
              >
                <option >Select Customer Segment.... </option>
                <option value="true">Customer is in segment</option>
                <option value="false">Customer is not in segment </option>
              </select>
            </Col>
            <Col lg={12}>
              <div className="mt-5">
                <Form.Item name="">
                  <Button type="primary" style={{ background: '#1890ff' }} size="large">
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        );
      case 'Attributes match':
        return <AttributeMatch setFormValues={setFormValues} initialValue={getPercentageAttributeData(initialValue, reducerData).data} reducerData={reducerData} />;
      case 'Budget':
        return (
          <>
            <Budget
              initialValue={getPercentageBudgetOrder(initialValue, reducerData)}
              title="Total orders value"
              fieldName={"totalOrderValue"}
              setFormValues={setFormValues}
              show={false}
            />
            <Budget
              initialValue={getPercentageBudgetDiscount(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total discounted amount"
              fieldName={"totalDiscountAmount"}
              show={false}
            />
            <Budget
              initialValue={getPercentageBudgetRedemption(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total number of redemptions"
              fieldName={"totalNumberOfRedemption"}
              show={false}
            />
            <div className="mt-6">
              <div className="pb-4 text-lg font-medium">
                Redemptions per customer
              </div>
              <div className="border-l-2 border-blue-400 border-dashed">
                <div className="pl-4">
                  <Budget
                    initialValue={getPercentageBudgetPercode(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code"
                    fieldName={"perCode"}
                    show={false}
                  />
                  <Budget
                    initialValue={getPercentageBudgetPerday(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code per day"
                    fieldName={"perCodePerDay"}
                    show={false}
                  />
                  <Budget
                    initialValue={getPercentageBudgetCampaign(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="In campaign"
                    fieldName={"inCampaign"}
                    show={false}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 'Order':
        return (
          <>
            <div className="">
              <Row gutter={6}>
                <Col lg={8}>
                  <div className="pt-2 text-lg font-semibold">Total amount</div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <select
                      name="condition"
                      size="large"
                      className="w-full border-blue-200 outline-none"
                      placeholder="Condition"
                      defaultValue={getPercentageOrderCondition(initialValue, reducerData)}
                      onChange={percentageOrderHandler}
                    >
                      <option >select condition </option>
                      <option value="isLessThan">is less than</option>
                      <option value="IsExactly">is exactly</option>
                      <option value="isMoreThan">is more than</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <input
                      type="number"
                      name="value"
                      placeholder="Value (USD)"
                      defaultValue={getPercentageOrderValue(initialValue, reducerData)}
                      onChange={orderConditionUSDHandler}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="pl-2">
                    <Button type="primary" style={{ background: '#1890ff' }} size="large">
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <AddOrder orderType="Any" setFormValues={setFormValues} title="Any order item" reducerData={reducerData} />
                <AddOrder orderType="Every" setFormValues={setFormValues} title="Every order item" reducerData={reducerData} />
              </div>
            </div>
          </>
        );
      default:
        return 'whole Cart – Discount will be applied to the total order amount.'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValidation])

  useEffect(() => {
    getCheckPercentageStatus(initialValue, reducerData, selectedDiscountTo)
  }, [selectedDiscountTo]);

  useEffect((e) => {
    getCheckPercentageStatus() === undefined ? setFormValues('applyDiscountOn', "wholeCart") : setFormValues('applyDiscountOn', e.target.value);
  }, [])

  return (
    <>
      <div className="p-4 bg-white">
        <Row gutter={12}>
          {/* <Col lg={12}>
            <span className="w-full -mb-4">Value (%)</span>
            <input
              type="number"
              min={0}
              name="percentageValue"
              className="w-full"
              placeholder="Value (%)"
              defaultValue={getPercentageValue(initialValue, reducerData)}
              onChange={e => setFormValues('percentageValue', e.target.value)}
            />
          </Col>
          <Col lg={12}>
            <span className="w-full -mb-4">Amount Limit (USD)</span>
            <input
              type="number"
              min={0}
              name="percentageLimit"
              className="w-full"
              placeholder="Amount limit (USD)"
              defaultValue={getPercentageLimit(initialValue, reducerData)}
              onChange={e => setFormValues('percentageLimit', e.target.value)}
            />
          </Col> */}
          <Col lg={12}>
            <span className={`block ${Error?.maxDiscountAmount && 'text-red-600'}`}>Discount value (USD)</span>
            <input
              name="maxDiscountAmount"
              type="number"
              min={0}
              className={`w-full outline-none ${Error?.maxDiscountAmount && 'border border-red-600'}`}
              defaultValue={getDiscountAmount(initialValue, reducerData)}
              placeholder="Discount Value (USD)"
              // onChange={(e) => setFormValues("maxDiscountAmount", e.target.value)}
              onChange={(e) => maxDiscountHandle(e)}
            />
          </Col>
          <Col lg={12}>
            <span className={`block ${Error?.percentageDiscount && 'text-red-600'}`}>Discount value (%)</span>
            <input
              name="percentageDiscount"
              type="number"
              min={0}
              className={`w-full outline-none ${Error?.percentageDiscount && 'border border-red-600'}`}
              defaultValue={getpercentageDiscount(initialValue, reducerData)}
              placeholder="Discount Value (USD)"
              // onChange={(e) => setFormValues("percentageDiscount", e.target.value)}
              onChange={(e) => percentageDiscountHandle(e)}
            />
          </Col>
          <Col lg={24}>
            <div className="w-full mb-2 text-lg mt-10">Apply discount to</div>
            <div className="flex mb-2 space-x-2">
              <div className='p-4 bg-white border shadow-md w-44 h-14'>
                <label>Whole cart</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"wholeCart"}
                  name="applyDiscountOn"
                  checked={getCheckPercentageStatus(initialValue, reducerData, selectedDiscountTo)?.whole}
                  onChange={(e) => changeDiscount(e)}
                  defaultChecked
                />
              </div>
              <div className='p-4 bg-white border shadow-md w-44 h-14'>
                <label>Items</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"items"}
                  name="applyDiscountOn"
                  checked={getCheckPercentageStatus(initialValue, reducerData, selectedDiscountTo)?.items}
                  onChange={(e) => changeDiscount(e)}
                />
              </div>
            </div>
          </Col>
          {/* <Col lg={24}>
            {selectedDiscountTo && (
              <Info>
                <span>Apply to </span> {renderInfoText()}
                {selectedDiscountTo !== "wholeCart" && (
                  <div className="flex">
                    <div className="mt-1 mr-2">
                      <ArrowDownOutlined />
                    </div>
                    <div className="mt-2 font-bold">
                      Choose discounted products
                    </div>
                  </div>
                )}
              </Info>
            )}
          </Col> */}
        </Row>
      </div>
      <div className="mb-8">
        {/* {selectedDiscountTo === "wholeCart" && (
          <div className="px-4 py-8 mt-4 bg-white">
            <Info>
              Validation rules help you define promotions’ eligibility
              conditions based on audience, order structure, order volume,
              budget constraints,and others - <Link to="#">read more</Link>
            </Info>
          </div>
        )} */}
        {/* {createValidation ? (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div>
              <Button
                type=""
                onClick={() => setCreateValidation(false)}
                size="large"
              >
                Create validation rule
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                Select existing validation rule
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex py-8 mt-4">
            <div>
              <Button type="" size="large">
                <EditOutlined /> Use advance builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button
                type=""
                onClick={() => setCreateValidation(true)}
                size="large"
              >
                Quit builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                <ExclamationCircleOutlined /> Get help
              </Button>
            </div>
          </div>
        )} */}

        {/* {!createValidation && <DiscountToFilter
          setFormValues={setFormValues}
          initialValue={initialValue}
          percentageType={"percentageType"}
        />}

        {!createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div className="flex flex-wrap">
              {validationType.map((item, i) => (
                <DiscountCard
                  i={i}
                  item={item}
                  selectedDiv={selectedValidation}
                  onClick={() => setSelectedValidation(item.discountType)}
                />
              ))}
            </div>
          </div>
        )}

        {!createValidation && (
          <div className="px-4 py-8 mt-4 bg-white">
            {renderValidationType()}
          </div>
        )} */}
      </div>
    </>
  );
}

export default PercentageType
