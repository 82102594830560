import React from 'react'
import './CustomLoader.scss'

const CustomLoader = () => {
    return (
        <div class="center-body">
            <div class="loader-circle-9">
                voucherify
                <span></span>
            </div>
        </div>

    )
}

export default CustomLoader