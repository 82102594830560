import React from 'react';
import icon from '../../assets/cardsImage/reedem2.png'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import './DashboardCard.scss'

function DashboardCard04({ pieChartData }) {
  const data = [
    { name: 'Discount Type', value: 1 },
    { name: 'Gift Type', value: 3 },
    { name: 'Referral Code', value: 2 },
    { name: 'Promotion', value: 6 },
    { name: 'Loyalty Program', value: 5 },
  ];

  const color = ["#3e7ce4", "#3e7ce4", "#3e7ce4", "#3e7ce4", "#3e7ce4"]
  // const processData = data.map((item, i) => ({
  //   ...item,
  //   color: color[i]
  // }))

  return (
    <div className="dashboard_card03">
      <header className="px-5 py-4 flex items-center">
        <img src={icon} width="32" height="32" alt="Icon 03" />
        <h2 className="font-normal text-[#787878] text-[20px] ml-3">Create campaign by types</h2>
      </header>
      <div className='discount_list'>
        <ul>
          <li>Discount Coupons</li>
          <li>Gift Vouchers</li>
          <li>Referral Codes</li>
          <li>Promotion</li>
          <li>Loyalty Program</li>
        </ul>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={pieChartData}
            cx="70%"
            cy="40%"
            outerRadius={95}
            fill="#3e7ce4"
            label
          >
            {pieChartData && pieChartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={color[index % color.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DashboardCard04;
