import React from 'react';
import redeem from '../../../assets/cardsImage/redeem2.png'
import './CouponCards.scss'


function CouponCard04({ pieChart }) {

  return (
    <div className="card_wrapper relative border-0 shadow-none text-[#4a4a4a] box-border h-full w-full">
      <div className='cards'>
        <div><img src={redeem} alt="gift card logo" className='cards_img' /></div>
        <div className='flex flex-col justify-between'>
          <p className='card_body'>Successful Redemptions</p>
          <div className='text-[25px] font-medium text-[#4a4a4a] leading-9 ml-[30%]'>{pieChart?.voucherRedeemCount ? pieChart?.voucherRedeemCount : 0}</div>
        </div>
      </div>
    </div>
  )
}

export default CouponCard04;