import React, { useState, useRef, useEffect } from 'react';
import UserAvatar from '../../images/user-avatar-32.png';
import Transition from '../../utils/Transition';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function UserMenu() {
  const userName = sessionStorage.getItem('userName')

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const dispatch = useDispatch();

  const logoutHandle = () => {
    setDropdownOpen(!dropdownOpen);
    dispatch({ type: 'LOGOUT_USER', payload: '' })
    sessionStorage.clear();
  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen || dropdown?.current?.contains(target) || trigger?.current?.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dropdownOpen]);

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex items-center justify-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img className="w-8 h-8 rounded-full" src={UserAvatar} width="32" height="32" alt="User" />
        <div className="flex items-center truncate">
          <span className="ml-2 text-sm font-medium truncate group-hover:text-blue-800">{userName}</span>
          <svg className="flex-shrink-0 w-3 h-3 ml-1 text-blue-400 fill-current" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-blue-200 py-1.5 rounded shadow-lg overflow-hidden mt-4 ml-12 w-40"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-blue-200">
            <div className="font-medium text-blue-800">Voucherify</div>
            <div className="text-xs italic text-blue-500">Administrator</div>
          </div>
          <ul>
            <li>
              <Link
                className="flex items-center px-3 py-1 text-sm text-indigo-500 cursor-pointer hover:text-indigo-600"
                to="/userkey"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                User Keys
              </Link>
            </li>
            <li>
              <Link
                className="flex items-center px-3 py-1 text-sm text-indigo-500 hover:text-indigo-600"
                to="/"
                onClick={() => logoutHandle()}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default UserMenu;