import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react'

const Budget = ({ title, fieldName, show, setFormValues, initialValue }) => {
  const [showDIv, setShowDiv] = useState(show);

  return (
    <div className="flex pb-4">
      <div className='pr-2 text-lg font-medium'>{title}</div>
      <div className='pr-2 text-lg text-blue-400'>
        <i>is less than or equal</i>
      </div>
      {showDIv ? (
        <div className='pl-2'>
          <input
            type="text"
            name={`${fieldName}`}
            defaultValue={initialValue}
            onChange={(e) => setFormValues(fieldName, e.target.value)}
            style={{ width: "50%" }}
          />
          <span className='pl-2 cursor-pointer' onClick={() => setShowDiv(false)}><CloseOutlined /></span>
        </div>
      ) : (
        <div className='px-4 rounded-md cursor-pointer hover:bg-blue-200' onClick={() => setShowDiv(true)}>
          <EditOutlined /> <span className='py-2 text-lg'>Set</span>
        </div>
      )}
    </div>
  );
}

export default Budget
