import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';

import CompaignTemplate from './CompaignTemplate/CompaignTemplate';
import AppContainer from '../AppContainer'
import PageHead from '../Common/PageHead/Index'
const { Paragraph } = Typography;

const CreateCampainTypes = () => {
  const secretKey = sessionStorage.getItem('secretKey');
  const [haveToken, setHaveToken] = useState(false);
  useEffect(() => {
    if (secretKey) {
      setHaveToken(true)
    } else {
      setHaveToken(false)
    }
  }, [secretKey]);


  const formatSecretKey = (key) => {
    if (!key || key.length <= 10) {
      return key;
    }
    const firstPart = key.slice(0, 10);
    const asterisks = '*'.repeat(key.length - 10);
    return firstPart + asterisks;
  };

  return (
    <AppContainer>
      <PageHead
        title="Campaign Types"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          {
            path: "/", breadcrumbName: "Campaign Types",
          },
        ]}
        bgNone={true}
        button={haveToken ? <div className='text-lg'><span className='pr-4'>Secret Key :</span>
          <p className='overflow-hidden text-ellipsis' title={secretKey}>
            <Paragraph className='w-52 flex flex-wrap mb-0' copyable={{ text: secretKey, tooltips: false }}>
              <p>{formatSecretKey(secretKey)}</p>
            </Paragraph>
          </p>
        </div>
          : <Link
            className="flex items-center px-3 py-1 text-sm font-medium text-indigo-500 cursor-pointer hover:text-indigo-600"
            to="/userkey"
          >
            <Button type="primary" size='small' className='bg-[#1890ff] h-[34px] rounded-[6px] text-[12px]' >Create Access & Secret key</Button>
          </Link>}
        subTitle="">
        <CompaignTemplate />
      </PageHead>
    </AppContainer>
  )
}

export default CreateCampainTypes;
