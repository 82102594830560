import React, { useState } from "react";
import DiscountCard from "../../../../Common/DiscountCard/DiscoutCard";

const ReferalCards = ({ data, campaignName, renderDiscountType, setSelectedDiv, setSelectedDiscount }) => {
  const [checkReferreType, setCheckReferrerType] = useState("Referrer and referee");
  const [referrerDiscont, setReferrerDiscont] = useState("Discount Type");
  const [referrerDiscountData, setTeferrerDiscountData] = useState("Amount type");

  let rewardTo = data.filter(item => item.isRefralType === true);
  let rewardType = data.filter(item => item.discountTypeInReferrel === true);
  let discountType = data.filter(item => item.isDiscountType === true);

  const discountTypeHandle = item => {
    renderDiscountType();
    setSelectedDiv(item.discountType);
    setSelectedDiscount(item.discount);
  };


  console.log(rewardTo, "rewardTo:::", rewardType, "rewardType:::::", discountType)

  return (
    <>
      <div className="mb-10">
        <div className="p-4 bg-white mb-10">
          <div className="text-xl mb-5">Reward To</div>
          <div className="flex flex-wrap content-evenly">
            {rewardTo.map((item, i) => (
              <DiscountCard
                key={i}
                i={i}
                item={item}
                selectedDiv={checkReferreType}
                onClick={() => {
                  setCheckReferrerType(item.discountType);
                  discountTypeHandle(item);
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="p-4 bg-white mb-10">
          <div className="text-xl mb-5">Reward Type</div>
          <div className="flex flex-wrap content-evenly">
            {rewardType.map((item, i) => (
              <DiscountCard
                key={i}
                i={i}
                item={item}
                selectedDiv={referrerDiscont}
                onClick={() => {
                  setReferrerDiscont(item.discountType);
                  discountTypeHandle(item);
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mb-10">
        <div className="p-4 bg-white">
          <div className="flex flex-wrap content-evenly">
            {discountType.map((item, i) => (
              <DiscountCard
                key={i}
                i={i}
                item={item}
                selectedDiv={referrerDiscountData}
                onClick={() => {
                  setTeferrerDiscountData(item.discountType);
                  discountTypeHandle(item);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferalCards;
