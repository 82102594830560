/* eslint-disable no-unused-vars */
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Info from '../../../../Common/Info/Info';
import { applyDiscountHandler } from '../Attribute/helper';
import DiscountToItem from '../DiscountToItem/DiscountToItem';

const DiscountToFilter = ({ setFormValues, initialValue, percentageType, AmountType }) => {

  const reducerData = useSelector((state) => state?.vouchers?.third);

  const [discountVisible, setDiscountVisible] = useState(false);
  const [excludVisible, setExcludVisible] = useState(false);
  const [selectedListItems, setSelectedListItems] = useState([]);
  const [isChecked, setIsChecked] = useState(true);

  const [included, setIncluded] = useState(false);
  const [exclued, setExcluded] = useState(false);
  const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([])
  const [selectedExcludedOptions, setSelectedExcludedOptions] = useState([])

  const key = excludVisible ? 'excludedItems' : 'includedItems';

  const onSelectDiscount = (e) => {
    setIsChecked(!isChecked);
    if (e.target.value === 'Each') {
      setFormValues("discountOn", e.target.value)
    } else {
      setFormValues("discountOn", e.target.value)
    }
  }

  const selectedItemHandler = () => {
    if (included === true && exclued === false) {
      setFormValues("discountedItems", selectedIncludedOptions);
    } else if (exclued === true && included === false) {
      setFormValues("excludedItems", selectedExcludedOptions)
    }
  };

  // const applyDiscountHandler = () => {
  //   if (initialValue && initialValue?.percentageType?.wholeCart?.discount?.discountOn === "Each") {
  //     return { each: isChecked }
  //   } else if (reducerData && reducerData?.discountOn === "Each") {
  //     return { each: isChecked }
  //   } else if (initialValue && initialValue?.percentageType?.wholeCart?.discount?.discountOn === "Selected") {
  //     return { select: isChecked }
  //   } else if (reducerData && reducerData?.discountOn === "Selected") {
  //     return { select: isChecked }
  //   }
  // };

  return <div className='px-4 py-4 mt-4 bg-white'>
    <div className='py-2'>
      <div className='flex items-baseline space-x-2'>
        <div className='pt-2 text-lg font-medium leading-5'>Discount</div>
        {!AmountType && percentageType
          ?
          <div className='flex w-2/6'>
            <div className='w-2/5 p-4 bg-white border-2 shadow-md'>
              <label>
                <input
                  type="radio"
                  style={{ 'float': "right" }}
                  value={"Each"}
                  name="applyDiscountTo"
                  onChange={onSelectDiscount}
                  checked={applyDiscountHandler(initialValue, reducerData, isChecked)?.each}
                />
                Each</label>
            </div>
            <div className='w-2/5 p-4 bg-white border-2 shadow-md'>
              <label>
                <input
                  type="radio"
                  style={{ 'float': "right" }}
                  value={"Selected"}
                  name="applyDiscountTo"
                  onChange={onSelectDiscount}
                  checked={applyDiscountHandler(initialValue, reducerData, isChecked)?.select}
                />
                Selected</label>
            </div>

          </div>
          :
          <div className='flex items-baseline'>
            <div className='pt-2 text-lg font-medium leading-5'>order line item(s)</div>
            <div
              // onClick={() => setIncluded(true)}
              className='px-2 py-1 pt-2 mr-2 text-lg font-medium rounded-md cursor-pointer hover:bg-blue-200 h-11'>
              <span className='pr-2'><PlusOutlined /></span>
              add products</div>
            <div className='flex mb-4 space-x-2'>
              <div className='pt-4 text-lg font-medium leading-5'>Exclude</div>
              <div
                // onClick={() => setExcluded(true)}
                className='px-2 py-1 pt-2 text-lg font-normal rounded-md cursor-pointer hover:bg-blue-200 h-11'>
                <span className='pr-2'><PlusOutlined /></span>
                add products</div>
            </div>
          </div>
        }
      </div>
    </div>
    <Modal
      centered
      // visible={discountVisible === true ? discountVisible : excludVisible === true ? excludVisible : ''}
      visible={included || exclued}
      onOk={() => {
        if (included) {
          setIncluded(false)
        }
        if (exclued) {
          setExcluded(false)
        }
        // setDiscountVisible(false);
        // setExcludVisible(false);
        selectedItemHandler()
      }}
      onCancel={() => {
        // setDiscountVisible(false);
        // setExcludVisible(false)
        setIncluded(false)
        setExcluded(false)
      }}
      width={1000}
    >
      <div>
        <div className="py-4 border-b-2 border-blue-200">
          <div>{discountVisible === true ? "Discounted Item" : "Excluded Item"}</div>
        </div>
        <div>
          <DiscountToItem
            itemKey={key}
            setSelectedListItems={setSelectedListItems}
            selectedListItems={selectedListItems}
            visible={discountVisible}
            excludVisible={excludVisible}
            show={included ? included : exclued}
            setShow={included ? setIncluded : setExcluded}
            included={included}
            exclued={exclued}
            selectedOptions={included ? selectedIncludedOptions : selectedExcludedOptions}
            setSelectedOptions={included ? setSelectedIncludedOptions : setSelectedExcludedOptions}
          />
        </div>
      </div>
    </Modal>
    <div className='py-2'>
      <Info>
        Added products will be discounted only if all validation rules are met
      </Info>
    </div>
    <div>
      <Info>
        The excluded products rule has priority over included products rule. If the excluded collection contains included items, then a discount will not be granted to those items.
      </Info>
    </div>
  </div>;
};

export default DiscountToFilter;
