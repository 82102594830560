import { notification } from 'antd';

export function Notification(data, description) {
  const openNotificationWithIcon = type => {
    notification[type]({
      // message: message,
      description: description,
    });
  };
  return openNotificationWithIcon(data)
};

export default Notification;
