import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../redux/rootReducer';
import thunk from "redux-thunk";
import voucherReducer from "./voucherReducer";

const reducer = combineReducers({
  rootReducer: rootReducer,
  vouchers: voucherReducer
})
// applyMiddleware supercharges createStore with middleware:
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

export default store;