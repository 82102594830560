import request from "../../../api/request";
import Notification from "../../Common/costant/Notification";

/*================= get all voucher ==============*/

export const loadinHandler = (data) => {
  return {
    type: "LOADING",
    payload: data
  }

}

export const getAllVoucherAction = (token) => (dispatch) => {
  dispatch(loadinHandler(true))
  const data = request(`/getVouchers`, {
    method: 'get',
    data: '',
    token: token,
  }).then(res => {
    dispatch(
      {
        type: "VOUCHERS",
        payload: res?.data,
      });
    dispatch(loadinHandler(false));
  }).catch((error) => {
    dispatch(loadinHandler(false));
    return data;
  })
}
/*======================== delete voucher ===========*/

export const deleteVoucher = (id, token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const resp = await request('/deleteVoucher', {
      method: 'delete',
      data: { id: id },
      token: token
    })
    if (resp.status === 200) {
      dispatch(getAllVoucherAction(token))
      resolve(resp)
    } else {
      reject(resp)
    }
  })
}
/*============= voucher detail ================*/

export const getVoucherDetailAction = (id, token) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request('/getOneVoucher', {
        method: 'post',
        data: { id: id },
        token: token
      })
      resolve(res)
      return dispatch({
        type: 'GET_VOUCHER_DETAIL',
        payload: res?.data,
      }),
        dispatch(loadinHandler(false));
    } catch (error) {
      reject(error)
      dispatch(loadinHandler(false));
      console.log('something went wrong..')
    }
  })
}

// export const getVoucherDetailAction = (id, token) => (dispatch) => {
//   dispatch(loadinHandler(true))
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await request('/getOneVoucher', {
//         method: 'post',
//         data: { id: id },
//         token: token
//       }).then(res => {
//         if (res.status === 200) {
//           resolve(res)
//           return dispatch({
//             type: 'GET_VOUCHER_DETAIL',
//             payload: res?.data,
//           }),
//           dispatch(loadinHandler(false));
//         } else {
//           console.log(res,"llllllllll")
//           reject(res)
//           console.log('catch an error');
//           dispatch(loadinHandler(false));
//         }
//       })
//     } catch (error) {
//         console.log('something went wrong..')
//     }
//   })
// }
/*================ get all product ==============*/

export const getProductAction = (token) => (dispatch) => {
  dispatch(loadinHandler(true));
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request('/getOrdersHistory', {
        method: 'get',
        data: '',
        token: token
      }).then(res => {
        resolve(res)
        if (res) {
          return dispatch({
            type: 'GET_PRODUCT_HISTORY',
            payload: res?.data,
          }),
            dispatch(loadinHandler(false));
        } else {
          console.log('catch an error');
        }
      })
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
        return dispatch(loadinHandler(false));
      })
    }
  })
}

export const getProductDetailAction = (id, token) => (dispatch) => {
  dispatch(loadinHandler(true));
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request('/getOneOrderDetails', {
        method: 'post',
        data: { 'orderId': id },
        token: token
      }).then(res => {
        if (res.status === 200) {
          resolve(res)
          return dispatch({
            type: 'GET_Product_DETAIL',
            payload: res?.data,
          }),
            dispatch(loadinHandler(false));
        } else {
          console.log('catch an error');
        }
      })
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
        return dispatch(loadinHandler(false));
      })
    }
  })
}

// export const sendEmail = (values) => (dispatch) => {
//   return request('/sendEmail', {
//     method: 'post',
//     data: {
//       email: values?.programSize?.email,
//       discount: values?.programSize?.discount,
//       voucher: values?.programSize.voucher
//     },
//   });
// }

/*================ user secret & access key ===============*/

export const createSecretAccessKey = (token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      return request('/keysCreate', {
        method: 'post',
        // data: data,
        token: token,
      }).then((res) => {
        if (res) {
          sessionStorage.setItem('secretKey', res?.data?.secretKey)
          sessionStorage.setItem('accessKey', res?.data?.accessKey)
          dispatch({
            type: "CREATE_USER_KEYS",
            payload: res.data,
          });
          resolve(res)
        } else {
          return undefined
        }
        return res
      });
    }
    catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }

  })
}

export const getSecretAccessKey = (token) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      return request('/getUserKeys', {
        method: 'get',
        // data: data,
        token: token,
      }).then((res) => {
        if (res) {
          sessionStorage.setItem('secretKey', res?.data?.secretKey)
          sessionStorage.setItem('accessKey', res?.data?.accessKey)
          dispatch({
            type: "GET_USER_KEYS",
            payload: res.data,
          });
          resolve(res)
        } else {
          return undefined
        }
        return res
      });
    } catch (error) {
      reject(error => {
        console.log('something went wrong..')
      })
    }

  })
}
// try {
//   const response = await dispatch(userLoginAction(values))
//   console.log(response, "response");
// } catch (error) {
//   console.log(error, "error in index");

// // }
// const response = await dispatch(userLoginAction(values)).then(res => {

// }).catch(error => {
//   console.log(error, "error in index")
// })


/**=============== distribution API =============== */

export const addUserEmailAction = (token, value) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const resp = await request('/customerDetails', {
      method: 'post',
      data: { "csvData": value },
      token: token,
    })
    if (resp.status === 200) {
      dispatch(getAllUserEmailAction(token))
      resolve(resp)
      Notification('success', resp.message)
    } else {
      Notification('error', resp.message)
      reject(resp)
    }
  })
};

export const getAllUserEmailAction = (token, value) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    const resp = await request('/campaign', {
      method: 'get',
      data: '',
      token: token,
    })
    if (resp.status === 200) {
      resolve(resp)
      dispatch({
        type: 'ALL_EMAIL',
        payload: resp?.data,
      });
      dispatch(loadinHandler(false))
    } else {
      reject(resp)
      dispatch(loadinHandler(false))
      console.log("something went wrong")
    }
  })
};

export const deleteUserEmailAction = (token, id) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    const resp = await request(`/campaign/${id}`, {
      method: 'delete',
      // data: '',
      token: token,
    })
    if (resp.status === 200) {
      resolve(resp)
      dispatch(loadinHandler(false))
    } else {
      reject(resp)
      dispatch(loadinHandler(false))
      console.log("something went wrong")
    }
  })
};


/**=============== dashboard chart api collection ================ */

export const pieChartAction = (token) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    const resp = await request('/pieChartCount', {
      method: 'get',
      data: '',
      token: token,
    })
    if (resp.status === 200) {
      resolve(resp)
      dispatch({
        type: "PIE_CHART_DATA",
        payload: resp.data,
      });
      dispatch(loadinHandler(false))
    } else {
      reject(resp)
      dispatch(loadinHandler(false))
      console.log("something went wrong")
    }
  })
};

export const lineChartAction = (token, data) => (dispatch) => {
  dispatch(loadinHandler(true))
  return new Promise(async (resolve, reject) => {
    const resp = await request('/lineChartCount', {
      method: 'post',
      data: data,
      token: token,
    })
    if (resp.status === 200) {
      resolve(resp)
      dispatch({
        type: 'LINE_CHART_DATA',
        payload: resp?.data,
      });
      dispatch(loadinHandler(false))
    } else {
      reject(resp)
      dispatch(loadinHandler(false))
      console.log("something went wrong")
    }
  })
};