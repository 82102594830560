import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CheckUserAuth = ({ children }) => {
  const navigate = useNavigate();
  let token = sessionStorage.getItem("userToken")

  const handleLogoutTimer = () => {
    setTimeout(() => {
      logoutAction();
    }, 3500000);
  };

  useEffect(() => {
    if (token !== null) {
      handleLogoutTimer();
    }
  }, [token]);

  const logoutAction = () => {
    sessionStorage.clear();
    navigate('/')
  };
  return children;
};

export default CheckUserAuth;



/*============  automatic logout user if for givr time user don't action any event(as given events array bellow) ====*/

// const events = [
//   "load",
//   "mousemove",
//   "mousedown",
//   "click",
//   "scroll",
//   "keypress",
// ];

// const CheckUserAuth = ({ children }) => {
//   const navigate = useNavigate();
//   let timer;

//   const handleLogoutTimer = () => {
//     timer = setTimeout(() => {
//       resetTimer();
//       Object.values(events).forEach((item) => {
//         window.removeEventListener(item, resetTimer);
//       });
//       // logs out user
//       logoutAction();
//     }, 10000);
//   };

//   // this resets the timer if it exists.
//   const resetTimer = () => {
//     if (timer) clearTimeout(timer);
//   };

//   useEffect(() => {
//     Object.values(events).forEach((item) => {
//       window.addEventListener(item, () => {
//         resetTimer();
//         handleLogoutTimer();
//       });
//     });
//   }, []);

//   const logoutAction = () => {
//     sessionStorage.clear();
//     navigate('/')
//   };

//   return children;
// };

// export default CheckUserAuth;