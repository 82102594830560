import React from "react";
import { Breadcrumb, PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useNavigate } from "react-router-dom";

const PageHead = (props) => {
  let { title, subTitle, breadcrumbs, children, bgNone, button } = props;
  const setBreadcrumbPath = () => (
    <Breadcrumb>
      {breadcrumbs.map(item => (
        <Breadcrumb.Item key={item.path}><Link to={item.path}>{item.breadcrumbName}</Link></Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={title}
        subTitle={subTitle}
        // breadcrumb={{ routes: breadcrumbs, itemRender: Demo() }}
        breadcrumbRender={setBreadcrumbPath}
        extra={button && button}
      >
        <Content className={bgNone ? `p-4` : `bg-white p-4`}>{children}</Content>
      </PageHeader>
    </div>
  );
};

export default PageHead;

// import React from "react";
// import { PageHeader } from "antd";
// import { Content } from "antd/lib/layout/layout";

// const PageHead = (props) => {
//   let { title, subTitle, breadcrumbs, children, bgNone, button } = props;
//   const routes = breadcrumbs.map((route) => route);

//   return (
//     <div>
//       <PageHeader
//         className="site-page-header"
//         onBack={() => window.history.back()}
//         title={title}
//         subTitle={subTitle}
//         breadcrumb={{ routes }}
//         extra={button && button}
//       >
//         <Content className={bgNone ? `p-4` : `bg-white p-4`}>{children}</Content>
//       </PageHeader>
//     </div>
//   );
// };

// export default PageHead;