import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { addUserEmailAction } from '../../Vouchers/services/users';
import DistributionData from '../DistributionData';
import './SCVReader.scss'

export default function CsvReader() {
  const token = sessionStorage.getItem("userToken");
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);

  let dispatch = useDispatch();

  const processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})

      return eachObject;
    })

    setCsvArray(newArray)
    dispatch(addUserEmailAction(token, newArray))
  }

  const CSVHandle = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text)
    }

    reader.readAsText(file);
  }

  return (
    <div>
      <form className='flex gap-8'>
        <input
          type='file'
          accept='.csv'
          className='input-scv'
          onChange={(e) => {
            setCsvFile(e.target.files[0])
          }}
        />
        <button className='upload-btn'
          onClick={(e) => {
            e.preventDefault()
            if (csvFile) CSVHandle()
          }}
        >
          Upload
        </button>
      </form>
      <div>
        <DistributionData csvArray={csvArray} />
      </div>
    </div>
  );

}