// import { Button } from 'antd'
import React from 'react';
import AppContainer from '../AppContainer';
import PageHead from '../Common/PageHead/Index';
import CsvReader from './csv/CSVReader';
import DistributionData from './DistributionData';

const Distributions = () => {
  return (
    <AppContainer>
      <PageHead
        title="Distributions"
        breadcrumbs={[
          { path: '/', breadcrumbName: 'Home' },
          {
            path: '/',
            breadcrumbName: 'Distributions',
          },
        ]}
        bgNone={true}
        subTitle=""
      >
        <div className="">
          <CsvReader />
        </div>
      </PageHead>
    </AppContainer>
  );
};

export default Distributions;
