import React from 'react';
import { Button, Col, Progress, Result, Row, Spin, Tabs } from 'antd';
import { CaretRightOutlined, LineOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import VoucherBarCode from '../vocherCode/VoucherBarCode';
import VoucherQrCode from '../vocherCode/VoucherQrCode';
import { useNavigate } from 'react-router-dom';
import './Index.scss';
import CustomLoader from '../../../loader/CustomLoader';

function Index({ resp }) {
  let { voucherDetail, loading } = useSelector((item) => item?.rootReducer)
  let navigate = useNavigate();

  const onChange = () => {
  }
  const capitalizeKeyHandle = (key) => {
    const result = key[0].toUpperCase() + key.substring(1);
    const finalResult = result.replace(/[A-Z]/g, ' $&').trim()
    return finalResult;
  }

  const renderFields = (key) => {
    let voucherFields = ["", "couponName", "couponCode", "maxDiscountAmount", "percentageDiscount", "refereeAmount", "referrerAmount", "discountType", "campaignType", "applyDiscountOn"];
    if (voucherFields.includes(key)) {
      return (
        <div className='detail_body_wrapper' key={key}>
          <div className='details_body'>
            <div className=''>{capitalizeKeyHandle(key)}</div>
            <div className=''>{voucherDetail ? voucherDetail[key] : ''}</div>
          </div>
          <hr className='horizontal' />
        </div>
      )
    }
    return null
  }
  const status = resp["status"] ? resp["status"] : "500"
  return (
    <div>
      {loading
        ?
        <div>
          <div className="example">
            <CustomLoader />
          </div>
        </div>
        :
        voucherDetail && Object.keys(voucherDetail)?.length > 0 && loading === false
          ?
          <div>
            <section className='validation_wrapper'>
              <div className='validation'>Valid from <span>{voucherDetail.activeDate}</span> to <span>{voucherDetail.expiryDate}</span></div>
            </section>
            <Row className='wrapper'>
              <Col xl={12} lg={24} md={24} sm={24} className='sub_wrapper'>
                <Col className='wrapper_body'>
                  <p className='list_heading'>Voucher Details</p>
                  <div className='details'>
                    {voucherDetail && Object.keys(voucherDetail).map((key) => renderFields(key))}
                  </div>
                </Col>
                <Col className='wrapper_body timeFrameSection'>
                  <p className='list_heading'>Code time frame</p>
                  <div className='details'>
                    <div className='timeFrame '>
                      <div>Time frame</div>
                      <div className='flex items-center'>
                        <span>{voucherDetail && voucherDetail.activeDate} </span>
                        <span className='mr-[3px] mb-2 ml-[6px]'>{<LineOutlined />}{<CaretRightOutlined className='ml-[-7px]' />}</span>
                        <span>{voucherDetail && voucherDetail.expiryDate}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col xl={12} lg={24} md={24} sm={24} className='sub_wrapper'>
                <Col className='wrapper_body'>
                  <div className='tabs_section'>
                    <Tabs
                      defaultActiveKey="barcode"
                      onChange={onChange}
                      items={[
                        {
                          label: `BAR CODE`,
                          key: 'barcode',
                          children: <VoucherBarCode />,
                        },
                        {
                          label: `QR CODE`,
                          key: 'qrcode',
                          children: <VoucherQrCode />,
                        },
                      ]}
                    />
                  </div>
                  <div className='details'>
                    <div></div>
                  </div>
                </Col>
                <Col className='wrapper_body'>
                  <p className='list_heading'>Redemptions</p>
                  <div className='details'>
                    <div className=''>
                      <div className=''>
                        <div className='redeem_section'>
                          <div>Total Redeem Count :</div>
                          <div className='flex-1'>{voucherDetail && voucherDetail.totalRedeemCount}</div>
                        </div>
                        <div className='redeem_section'>
                          <div>Max Redeem Count :</div>
                          <div className='flex-1'>{voucherDetail && voucherDetail.maxRedeemCount}</div>
                        </div>
                      </div>
                      <div className='mt-4 w-2/3'>
                        <Progress percent={100 * voucherDetail.totalRedeemCount / voucherDetail.maxRedeemCount} size="large" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Col>
            </Row>
          </div>
          :
          <div>
            <div className="example">
              <Result
                status='404'
                title={`status - ${status}`}
                subTitle={resp["message"] ? resp["message"] : "Its not you Its us "}
                extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
              />
            </div>
          </div>
      }
    </div>
  )
}

export default Index;