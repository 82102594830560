import { Button, Col, Row, Tabs } from "antd";
import React from "react";
import Info from "../../../../Common/Info/Info";
const { TabPane } = Tabs;

const AttributeMatch = ({ setFormValues, reducerData, initialValue }) => {

  const setProperty = (e) => {
    setFormValues('selectedProperty', e.target.value)
  }
  return (
    <div className="mb-4 space-x-4">
      <Tabs defaultActiveKey="1" centered={false}>
        <TabPane
          tab={<span className="text-lg font-medium">Order Metadata</span>}
          key="1"
        >
          <Info>
            Extend Voucherify with custom fields like payment_mean,
            booking_start_date, booking_end_date, location_id etc., so that you
            can fully customize your campaigns
          </Info>
          <Row gutter={6}>
            <Col lg={7}>
              <span className="w-full -mb-4">Select property</span>
              <select
                name="selectedProperty"
                style={{ width: "100%" }}
                defaultValue={initialValue ? initialValue?.selectedProperty : reducerData?.selectedProperty}
                onChange={(e) => setProperty(e)}
              >
                <option>select property... </option>
                <option value="property1">Property 1</option>
                <option value="property2">Property 2</option>
              </select>
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Condition</span>
              <input
                name="selectedCondition"
                type="text"
                style={{ width: "100%" }}
                placeholder="Condition"
                defaultValue={initialValue ? initialValue?.selectedCondition : reducerData?.selectedCondition}
                onChange={e => setFormValues('selectedCondition', e.target.value)}
              />
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Value</span>
              <input
                name="selectedValue"
                type="number"
                min={0}
                placeholder="Value"
                style={{ width: "100%" }}
                defaultValue={initialValue ? initialValue?.selectedValue : reducerData?.selectedValue}
                onChange={e => setFormValues('selectedValue', e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <div className="mt-6">
                <Button type="primary" style={{ background: '#1890ff' }} size="large">
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={<span className="text-lg font-medium">Redemption Metadata</span>}
          key="2"
        >
          <Info>
            Extend Voucherify with custom fields like location_id etc. so that
            you can fully customize your campaigns
          </Info>
          <Row gutter={6}>
            <Col lg={7}>
              <span className="w-full -mb-4">Select property</span>
              <select
                name="redemptionProperty"
                style={{ width: "100%" }}
                defaultValue={initialValue ? initialValue?.redemptionProperty : reducerData?.redemptionProperty}
                onChange={(e) => setFormValues('redemptionProperty', e.target.value)}
              >
                <option >select redemption property... </option>
                <option value="property1">Property 1</option>
                <option value="property2">Property 2</option>
              </select>
            </Col>
            <Col lg={7}>
              {/* {getInitial('lll')} */}
              <span className="w-full -mb-4">Condition</span>
              <input
                name="redemptionCondition"
                type="text"
                style={{ width: "100%" }}
                placeholder="Condition"
                onChange={e => setFormValues('redemptionCondition', e.target.value)}
                defaultValue={initialValue ? initialValue?.redemptionCondition : reducerData?.redemptionCondition}
              />
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Value</span>
              <input
                name="redemptionValue"
                type="number"
                min={0}
                onChange={e => setFormValues('redemptionValue', e.target.value)}
                defaultValue={initialValue ? initialValue?.redemptionValue : reducerData?.redemptionValue}
                style={{ width: "100%" }}
                placeholder="Value"
              />
            </Col>
            <Col lg={3}>
              <div className="mt-6">
                <Button type="primary" style={{ background: '#1890ff' }} size="large">
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AttributeMatch;
