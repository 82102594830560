/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { discountTypeHandler } from "../../../helper/helper";
import FixedAmountType from "./DiscountValue/FixedAmountType/FixedAmountType";
import PercentageType from "./DiscountValue/PercentageType/PercentageType";
import ReferalCards from "./DiscountValue/ReferrelType/ReferalCards";
import FreeShipping from "./DiscountValue/FreeShipping/FreeShipping";
import DiscountCard from "../../Common/DiscountCard/DiscoutCard";
import AmountType from "./DiscountValue/AmountType/AmountType";
import UnitType from "./DiscountValue/UnitType/UnitType";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CampainThirdStep = props => {
  const { setFormValues, formData, setFromData, Error, setError } = props;
  const initialValue = useSelector(state => state?.rootReducer?.voucherDetail);

  const location = useLocation();
  // let campaignName = location.state.campaignName;
  let updateVoucher = location.state.type;
  let campaignName;
  if ("campaignType" in initialValue) {
    campaignName = initialValue["campaignType"];
  } else {
    campaignName = location.state.campaignName;
  }

  const [selectedDiv, setSelectedDiv] = useState(discountTypeHandler(initialValue, campaignName, updateVoucher));
  const [selectedDiscount, setSelectedDiscount] = useState("amountType");
  const [type, setSelectedType] = useState([]);
  const [isEdit, setIsEdit] = useState(campaignName ? campaignName : initialValue.campaignType);

  useEffect(() => {
    setFormValues("discountType", selectedDiscount);
  }, [selectedDiv, selectedDiscount]);

  useEffect(() => {
    setIsEdit(campaignName ? campaignName : initialValue.campaignType);
  }, [campaignName, initialValue]);

  const discountType = [
    {
      imageSrc: "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
      discountType: "Amount type",
      discount: "amountType",
      subTitle: "(e.g. $10.00 off)",
      isDiscountType: true,
      isDiscount: true,
      isRefralType: false,
      isPromotion: true,
      isLoyality: true,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://img.icons8.com/cotton/64/000000/percentage--v1.png",
      discountType: "Percentage type",
      discount: "percentType",
      subTitle: "(e.g. 10% off)",
      isDiscountType: false,
      isRefralType: false,
      isDiscount: true,
      isPromotion: true,
      isLoyality: true,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/60/000000/external-dollar-currency-vitaliy-gorbachev-lineal-vitaly-gorbachev-2.png",
      discountType: "Fixed amount",
      discount: "fixedAmount",
      subTitle: "(e.g. cart total is $20.00)",
      isDiscountType: false,
      isRefralType: false,
      isPromotion: true,
      isDiscount: false,
      isLoyality: true,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://img.icons8.com/external-wanicon-lineal-wanicon/64/000000/external-glucose-meter-health-checkup-wanicon-lineal-wanicon.png",
      discountType: "Unit type",
      discount: "unitType",
      subTitle: "(e.g. time, square meters)",
      isDiscountType: false,
      isRefralType: false,
      isDiscount: false,
      isPromotion: true,
      isLoyality: true,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://img.icons8.com/dotty/80/000000/one-free.png",
      discountType: "Free shipping",
      discount: "freeShipping",
      subTitle: "",
      isDiscountType: false,
      isRefralType: false,
      isDiscount: false,
      isPromotion: true,
      isLoyality: true,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://cdn-icons-png.flaticon.com/512/3059/3059416.png",
      discountType: "Referrer and referee",
      discount: "Referrer and referee",
      subTitle: "",
      isDiscountType: false,
      isRefralType: true,
      isDiscount: false,
      isPromotion: false,
      isLoyality: false,
      discountTypeInReferrel: false,
    },
    {
      imageSrc: "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
      discountType: "Discount Type",
      discount: "discountType",
      subTitle: "",
      isDiscountType: false,
      isRefralType: false,
      isDiscount: false,
      isPromotion: false,
      isLoyality: false,
      discountTypeInReferrel: true,
    },
    {
      imageSrc: "https://img.icons8.com/dotty/80/000000/one-free.png",
      discountType: "Credits on gift card",
      discount: "creditsOnGiftCard",
      subTitle: "",
      isDiscountType: false,
      isRefralType: false,
      isPromotion: false,
      isDiscount: false,
      isLoyality: false,
      discountTypeInReferrel: true,
    },
    {
      imageSrc: "https://cdn-icons-png.flaticon.com/512/3059/3059416.png",
      discountType: "Points on loyalty card",
      discount: "pointsOnLoyaltyCard",
      subTitle: "",
      isDiscountType: false,
      isRefralType: false,
      isDiscount: false,
      isPromotion: false,
      isLoyality: false,
      discountTypeInReferrel: true,
    },
  ];

  const renderDiscountType = () => {
    switch (selectedDiv) {
      case "Amount type":
        return <AmountType setFormValues={setFormValues} setFromData={setFromData} formData={formData} Error={Error} setError={setError} />;
      case "Percentage type":
        return <PercentageType setFormValues={setFormValues} setFromData={setFromData} formData={formData} Error={Error} setError={setError} />;
      case "Fixed amount":
        return <FixedAmountType setFormValues={setFormValues} setFromData={setFromData} formData={formData} Error={Error} setError={setError} />;
      case "Unit type":
        return <UnitType setFormValues={setFormValues} setFromData={setFromData} formData={formData} />;
      case "Free shipping":
        return <FreeShipping setFormValues={setFormValues} setFromData={setFromData} formData={formData} />;
      case "Referrer and referee":
        return <AmountType setFormValues={setFormValues} setFromData={setFromData} formData={formData} Error={Error} setError={setError} />;
      default:
        return "Amount type";
    }
  };

  useEffect(() => {
    let discountTypeData = [];
    switch (isEdit) {
      case "discountCoupons":
        discountTypeData = discountType.filter(item => item.isDiscount === true);
        setSelectedType(discountTypeData);
        break;
      case "giftVouchers":
        discountTypeData = [];
        setSelectedType(discountTypeData);
        break;
      case "referralCodes":
        discountTypeData = discountType.filter(item => item.isRefralType === true);
        setSelectedType(discountTypeData);
        break;
      case "promotion":
        discountTypeData = discountType.filter(item => item.isPromotion === true);
        setSelectedType(discountTypeData);
        break;
      case "loyalityProgram":
        discountTypeData = discountType.filter(item => item.isLoyality === true);
        setSelectedType(discountTypeData);
        break;
      default:
        break;
    }
  }, [campaignName]);

  return (
    <div className="">
      {isEdit !== "referralCodes" ? (
        <div className={`${isEdit === "giftVouchers" ? "" : "p-4 bg-white"}`}>
          <div className="flex flex-wrap content-evenly">
            {type.map((item, i) => (
              <DiscountCard
                key={i}
                i={i}
                item={item}
                selectedDiv={selectedDiv}
                onClick={() => {
                  setSelectedDiv(item.discountType);
                  setSelectedDiscount(item.discount);
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <ReferalCards data={discountType} renderDiscountType={renderDiscountType} setSelectedDiv={setSelectedDiv} setSelectedDiscount={setSelectedDiscount} />
      )}
      <div className="mt-8">{renderDiscountType()}</div>
    </div>
  );
};

export default CampainThirdStep;
