/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDownOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import DiscountCard from '../../../../Common/DiscountCard/DiscoutCard';
import Info from '../../../../Common/Info/Info'
import AddOrder from '../AddOrder/AddOrder';
import AttributeMatch from '../Attribute/AttributeMatch';
import Budget from '../Budget/Budget';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getAttributeData,
  getCheckedFixedStatus,
  getDiscountValue,
  getFixedAmount,
  getFixedAmountBudgetCampaign,
  getFIxedAmountBudgetDiscount,
  getFixedAmountBudgetOrder,
  getFIxedAmountBudgetPercode,
  getFixedAmountBudgetPerday,
  getFixedAmountBudgetRedemption,
  getFixedAmountCondition,
  getFixedOrderCondition,
  getFixedOrderValue
} from '../../../../../helper/helper';

const FixedAmountType = ({ setFormValues }) => {

  const [selectedDiscountTo, setSelectedDiscountTo] = useState(true)
  const [segmentSelection, setSegmentSelection] = useState('Customer activity')
  const [createValidation, setCreateValidation] = useState(true)
  const [selectedValidation, setSelectedValidation] = useState('Customer activity')

  const initialValue = useSelector(state => state?.rootReducer?.data);
  const reducerData = useSelector((state) => state?.vouchers?.third);

  const changeDiscount = (e) => {
    setSelectedDiscountTo(!selectedDiscountTo)
    if (e.target.value === 'applyToItems') {
      setCreateValidation(false)
    } else {
      setCreateValidation(true)
    }
  }
  const fixedCustomerActivityHandler = (e) => {
    setFormValues('acitivityCondition', e.target.value);
    setSegmentSelection(e.target.value);
  }
  const fixedAmountOrderHandler = (e) => {
    setFormValues('condition', e.target.value)
  }
  const renderInfoText = useCallback(() => {
    switch (selectedDiscountTo) {
      case 'applyToOrders':
        return 'Set fixed total on the whole cart. Discount will be calculated dynamically, based on fixed amount value. For example, if fixed amount is set to equal $10 and order amount equals $25, then the calculated discount will be $15.';
      case 'applyToItems':
        return <>
          <span className='pb-4 pl-1'>This type of deal applies the new price for line items. Default rules:</span>
          <ol style={{ listStyleType: 'disk', paddingLeft: '30px' }} >
            <li>
              During redemption, prices will change only if the new one is lower than the old one.
            </li>
            <li>
              If a product price in the collection and the new product price you have set up differs, then the new product price will be passed during the redemption.
            </li>
            <li>
              When a product is in two collections, we always change the price for the lowest.
            </li>
            <li>
              The new price for products with several SKUs will force the price change for SKUs if their price is higher than the new price.;
            </li>
          </ol>
        </>
      default:
        return 'whole Cart – Discount will be applied to the total order amount.'
    }
  }, [selectedDiscountTo])

  const validationType = [
    {
      imageSrc: 'https://img.icons8.com/ios-filled/50/000000/user.png',
      discountType: 'Customer activity',
      subTitle: '(e.g. cart abandoned)'
    },
    {
      imageSrc: 'https://img.icons8.com/glyph-neue/64/000000/stack.png',
      discountType: 'Attributes match',
      subTitle: '(e.g. 10% off)'
    },
    {
      imageSrc: 'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png',
      discountType: 'Budget',
      subTitle: '(e.g. total order value)'
    },
    {
      imageSrc: 'https://img.icons8.com/dotty/80/000000/create-order.png',
      discountType: 'Order',
      subTitle: '(e.g. total amount)'
    }
  ];

  const renderValidationType = useCallback(() => {
    switch (selectedValidation) {
      case 'Customer activity':
        return <Row gutter={12}>
          <Col lg={12}>
            <span className='w-full'>Condition</span>
            <select
              name="acitivityCondition"
              className='w-full border-blue-200'
              defaultValue={getFixedAmountCondition(initialValue, reducerData)}
              onChange={fixedCustomerActivityHandler}
            >
              <option>Select Customer Segment.... </option>
              <option value="true">Customer is in segment</option>
              <option value="false">Customer is not in segment </option>
            </select>
          </Col>
          <Col lg={12}>
            <div className='mt-5'>
              <Form.Item
                name=""
              >
                <Button type='primary' size='large'>Add</Button>
              </Form.Item>
            </div>
          </Col>
        </Row>;
      case 'Attributes match':
        return <AttributeMatch setFormValues={setFormValues} reducerData={reducerData} initialValue={getAttributeData(initialValue, reducerData)?.data} />;
      case 'Budget':
        return (
          <>
            <Budget
              initialValue={getFixedAmountBudgetOrder(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total orders value"
              fieldName={"totalOrderValue"}
              show={false}
            />
            <Budget
              initialValue={getFIxedAmountBudgetDiscount(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total discounted amount"
              fieldName={"totalDiscountAmount"}
              show={false}
            />
            <Budget
              initialValue={getFixedAmountBudgetRedemption(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total number of redemptions"
              fieldName={"totalNumberOfRedemption"}
              show={false}
            />
            <div className="mt-6">
              <div className="pb-4 text-lg font-medium">
                Redemptions per customer
              </div>
              <div className="border-l-2 border-blue-400 border-dashed">
                <div className="pl-4">
                  <Budget
                    initialValue={getFIxedAmountBudgetPercode(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code"
                    fieldName={"perCode"}
                    show={false}
                  />
                  <Budget
                    initialValue={getFixedAmountBudgetPerday(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code per day"
                    fieldName={"perCodePerDay"}
                    show={false}
                  />
                  <Budget
                    initialValue={getFixedAmountBudgetCampaign(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="In campaign"
                    fieldName={"inCampaign"}
                    show={false}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case 'Order':
        return (
          <>
            <div className="">
              <Row gutter={6}>
                <Col lg={8}>
                  <div className="pt-2 text-lg font-semibold">Total amount</div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <select
                      name="condition"
                      className="w-full border-blue-200"
                      placeholder="Condition"
                      defaultValue={getFixedOrderCondition(initialValue, reducerData)}
                      onChange={fixedAmountOrderHandler}
                    >
                      <option>select condition... </option>
                      <option value="isLessThan">is less than</option>
                      <option value="IsExactly">is exactly</option>
                      <option value="isMoreThan">is more than</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <input
                      name="value"
                      type="number"
                      min={0}
                      placeholder="Value (USD)"
                      onChange={e => setFormValues('value', e.target.value)}
                      defaultValue={getFixedOrderValue(initialValue, reducerData)}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="pl-2">
                    <Button type="primary" style={{ background: '#1890ff' }} size="large">
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <AddOrder orderType="Any" title="Any order item" setFormValues={setFormValues} reducerData={reducerData} initialValue={initialValue?.fixedAmount?.applyEffect?.toItems?.order?.anyOrderItem} />
                <AddOrder orderType="Every" title="Every order item" setFormValues={setFormValues} reducerData={reducerData} initialValue={initialValue?.fixedAmount?.applyEffect?.toItems?.order?.everyOrderItem} />
              </div>
            </div>
          </>
        );
      default:
        return 'whole Cart – Discount will be applied to the total order amount.'
    }
  }, [selectedValidation]);

  useEffect(() => {
    getCheckedFixedStatus()
  }, [selectedDiscountTo]);

  useEffect((e) => {
    getCheckedFixedStatus() === undefined ? setFormValues('applyDiscountOn', "toOrders") : setFormValues('applyDiscountOn', e?.target?.value);
  }, [])

  return (
    <>
      <div className="p-4 bg-white">
        <Row gutter={12}>
          {/* <Col lg={24}>
            <span className="w-full -mb-4">Fixed amount (USD)</span>
            <input
              name="fixedAmount"
              type="number"
              min={0}
              className="w-full"
              placeholder="Fixed amount (USD)"
              defaultValue={getFixedAmount(initialValue, reducerData)}
              onChange={e => setFormValues('fixedAmount', e.target.value)}
            />
          </Col> */}
          <Col lg={12}>
            <span className="w-full -mb-4">Fixed Amount (USD)</span>
            <input
              name="maxDiscountAmount"
              type="number"
              min={0}
              className="w-full"
              defaultValue={getDiscountValue(initialValue, reducerData)}
              placeholder="Fixed Discount Value (USD)"
              onChange={(e) => setFormValues("maxDiscountAmount", e.target.value)}
            />
          </Col>
          {/* <Col lg={12}>
            <span className="w-full -mb-4">Percentage Discount</span>
            <input
              name="percentageDiscount"
              type="number"
              min={0}
              className="w-full"
              defaultValue={getDiscountValue(initialValue, reducerData)}
              placeholder="Discount Value (USD)"
              onChange={(e) => setFormValues("percentageDiscount", e.target.value)}
            />
          </Col> */}

          <Col lg={24}>
            <div className="w-full mb-2 text-lg mt-10">Apply effect</div>
            <div className="flex mb-2 space-x-2">
              <div className='w-56 p-4 bg-white border shadow-md h-14'>
                <label>Apply to orders</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"toOrders"}
                  name="applyDiscountOn"
                  checked={getCheckedFixedStatus(initialValue, reducerData, selectedDiscountTo)?.order}
                  onChange={(e) => { changeDiscount(e); setFormValues('applyDiscountOn', e.target.value) }}
                  defaultChecked
                />
              </div>
              <div className='w-56 p-4 bg-white border shadow-md h-14'>
                <label>Apply to items</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"toItems"}
                  name="applyDiscountOn"
                  checked={getCheckedFixedStatus(initialValue, reducerData, selectedDiscountTo)?.items}
                  onChange={(e) => { changeDiscount(e); setFormValues('applyDiscountOn', e.target.value) }}
                />
              </div>
            </div>
          </Col>
          <Col lg={24}>
            {selectedDiscountTo && (
              <Info>
                <span>Apply to </span> {renderInfoText()}
                {selectedDiscountTo !== "applyToOrders" && (
                  <div className="flex">
                    <div className="mt-1 mr-2">
                      <ArrowDownOutlined />
                    </div>
                    <div className="mt-2 font-bold">
                      Choose discounted products
                    </div>
                  </div>
                )}
              </Info>
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-8">
        {/* {selectedDiscountTo === "applyToOrders" && (
          <div className="px-4 py-8 mt-4 bg-white">
            <Info>
              Validation rules help you define promotions’ eligibility
              conditions based on audience, order structure, order volume,
              budget constraints,and others - <Link to="#">read more</Link>
            </Info>
          </div>
        )} */}
        {/* {createValidation ? (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div>
              <Button
                type=""
                onClick={() => setCreateValidation(false)}
                size="large"
              >
                Create validation rule
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                Select existing validation rule
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex py-8 mt-4">
            <div>
              <Button type="" size="large">
                <EditOutlined /> Use advance builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button
                type=""
                onClick={() => setCreateValidation(true)}
                size="large"
              >
                Quit builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                <ExclamationCircleOutlined /> Get help
              </Button>
            </div>
          </div>
        )} */}

        {/* {!createValidation && <DiscountToFilter setFormValues={setFormValues} />} */}

        {/* {!createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div className="flex flex-wrap">
              {validationType.map((item, i) => (
                <DiscountCard
                  key={i}
                  i={i}
                  item={item}
                  selectedDiv={selectedValidation}
                  onClick={() => setSelectedValidation(item.discountType)}
                />
              ))}
            </div>
          </div>
        )}

        {!createValidation && (
          <div className="px-4 py-8 mt-4 bg-white">
            {renderValidationType()}
          </div>
        )} */}
      </div>
    </>
  );
}

export default FixedAmountType
