import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction, userRegisterAction } from "../../redux/actions";
import Notification from "../Common/costant/Notification";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './index.less'
// import bcrypt from 'bcryptjs'
// const salt = bcrypt.genSaltSync(10)

function LoginRegisterForm({ value }) {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((item) => item.rootReducer);

  const onFinish = async (values) => {
    // const hashedPassword = bcrypt.hashSync(values.password,salt)
    if (value === "login") {
      const response = await dispatch(userLoginAction(values)).then(res => {
        navigate("/")
        Notification('success', res?.message)
      }).catch(error => {
        Notification('error', error?.message)
      })

    } else if (value === "signup") {
      let registered = await dispatch(userRegisterAction(values)).then(res => {
        Notification('success', res?.message)
        navigate("/")
      }).catch(error => {
        Notification('error', error?.message)
      })
    }
  };
  const onFinishFailed = (errorInfo, e) => {
  };
  const setRulesHandle = (value) => {
    if (value === 'signup') {
      return [
        {
          required: true,
          message: "Please input your password!"
        },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
          message: <div className="px-2">
            <p>At least one capital letter</p>
            <p>At least one number </p>
            <p>At least one special character</p>
            <p>At least 8 characters</p>
          </div>,
        }
      ]
    } else if (value === 'login') {
      return [
        {
          required: true,
          message: "Please input your password!"
        },
      ]
    }
  }

  /**======== show loading on login button ======== */
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: '#fff'
      }}
      spin
    />
  );
  return (
    <div className="text-left">
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {value !== "login" && (
          <Form.Item
            label="Full Name"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your full name!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter full name" />
          </Form.Item>
        )}

        <Form.Item
          name={"email"}
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input your email address!"
            },
          ]}
        >
          <Input size="large" placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={setRulesHandle(value)}
        >
          <Input type="password" size="large" placeholder="Enter password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" style={{ background: '#1890ff', width: '11rem' }} size="large" className="loginBtn" htmlType="submit">
            {loading === true ? <Spin indicator={antIcon} /> : value === "login" ? "Login" : "Register"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginRegisterForm;


