
export const getVoucherPayload = (data) => {
  return {
    activeDate: data.activeDate,
    couponName: data.couponName,
    discountType: data.discountType,
    expiryDate: data.expiryDate,
    maxDiscountAmount: data.maxDiscountAmount,
    id: `${data.id}`,
    maxRedeemCount: data.maxRedeemCount,
    percentageDiscount: data.percentageDiscount,
  }
}

export const saveUserToken = (token) => {
  sessionStorage.setItem('userToken', token);

}

export const userLogoutHandle = () => {

}