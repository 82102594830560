import {
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip, Row, Col, Tabs } from "antd";
import React from "react";
import AppContainer from "../../AppContainer";
import PageHead from "../../Common/PageHead/Index";
const { TabPane } = Tabs;

const CampaignWrapper = () => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="" icon={<DownloadOutlined />} size='middle'>
          Download
        </Button>
      </Menu.Item>
      <Menu.Item >
        <Button type="" icon={<CloudUploadOutlined />} size='middle'>
          Import
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="" icon={<DownloadOutlined />} size='middle'>
          Download
        </Button>
      </Menu.Item>
    </Menu>
  );

  const content =
    <Row gutter={6}>
      <Col lg={16}>
        <div className="p-4 bg-white">Main div</div>
      </Col>
      <Col lg={8}>
        <div className="p-4 bg-white">Side div</div>
      </Col>
    </Row>

  return (
    <AppContainer>
      <PageHead
        title="Gift 123"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          {
            path: "/", breadcrumbName: "Create Campaign",
          },
        ]}
        bgNone={true}
        subTitle="Created at: 01/28/2022 07:18:53"
        button={
          <div>
            <span className="text-ellipsis">
              Vouchers generation in progress
            </span>
            <span className="px-2">
              <Tooltip title="Create campaign">
                <Button shape="circle" icon={<PlusOutlined />} />
              </Tooltip>
            </span>
            <span className="">
              <Tooltip title="Delete">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </span>
            <Dropdown overlay={menu} placement="bottomLeft">
              <span className="pl-2">
                <Button shape="circle" icon={<MoreOutlined />} />
              </span>
            </Dropdown>
          </div>
        }
      >
        <div>
          <div>
            <Tabs defaultActiveKey="1" size={'size'} style={{ marginBottom: 32, background: 'white', paddingLeft: '10px' }}>
              <TabPane tab={<span className="text-lg font-medium">Dashboard</span>} key="1">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Vouchers</span>} key="2">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Validation rules</span>} key="3">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Redemption history</span>} key="4">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Publication history</span>} key="5">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Distributions</span>} key="6">
                {content}
              </TabPane>
              <TabPane tab={<span className="text-lg font-medium">Metadata</span>} key="7">
                {content}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </PageHead>
    </AppContainer>
  );
};

export default CampaignWrapper;
