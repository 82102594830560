import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Empty } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteUserEmailAction, getAllUserEmailAction } from '../Vouchers/services/users';
import moment from 'moment';

function DistributionData({ csvArray }) {
  const token = sessionStorage.getItem('userToken');
  const { loading, emailList } = useSelector((user) => user?.rootReducer)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUserEmailAction(token))
  }, [])

  const deleteHandler = (data, id) => {
    dispatch(deleteUserEmailAction(token, data.id)).then(() => {
      dispatch(getAllUserEmailAction(token))
    })
  };

  const columns = [
    {
      title: 'User Id',
      dataIndex: 'userId',
      key: 'userId',
      render: (index, item) => <span key={index}>{<p>{item.userId}</p> || "---"}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (index, item) => <span key={index}>{<p>{item.emailId}</p> || "---"}</span>,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (index, item) => <span key={index}>{<p>{item.id}</p> || "---"}</span>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (index, item) => <span key={index}>{<p>{moment(item?.createdAt).format('DD-MM-YYYY')}</p> || "---"}</span>,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (index, item) => <span key={index}>{<p>{moment(item?.updatedAt).format('DD-MM-YYYY')}</p> || "---"}</span>,
    },
    {
      title: 'Operations',
      key: 'operation',
      render: (text, record) => (
        <span className='operation_section' key={record}>
          <Popconfirm title="Confirm to delete?" onConfirm={deleteHandler.bind(text._id, text)}>
            <Button type="dashed">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className='distribution_wrapper'>
      <div className="mt-4">
        <Table
          loading={loading}
          columns={columns}
          dataSource={emailList || []}
          rowKey={(record) => record._id}
          pagination={true}
          locale={{
            emptyText: (<div style={{ textAlign: 'center' }} key={csvArray.id}>
              <Empty description={false} style={{ fontSize: 20 }} />
              <p>No Data Found!</p>
            </div>)
          }}
        />
      </div>
    </div>
  )
}

export default DistributionData;