import { deleteVoucher } from '../../Vouchers/services/users';
import Notification from '../costant/Notification';

export function EditVoucherHandle(id, navigate) {
  sessionStorage.setItem("editVoucher", id);
  navigate('/campaign/edit/discountCoupon',
    { state: { type: 'editVoucher' } }
  );
}

export const DeleteVoucherHandle = async (id, token, dispatch, navigate) => {
  // console.log(navigate, "navigate")
  if (id && token) {
    let resp = await dispatch(deleteVoucher(id, token))
    if (resp?.status === 200) {
      Notification('success', resp?.message);
      navigate('/vouchers')
    } else {
      Notification('error', resp?.message);
    }
  }
}
