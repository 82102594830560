/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";
// Import pages
import VoucherDetail from "./Components/Vouchers/components/voucherDetail/VoucherDetail";
import CampaignWrapper from "./Components/PostCampaign/CampaignWrapper/CampaignWrapper";
import CampaignCreator from "./Components/Compaign/CampainCreator/CampaignCreator";
import ErrorBoundary from "./Components/Common/ErrorBoundary/ErrorBoundary";
import CreateCampainTypes from "./Components/Compaign/CreateCampainTypes";
import ProductSummary from "./Components/product/detail/ProductSummary";
import CheckUserAuth from "./Components/Common/costant/checkUserAuth";
import Distributions from "./Components/Distributions/Distributions";
import PrivateRoutes from "./Components/PrivateRoutes/PrivateRoutes";
import Vouchers from "./Components/Vouchers/components/Vouchers";
import Redemption from "./Components/Redemptions/Redemptions";
import Customers from "./Components/customers/Customers";
import UserKeys from "./Components/userKeys/UserKeys";
import Products from "./Components/product/Products";
import LoginDashboard from "./pages/LoginDashboard";
import Dashboard from "./pages/Dashboard";
import { useSelector } from "react-redux";
import "./css/style.scss";

function App() {
  const userData = useSelector((state) => state.rootReducer);
  // const [isLogin, setIslogin] = useState(false);
  const location = useLocation();
  const loginData = sessionStorage.getItem("userToken") || false;
  const [hasError, setHasError] = useState(false)

  // useEffect(() => {
  //   setIslogin(userData);
  // }, [userData]);

  useEffect(() => {
    if (hasError) { setHasError(false) }
  }, [location.key])

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location?.pathname]); // triggered on route change

  return (
    <>
      {/* <CheckUserAuth> */}
      {/* <ErrorBoundary hasError={hasError} setHasError={setHasError}> */}
      <Routes>
        <Route
          element={loginData ? <Dashboard /> : <LoginDashboard />}
          path="/"
        />
        <Route
          element={<PrivateRoutes children={<Vouchers />} loggedIn={loginData} />}
          path="/vouchers"
        />
        <Route
          element={<PrivateRoutes children={<VoucherDetail />} loggedIn={loginData} />}
          path="/vouchers/detail/:id"
        />
        <Route
          element={<PrivateRoutes children={<UserKeys />} loggedIn={loginData} />}
          path="/userkey"
        />
        <Route
          element={<PrivateRoutes children={<CreateCampainTypes />} loggedIn={loginData} />}
          path="/campaign"
        />
        <Route
          element={<PrivateRoutes children={<Distributions />} loggedIn={loginData} />}
          path="/distributions"
        />
        <Route
          element={<PrivateRoutes children={<CampaignCreator />} loggedIn={loginData} />}
          path="/campaign/:create/:voucherType"
        />
        <Route
          element={<PrivateRoutes children={<CampaignWrapper />} loggedIn={loginData} />}
          path="/core/campaign/:campaignId/:tabs"
        />
        <Route
          element={<PrivateRoutes children={<Redemption />} loggedIn={loginData} />}
          path="/redemptions"
        />
        <Route
          element={<PrivateRoutes children={<Customers />} loggedIn={loginData} />}
          path="/customers"
        />
        <Route
          element={<PrivateRoutes children={<Products />} loggedIn={loginData} />}
          path="/products"
        />
        <Route
          element={<PrivateRoutes children={<ProductSummary />} loggedIn={loginData} />}
          path="/products/summary/:id"
        />
      </Routes>
      {/* </ErrorBoundary> */}
      {/* </CheckUserAuth> */}
    </>
  );
}

export default App;
