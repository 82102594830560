/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Form, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DiscountCard from "../../../../Common/DiscountCard/DiscoutCard";
import Info from "../../../../Common/Info/Info";
import AddOrder from "../AddOrder/AddOrder";
import AttributeMatch from "../Attribute/AttributeMatch";
import {
  getShippingActivityConditin,
  getShippingAttributeData,
  getShippingBudgetCampaign,
  getShippingBudgetDiscount,
  getShippingBudgetOrder,
  getShippingBudgetPercode,
  getShippingBudgetPerday,
  getShippingBudgetRedemption,
  getShippingOrderCondition,
  getShippingOrderValue,
  getShippingUnitType,
  getShippingUnitValue
} from '../../../../../helper/helper';
import Budget from "../Budget/Budget";

const FreeShipping = ({ setFormValues }) => {

  const [selectedDiscountTo, setSelectedDiscountTo] = useState();
  const [segmentSelection, setSegmentSelection] = useState("Customer activity");
  const [createValidation, setCreateValidation] = useState(true);
  const [selectedValidation, setSelectedValidation] = useState("Customer activity");

  const initialValue = useSelector(state => state?.rootReducer?.data);
  const reducerData = useSelector((state) => state?.vouchers?.third);

  const changeDiscount = (e) => {
    setSelectedDiscountTo(e.target.value)
    setFormValues('shippingDiscountTo', e.target.value)
    if (e.target.value !== 'missingItem') {
      setCreateValidation(false)
    } else {
      setCreateValidation(true)
    }
  }
  const selectUnitHandler = (e) => {
    setFormValues("shippingUnitType", e.target.value)
  }
  const customerSegmentHandler = (e) => {
    setFormValues('acitivityCondition', e.target.value);
    setSegmentSelection(e.target.value);
  }
  const freeShippingAmountHandler = (e) => {
    setFormValues('condition', e.target.value)
  }

  const renderInfoText = "This type of deal adds items to the cart only if a customer has fewer free items than offered by the voucher.";

  const validationType = [
    {
      imageSrc: "https://img.icons8.com/ios-filled/50/000000/user.png",
      discountType: "Customer activity",
      subTitle: "(e.g. cart abandoned)",
    },
    {
      imageSrc: "https://img.icons8.com/glyph-neue/64/000000/stack.png",
      discountType: "Attributes match",
      subTitle: "(e.g. 10% off)",
    },
    {
      imageSrc:
        "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
      discountType: "Budget",
      subTitle: "(e.g. total order value)",
    },
    {
      imageSrc: "https://img.icons8.com/dotty/80/000000/create-order.png",
      discountType: "Order",
      subTitle: "(e.g. total amount)",
    },
  ];

  const renderValidationType = useCallback(() => {
    switch (selectedValidation) {
      case "Customer activity":
        return (
          <Row gutter={12}>
            <Col lg={12}>
              <span className="w-full">Condition</span>
              <select
                name="acitivityCondition"
                className="w-full border-blue-200"
                placeholder="Active only on"
                defaultValue={getShippingActivityConditin(initialValue, reducerData)}
                onChange={customerSegmentHandler}
              >
                <option>Select Customer Segment.... </option>
                <option value="true">Customer is in segment</option>
                <option value="false">Customer is not in segment</option>
              </select>
            </Col>
            <Col lg={12}>
              <div className="mt-5">
                <Form.Item name="">
                  <Button type="primary" style={{ background: '#1890ff' }} size="large">
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        );
      case "Attributes match":
        return <AttributeMatch setFormValues={setFormValues} reducerData={reducerData} initialValue={getShippingAttributeData(initialValue, reducerData)?.data} />;
      case "Budget":
        return (
          <>
            <Budget
              initialValue={getShippingBudgetOrder(initialValue, reducerData)}
              title="Total orders value"
              show={false}
              fieldName={'totalOrderValue'}
              setFormValues={setFormValues}
            />
            <Budget
              initialValue={getShippingBudgetDiscount(initialValue, reducerData)}
              title="Total discounted amount"
              fieldName={"totalDiscountAmount"}
              show={false}
              setFormValues={setFormValues}
            />
            <Budget
              initialValue={getShippingBudgetRedemption(initialValue, reducerData)}
              title="Total number of redemptions"
              fieldName={"totalNumberOfRedemption"}
              show={false}
              setFormValues={setFormValues}
            />
            <div className="mt-6">
              <div className="pb-4 text-lg font-medium">
                Redemptions per customer
              </div>
              <div className="border-l-2 border-blue-400 border-dashed">
                <div className="pl-4">
                  <Budget
                    initialValue={getShippingBudgetPercode(initialValue, reducerData)}
                    title="Per code"
                    fieldName={"perCode"}
                    show={false}
                    setFormValues={setFormValues}
                  />
                  <Budget
                    initialValue={getShippingBudgetPerday(initialValue, reducerData)}
                    title="Per code per day"
                    fieldName={"perCodePerDay"}
                    show={false}
                    setFormValues={setFormValues}
                  />
                  <Budget
                    initialValue={getShippingBudgetCampaign(initialValue, reducerData)}
                    title="In campaign"
                    fieldName={"inCampaign"}
                    setFormValues={setFormValues}
                    show={false}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Order":
        return (
          <>
            <div className="">
              <Row gutter={6}>
                <Col lg={8}>
                  <div className="pt-2 text-lg font-semibold">Total amount</div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <select
                      name="orderCondition"
                      className="w-full border-blue-200"
                      placeholder="Condition"
                      defaultValue={getShippingOrderCondition(initialValue, reducerData)}
                      onChange={freeShippingAmountHandler}
                    >
                      <option>select condition...</option>
                      <option value="isLessThan">is less than</option>
                      <option value="IsExactly">is exactly</option>
                      <option value="isMoreThan">is more than</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <input
                      name="freeShippingValue"
                      type="number"
                      min={0}
                      placeholder="Value (USD)"
                      defaultValue={getShippingOrderValue(initialValue, reducerData)}
                      onChange={e => setFormValues('value', e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="pl-2">
                    <Button type="primary" style={{ background: '#1890ff' }} size="large">
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <AddOrder orderType="Any" title="Any order item" setFormValues={setFormValues} reducerData={reducerData} initialValue={initialValue?.freeShipping?.applyEffect?.addMissingItems?.order?.anyOrderItem} />
                <AddOrder orderType="Every" title="Every order item" setFormValues={setFormValues} reducerData={reducerData} initialValue={initialValue?.freeShipping?.applyEffect?.addMissingItems?.order?.everyOrderItem} />
              </div>
            </div>
          </>
        );
      default:
        return "whole Cart – Discount will be applied to the total order amount.";
    }
  }, [selectedValidation]);

  useEffect(() => {
    // getCheckedStatus()
    setFormValues("shippingDiscountTo", "missingItem")
  }, [])

  return (
    <>
      <div className="p-4 bg-white">
        <Row gutter={12}>
          <Col lg={12}>
            <span className="w-full -mb-4">Unit Value</span>
            <input
              type="number"
              name="shippingUnitValue"
              className="w-full"
              placeholder="Unit Value"
              defaultValue={getShippingUnitValue(initialValue, reducerData)}
              onChange={e => setFormValues('shippingUnitValue', e.target.value)}
            />
          </Col>
          <Col lg={11}>
            <span className="w-full -mb-4">Select unit type</span>
            <select
              name="shippingUnitType"
              className="w-full border-blue-200"
              placeholder="Select unit"
              defaultValue={getShippingUnitType(initialValue, reducerData)}
              onChange={selectUnitHandler}
            >
              <option>select unit type... </option>
              <option value="unit1">Unit 1</option>
              <option value="unit2">
                Unit 2
              </option>
            </select>
          </Col>
          <Col lg={24}>
            <div className="w-full mb-2 text-lg mt-10">Discount effect</div>
            <div className='w-3/12 p-4 bg-white border shadow-md h-14'>
              <label>Add missing items</label>
              <input
                type="radio"
                value={"missingItem"}
                className='float-right'
                name="applyDiscountTo"
                checked={selectedDiscountTo}
                onChange={(e) => changeDiscount(e)}
                defaultChecked
              />
            </div>
          </Col>
          <Col lg={24}>
            {selectedDiscountTo && (
              <Info>
                <span>Apply to </span> {renderInfoText}
              </Info>
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-8">
        {/* {selectedDiscountTo === "missingItem" && (
          <div className="px-4 py-8 mt-4 bg-white">
            <Info>
              Validation rules help you define promotions’ eligibility
              conditions based on audience, order structure, order volume,
              budget constraints,and others - <Link to="#">read more</Link>
            </Info>
          </div>
        )} */}
        {/* {createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div>
              <Button
                type=""
                onClick={() => setCreateValidation(false)}
                size="large"
              >
                Create validation rule
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                Select existing validation rule
              </Button>
            </div>
          </div>
        )} */}

        {/* {!createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div className="flex flex-wrap">
              {validationType.map((item, i) => (
                <DiscountCard
                  i={i}
                  item={item}
                  selectedDiv={selectedValidation}
                  onClick={() => setSelectedValidation(item.discountType)}
                />
              ))}
            </div>
          </div>
        )}

        {!createValidation && (
          <div className="px-4 py-8 mt-4 bg-white">
            {renderValidationType()}
          </div>
        )} */}
      </div>
    </>
  );
};

export default FreeShipping;
