import React, { useState } from 'react';
import { Table, Skeleton, Tag, Popconfirm, Button, Empty, Spin } from 'antd';
import { Link } from 'react-router-dom';

function RedemptionsData() {

  const [loader, setLoading] = useState(false);
  // const [dataSource, setDataSource] = useState();

  const editHandler = () => { };
  const deleteHandler = () => { }

  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },

  ];

  const columns = [
    {
      title: 'Redemption ID',
      dataIndex: 'redemptionId',
      key: 'redemptionId',
      render: (index, item) => <div key={index}><Link to={`/`} key={index}>data</Link>
      </div>,
      responsive: ["xs"]
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      render: (index, item) => <span key={index}>{<p>campaign data2 </p> || "---"}</span>,
      responsive: ["sm"]
    },
    {
      title: 'Redeemables',
      dataIndex: 'redeemables',
      key: 'redeemables',
      render: (index, item) => <span key={index}>{<p>redeemables data3</p> || "---"}</span>,
      responsive: ["sm"]
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (index, item) => <span key={index}>{<p>customer data4</p> || "---"}</span>,
      responsive: ["sm"]
    },
    {
      title: 'Redemption date',
      dataIndex: 'redemption date',
      key: 'redemption date',
      render: (index, item) => <span key={index}>{<p>02-12-2022</p> || "---"}</span>,
    },
    {
      title: 'Operations',
      key: 'operation',
      render: (text, record) => (
        <span className='operation_section'>
          <Button type="dashed" onClick={() => editHandler(record, text)} className="mr-2">Edit</Button>
          <Popconfirm title="Confirm to delete?" onConfirm={deleteHandler.bind(text._id, text)}>
            <Button type="dashed">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className='redemption_wrapper'>
      <div>
        <div>
          <div className="">
            <Table
              loading={loader}
              columns={columns}
              dataSource={dataSource}
              rowKey={(record) => record._id}
              pagination={true}
              locale={{
                emptyText: (<div style={{ textAlign: 'center' }}>
                  <Empty description={false} style={{ fontSize: 20 }} />
                  <p>No Data Found!</p>
                </div>)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RedemptionsData;