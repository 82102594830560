/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Form, Row, } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import DiscountCard from "../../../../Common/DiscountCard/DiscoutCard";
import Info from "../../../../Common/Info/Info";
import AddOrder from "../AddOrder/AddOrder";
import AttributeMatch from "../Attribute/AttributeMatch";
import Budget from "../Budget/Budget";
import DiscountToFilter from "../DiscountToFilter/DiscountToFilter";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  customerActivityHandler,
  getAmountAttributeData,
  getBudgetCampaign,
  getBudgetDiscount,
  getBudgetPercode,
  getBudgetPerday,
  getBudgetRedemption,
  getBudgetTotalOrder,
  getCheckedStatus,
  getDiscountValue,
  getOrderCondition,
  getOrderValue,
  getPercentageDisc,
  inputLabelHandle,
} from '../../../../../helper/helper';

const AmountType = ({ setFormValues, Error, setError }) => {

  const location = useLocation();
  let campaignName = location.state.campaignName;
  let updateVoucher = location.state.type;
  // const thirdData = useSelector(state => state?.rootReducer?.data);

  const [selectedDiscountTo, setSelectedDiscountTo] = useState(true);
  const [createValidation, setCreateValidation] = useState(true);
  const [selectedValidation, setSelectedValidation] = useState("Customer activity");
  const [discountType, setDiscountType] = useState('');
  const reducerData = useSelector((state) => state?.vouchers?.third);
  const initialValue = useSelector(state => state?.rootReducer?.voucherDetail);
  // useEffect(() => {
  //   if (initialValue.length !== 0) {
  //     setFromData({ ...formData, initialValue })
  //   } else {
  //     setFromData({ ...formData })
  //   }
  // }, [initialValue]);


  const changeDiscount = (e) => {
    setSelectedDiscountTo(!selectedDiscountTo)
    setFormValues('applyDiscountOn', e.target.value)
    setDiscountType(e.target.value)
    if (e.target.value !== 'wholeCart') {
      setCreateValidation(false)
    } else {
      // setCreateValidation(true)
    }
  };

  const amountOrderHandler = (e) => {
    setFormValues("condition", e.target.value)
  };
  const createValidationHandler = (e) => {
    setCreateValidation(!createValidation)
  };

  const maxDiscountHandle = (e) => {
    if (e.target.value > 0) {
      setError('')
    }
    const updateCondition = campaignName ? campaignName : initialValue.campaignType
    updateCondition === "referralCodes"
      ?
      setFormValues("referrerAmount", e.target.value)
      :
      setFormValues("maxDiscountAmount", e.target.value)
    // setFormValues("maxDiscountAmount", e.target.value)
  }

  const percentageDiscountHandle = (e) => {
    if (e.target.value > 0) {
      setError('')
    }
    const updateCondition = campaignName ? campaignName : initialValue.campaignType
    updateCondition === "referralCodes"
      ?
      setFormValues("refereeAmount", e.target.value)
      :
      setFormValues("percentageDiscount", e.target.value)
    // setFormValues("percentageDiscount", e.target.value)
  }


  const renderInfoText = useCallback(() => {
    switch (selectedDiscountTo) {
      case "wholeCart":
        return "whole Cart – Discount will be applied to the total order amount.";
      case "items":
        return "items – Discount will be applied to the products below. Each product subtotal will be discounted equally.";
      case "amountProportionally":
        return "amount proportionally – Discount will be applied to the products below. Each item will be discounted proportionally based on its share in the total order amount.";
      case "quantityProportionally":
        return "quantity proportionally – Discount will be applied to the products below. Each item will be discounted proportionally based on its quantity share in the total order items quantity.";
      default:
        return "whole Cart – Discount will be applied to the total order amount.";
    }
  }, [selectedDiscountTo]);

  const validationType = [
    {
      imageSrc: "https://img.icons8.com/ios-filled/50/000000/user.png",
      discountType: "Customer activity",
      subTitle: "(e.g. cart abandoned)",
    },
    {
      imageSrc: "https://img.icons8.com/glyph-neue/64/000000/stack.png",
      discountType: "Attributes match",
      subTitle: "(e.g. 10% off)",
    },
    {
      imageSrc: "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
      discountType: "Budget",
      subTitle: "(e.g. total order value)",
    },
    {
      imageSrc: "https://img.icons8.com/dotty/80/000000/create-order.png",
      discountType: "Order",
      subTitle: "(e.g. total amount)",
    },
  ];

  const renderValidationType = useCallback(() => {
    switch (selectedValidation) {
      case "Customer activity":
        return (
          <Row gutter={12}>
            <Col lg={12}>
              <span className="w-full">Condition</span>
              <select
                name="acitivityCondition"
                className="w-full border-blue-200 outline-none"
                placeholder="Customer segment"
                defaultValue={customerActivityHandler(initialValue, reducerData)}
                onChange={(e) => setFormValues("acitivityCondition", e.target.value)}
              >
                <option value="false">Select Customer Segment.... </option>
                <option value="true">Customer is in segment</option>
                <option value="false">Customer is not in segment </option>
              </select>
            </Col>
            <Col lg={12}>
              <div className="mt-5">
                <Form.Item name="">
                  <Button type="primary" style={{ background: '#1890ff' }} size="large">
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        );
      case "Attributes match":
        return <AttributeMatch setFormValues={setFormValues} reducerData={reducerData} initialValue={getAmountAttributeData(initialValue, reducerData).data} />;
      case "Budget":
        return (
          <>
            <Budget
              initialValue={getBudgetTotalOrder(initialValue, reducerData)}
              setFormValues={setFormValues}
              fieldName={"totalOrderValue"}
              title="Total orders value"
              show={false}
            />
            <Budget
              initialValue={getBudgetDiscount(initialValue, reducerData)}
              title="Total discounted amount"
              fieldName={"totalDiscountAmount"}
              show={false}
              setFormValues={setFormValues}
            />
            <Budget
              initialValue={getBudgetRedemption(initialValue, reducerData)}
              setFormValues={setFormValues}
              title="Total number of redemptions"
              fieldName={"totalNumberOfRedemption"}
              show={false}
            />
            <div className="mt-6">
              <div className="pb-4 text-lg font-medium">
                Redemptions per customer
              </div>
              <div className="border-l-2 border-blue-400 border-dashed">
                <div className="pl-4">
                  <Budget
                    initialValue={getBudgetPercode(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code"
                    fieldName={"perCode"}
                    show={false}
                  />
                  <Budget
                    initialValue={getBudgetPerday(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="Per code per day"
                    fieldName={"perCodePerDay"}
                    show={false}
                  />
                  <Budget
                    initialValue={getBudgetCampaign(initialValue, reducerData)}
                    setFormValues={setFormValues}
                    title="In campaign"
                    fieldName={"inCampaign"}
                    show={false}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Order":
        return (
          <>
            <div className="">
              <Row gutter={6}>
                <Col lg={8}>
                  <div className="pt-2 text-lg font-semibold">Total amount</div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <select
                      name="condition"
                      className='w-full border-blue-200'
                      placeholder="Condition"
                      value={getOrderCondition(initialValue, reducerData)}
                      onChange={amountOrderHandler}
                    >
                      <option >select condition...</option>
                      <option value="isLessThan">is less than</option>
                      <option value="IsExactly">is exactly</option>
                      <option value="isMoreThan">is more than</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <input
                      name="value"
                      type="number"
                      placeholder="Value (USD)"
                      defaultValue={getOrderValue(initialValue, reducerData)}
                      onChange={(e) => setFormValues("value", e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="pl-2">
                    <Button type="primary" style={{ background: '#1890ff' }} size="large" className="ml-7">
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <AddOrder orderType="Any" title="Any order item" setFormValues={setFormValues} reducerData={reducerData} />
                <AddOrder orderType="Every" title="Every order item" setFormValues={setFormValues} reducerData={reducerData} />
              </div>
            </div>
          </>
        );
      default:
        return "whole Cart – Discount will be applied to the total order amount.";
    }
  }, [selectedValidation]);

  useEffect(() => {
    Object.keys(initialValue).length > 0 ? setFormValues('applyDiscountOn', initialValue.applyDiscountOn) : setFormValues('applyDiscountOn', "wholeCart")
  }, [])

  return (
    <>
      <div className="p-4 bg-white">
        <Row gutter={12}>
          {/* <Col lg={24}>
            <span className="w-full -mb-4">Discount Value (USD)</span>
            <input
              name="discountValue"
              type="number"
              min={0}
              className="w-full"
              defaultValue={getDiscountValue(initialValue, reducerData)}
              placeholder="Discount Value (USD)"
              onChange={(e) => setFormValues("discountValue", e.target.value)}
            />
          </Col> */}
          <Col lg={12}>
            <span className={`block ${Error?.maxDiscountAmount && 'text-red-600'}`}>{campaignName === 'referralCodes' || initialValue.campaignType === "referralCodes" ? 'Referrer amount (USD)' : 'Discount value (USD)'}</span>
            {/* <span className={`block ${Error?.maxDiscountAmount && 'text-red-600'}`}>{inputLabelHandle('amount', campaignName, initialValue)}</span> */}
            <input
              name="maxDiscountAmount"
              type="number"
              min={1}
              className={`w-full outline-none ${Error?.maxDiscountAmount && 'border border-red-600'}`}
              defaultValue={getDiscountValue(initialValue, reducerData)}
              placeholder="Discount Value (USD)"
              // onChange={(e) => setFormValues("maxDiscountAmount", e.target.value)}
              onChange={(e) => maxDiscountHandle(e)}
            />
          </Col>
          {
            campaignName !== 'giftVouchers' && initialValue.campaignType !== 'giftVouchers' ?
              <Col lg={12}>
                <span className={`block ${Error?.percentageDiscount && 'text-red-600'}`}>{campaignName === 'referralCodes' || initialValue.campaignType === "referralCodes" ? 'Referee amount (USD)' : 'Percentage value (%)'}</span>
                <input
                  name="percentageDiscount"
                  type="number"
                  min={1}
                  className={`w-full outline-none ${Error?.percentageDiscount && 'border border-red-600'}`}
                  defaultValue={getPercentageDisc(initialValue, reducerData)}
                  placeholder="Discount Value (USD)"
                  // onChange={(e) => setFormValues("percentageDiscount", e.target.value)}
                  onChange={(e) => percentageDiscountHandle(e)}
                />
              </Col>
              : ""
          }
          <Col lg={24}>
            <div className="w-full mb-2 text-lg mt-10">Apply discount to</div>
            <div className="flex mb-2 space-x-2">
              <div className='p-4 bg-white border shadow-md w-44 h-14'>
                <label>Whole cart</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"wholeCart"}
                  name="applyDiscountOn"
                  checked={getCheckedStatus(initialValue, reducerData, selectedDiscountTo)?.whole}
                  onChange={(e) => changeDiscount(e)}
                />
              </div>
              <div className='p-4 bg-white border shadow-md w-44 h-14'>
                <label>Items</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"items"}
                  name="applyDiscountOn"
                  checked={getCheckedStatus(initialValue, reducerData, selectedDiscountTo)?.items}
                  onChange={(e) => changeDiscount(e)}
                />
              </div>
              {/* <div className='w-60 p-4 bg-white border shadow-md h-14'>
                <label>Amount proportionally</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"amountProportionally"}
                  name="applyDiscountOn"
                  checked={getCheckedStatus(initialValue, reducerData, selectedDiscountTo)?.amount}
                  onChange={(e) => changeDiscount(e)}
                />
              </div>
              <div className='w-60 p-4 bg-white border shadow-md h-14'>
                <label>Quantity proportionally</label>
                <input
                  type="radio"
                  className='float-right'
                  value={"quantityProportionally"}
                  name="applyDiscountOn"
                  checked={getCheckedStatus(initialValue, reducerData, selectedDiscountTo)?.quantity}
                  onChange={(e) => changeDiscount(e)}
                />
              </div> */}
            </div>
          </Col>
          {/* <Col lg={24} >
            {selectedDiscountTo && (
              <Info>
                <span>Apply to </span> {renderInfoText()}
                {selectedDiscountTo !== "wholeCart" && (
                  <div className="flex">
                    <div className="mt-1 mr-2">
                      <ArrowDownOutlined />
                    </div>
                    <div className="mt-2 font-bold">
                      Choose discounted products
                    </div>
                  </div>
                )}
              </Info>
            )}
          </Col> */}
        </Row>
      </div>
      <div className="mb-8">
        {/* {selectedDiscountTo === "wholeCart" && (
          <div className="px-4 py-8 mt-4 bg-white">
            <Info>
              Validation rules help you define promotions’ eligibility
              conditions based on audience, order structure, order volume,
              budget constraints,and others - <Link to="#">read more</Link>
            </Info>
          </div>
        )} */}
        {/* {createValidation ? (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div>
              <Button
                type=""
                onClick={(e) => createValidationHandler(e)}
                size="large"
              >
                Create validation rule
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                Select existing validation rule
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex py-8 mt-4">
            <div>
              <Button type="" size="large">
                <EditOutlined /> Use advance builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button
                type=""
                onClick={() => setCreateValidation(true)}
                size="large"
              >
                Quit builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                <ExclamationCircleOutlined /> Get help
              </Button>
            </div>
          </div>
        )} */}

        {/* {!createValidation && <DiscountToFilter
          setFormValues={setFormValues}
        />} */}
        {/* {!createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div className="flex flex-wrap">
              {validationType.map((item, i) => (
                <DiscountCard
                  key={i}
                  i={i}
                  item={item}
                  selectedDiv={selectedValidation}
                  onClick={() => setSelectedValidation(item.discountType)}
                />
              ))}
            </div>
          </div>
        )}
        {!createValidation && (
          <div className="px-4 py-8 mt-4 bg-white">
            {renderValidationType()}
          </div>
        )} */}
      </div>
    </>
  );
};

export default AmountType;
