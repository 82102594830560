import React from 'react'
import AppContainer from '../AppContainer'
import PageHead from '../Common/PageHead/Index'
import RedemptionsData from './RdemptionsData'

const Redemption = () => {
  return (
    <AppContainer>
      <PageHead
        title="Redemption"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          {
            path: "/", breadcrumbName: "Redemption",
          },
        ]}
        bgNone={true}
        subTitle="">
        <div>
          <RedemptionsData />
        </div>
      </PageHead>
    </AppContainer>
  )
}

export default Redemption