import { useSelector } from 'react-redux';
import { Button, Result, Spin } from 'antd';
import React from 'react';
import '../Products.scss'
import { useNavigate } from 'react-router-dom';

function Index() {
  const navigate = useNavigate();
  const { productDetail, loading } = useSelector((item) => item.rootReducer)

  const capitalizeKeyHandle = (key) => {
    const result = key[0].toUpperCase() + key.substring(1);
    const finalResult = result.replace(/[A-Z]/g, ' $&').trim()
    return finalResult;
  }
  const renderFields = (key) => {
    const productFields = ["", "title", "cartId", "orderNumbers", "skuID", "userId", "QTY", "shipping", "category", "subCategory", "customLabel1", "customLabel2", "customLabel3", "type"];
    if (productFields.includes(key)) {
      return (
        <div className='detail_body_wrapper' key={key}>
          <div className='details_body'>
            <div className=''>{capitalizeKeyHandle(key)}</div>
            <div className=''>{productDetail && productDetail[key]}</div>
          </div>
          <hr className='horizontal' />
        </div>
      )
    }
    return null
  }

  return (
    <div>
      {loading ?
        <div>
          <div className="example">
            <Spin tip="please wait..." size="large"></Spin>
          </div>
        </div>
        :
        Object.keys(productDetail).length !== 0 && loading === false
          ?
          <div className='product_detail_container'>
            <div className='product_detail_wrapper product_detail_sec'>
              <div className='product_detail_body'>
                <div className='sub_container'>
                  <p className='list_heading'>Product Details</p>
                  <div className='details'>
                    {productDetail && Object.keys(productDetail).map((key) => renderFields(key))}
                  </div>
                </div>
              </div>
            </div>
            <div className='product_section2'>
              <div className='sub_container product_create'>
                <p className='list_heading'>Recent Activity</p>
                <div className='details'>
                  <div>
                    <div className='create_product'>
                      <div className='create_product_head'>Product was created at</div>
                      <div className='create_product_body'>{productDetail && <div><span>{new Date(productDetail.createdAt).toLocaleDateString()}</span>{" - "}<span>{new Date(productDetail.createdAt).toLocaleTimeString()}</span></div>}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='sub_container product_create'>
                <p className='list_heading'>Price Section</p>
                <div className='details'>
                  <div className='product_price'>
                    <span className='mr-[5px]'>Price : </span>
                    <span className='product_body_price'>{productDetail && productDetail.price}</span>
                  </div>
                  <div className='product_price'>
                    <span className='mr-[5px]'>Total Price : </span>
                    <span className='product_body_price'>{productDetail && productDetail.totalPrice}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div>
            <div className="example">
              <Result
                // status="403"
                title='Sorry'
                subTitle=" Something went wrong,Please try again after some time.."
                extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
              />
            </div>
          </div>
      }
    </div>

  )
}

export default Index;