import React from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";

const CampainFifthStep = () => {
  const { first, second, third, fourth } = useSelector((state) => state?.vouchers);

  console.log(first, "111", second, "2222", third, "3333", fourth, "444", fourth.couponCode)

  return (
    <div className="p-8 bg-white">
      <div className="flex flex-wrap justify-between flex-col">
        <h2 className="text-xl my-8">Program Size</h2>
        <Row gutter={6}>
          {fourth?.couponName && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Coupon Name</div>
                <div className="py-2 text-base font-medium text-blue-400">
                  {fourth?.couponName}
                </div>
              </div>
            </div>
          )}
          {fourth?.couponCode && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Coupon Code</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth.couponCode === Array.isArray ? fourth.couponCode?.map((code) => <div>{code}</div>) || "" : fourth.couponCode}</div>
              </div>
            </div>
          )}
          {fourth?.maxRedeemCount && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Max Redeem Count</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.maxRedeemCount || ""}</div>
              </div>
            </div>
          )}
          {fourth?.activeDate && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Active Date</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.activeDate || ""}</div>
              </div>
            </div>
          )}
          {fourth?.expiryDate && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Expiry Date</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.expiryDate || ""}</div>
              </div>
            </div>
          )}
          {fourth?.discountType && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Discount Type</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.discountType || ""}</div>
              </div>
            </div>
          )}
          {(fourth?.maxDiscountAmount || fourth?.refereeAmount) && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Max Discount Amount</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.maxDiscountAmount || fourth?.refereeAmount || ""}</div>
              </div>
            </div>
          )}
          {(fourth?.percentageDiscount || fourth?.referrerAmount) && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Percentage Discount</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.percentageDiscount || fourth?.referrerAmount || ""}</div>
              </div>
            </div>
          )}
          {fourth?.campaignType && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Campaign Type</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.campaignType || ""}</div>
              </div>
            </div>
          )}
          {fourth?.applyDiscountOn && (
            <div lg={12} className="w-1/3">
              <div className="py-4">
                <div className="text-base">Apply Discount on</div>
                <div className="py-2 text-base font-medium text-blue-400">{fourth?.applyDiscountOn || ""}</div>
              </div>
            </div>
          )}
        </Row>
      </div>

      {/* fourth sections value */}
      {/* {
        fourth?.metaData ?
          <div className="border-b">
            <div>
              <h2 className="text-2xl my-8">Metadata</h2>
            </div>
            <Row className="w-full">
              {fourth?.metaData?.map((item) => (
                <div className="inline-flex p-3 w-2/6" key={item.id}>
                  <p className="mr-2 text-lg">{Object.keys(item)} :-</p>
                  <p className="flex-1 py-2 text-lg font-medium text-blue-400">{Object.values(item)}</p>
                </div>
              ))}
            </Row>

          </div>
          :
          ""
      } */}
    </div>
  );
};
export default CampainFifthStep;
