import React from 'react';
import Barcode from 'react-barcode';

function VoucherBarCode() {
  return (
    <div className="flex">
      <Barcode value='https://www.google.com/' />
    </div>
  )
}

export default VoucherBarCode;