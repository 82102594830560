import { getDefaultNormalizer } from "@testing-library/react";
import { Button, Empty, Popconfirm, Table } from "antd";
import { useSelector } from "react-redux";

function CustomersDetail() {
  const { loading } = useSelector((user) => user.rootReducer);

  const editHandler = () => { }
  const deleteHandler = () => { }

  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      email: 'mike.test@gmail.com',
      address: '10 Downing Street,sector-XYZ',
      customerId: 'mike_12'
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      email: "john123@gmail.com",
      address: '23 Post Street,sector-ABC',
      customerId: 'john_12'
    },
  ];

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (index, item) => <div key={index}><span key={index}>{item.name}</span>
      </div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (index, item) => <span key={index}>{item.email}</span>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (index, item) => <span key={index}>{item.address}</span>,
    },
    {
      title: 'Customer_id',
      dataIndex: 'customer_id',
      key: 'customer_id',
      render: (index, item) => <span key={index}>{item.customerId}</span>,
    },
    {
      title: 'Operations',
      key: 'operation',
      render: (text, record) => (
        <span className='operation_section'>
          <Button type="dashed" onClick={() => editHandler(record, text)} className="mr-2">Edit</Button>
          <Button type="dashed" onClick={() => editHandler(record, text)} className="mr-2">Detail</Button>
          <Popconfirm title="Confirm to delete?" onConfirm={deleteHandler.bind(text._id, text)}>
            <Button type="dashed">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="">
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record._id}
          pagination={true}
          locale={{
            emptyText: (<div style={{ textAlign: 'center' }}>
              <Empty description={false} style={{ fontSize: 20 }} />
              <p>No Data Found!</p>
            </div>)
          }}
        />
      </div>
    </div>
  )
}

export default CustomersDetail;