/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import Info from '../../../../Common/Info/Info';
import { useSelector } from 'react-redux';

const MetaData = ({ setFormValues }) => {
  const [metadata, setMetadata] = useState(true);
  const [inputData, setInputData] = useState([]);
  const metaData1 = useSelector((state) => (state?.vouchers?.fourth?.metaData));

  let key = ""
  let values = ""
  let data = [...inputData];

  const valueDataHandler = (e, index) => {
    const { name, value } = e.target
    let obj = {}
    if (name === "key") {
      key = value
    } else {
      values = value
    }
    obj[key] = values
    data[index] = obj
  }

  const deleteArray = (remove, index) => {
    let deletedData = (data.filter((item, i) => i !== index));
    setInputData(deletedData)
    remove(index)
  }
  const addHandler = (add) => {
    key = ""
    values = ""
    add()
  }
  const submitHandler = (e) => {
    setFormValues("metaData", data)
  }

  return (
    <div className='p-4 bg-white'>
      <Form.Item
        name="metadataChecked"
        valuePropName="metadataChecked"
      >
        <Checkbox size='large' checked={metadata} onChange={e => setMetadata(e.target.checked)}>
          Use the voucher's metadata schema.
        </Checkbox>
      </Form.Item>
      <div className='my-4'>
        {metadata &&
          <Info>
            This option enabled will pursue that the defined metadata will be set in the generated vouchers automatically.
            Updating metadata will update vouchers aggregated in the campaign, it will affect all vouchers which are not
            published or redeemed yet.
          </Info>
        }
      </div>
      <div>
        <Info>
          Metadata structure must be consistent with a scheme you have defined in the settings
          <Link to='#' className='pl-4 font-bold text-md'>
            <span className='pr-2'><SettingOutlined /></span>Project settings
          </Link>
        </Info>
      </div>
      <div className='py-2 font-medium border-b-2 border-blue-200 text-md'>
        <Row gutter={6}>
          <Col lg={12}>Key</Col>
          <Col lg={12}>Value</Col>
        </Row>
      </div>
      <div className='py-4'>
        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">

                  <Form.Item
                    {...restField}
                    rules={[{ required: true, message: 'Missing key' }]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Key"
                      name={`key`}
                      defaultValue={metaData1 ? Object.keys(metaData1[index]) : ""}
                      // defaultValue={metaData1 && metaData1 ? metaData1.map((item, indx) => (Object.keys(item))) : ""}
                      onChange={(e) => valueDataHandler(e, index)}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    rules={[{ required: true, message: 'Missing value' }]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Value"
                      name={`value`}
                      defaultValue={metaData1 ? Object.values(metaData1[index]) : ""}
                      // defaultValue={metaData1 && metaData1 ? metaData1.map((item, indx) => (Object.values(item))) : ""}
                      onChange={(e) => valueDataHandler(e, index)}
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => deleteArray(remove, index)} />
                </Space>
              ))}
              <div className=''>
                <div className='w-1/4 leading-4'>
                  <Form.Item>
                    <Button type="button" size='large' className='ant-btn ant-btn-primary ant-btn-lg' onClick={(e) => submitHandler(e)}>
                      Add
                    </Button>
                    <br />
                    <br />
                    <Button type="dashed" size='large' onClick={() => addHandler(add)} block icon={<PlusOutlined />}>
                      Add next property
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </>
          )}
        </Form.List>
      </div>
    </div>
  );
};

export default MetaData;