import React, { useEffect, useState } from "react";
import { pieChartAction } from "../Components/Vouchers/services/users";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DashboardCard12 from "../partials/dashboard/DashboardCard12";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import FilterButton from "../partials/actions/FilterButton";
import Datepicker from "../partials/actions/Datepicker";
import AppContainer from "../Components/AppContainer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Banner from "../partials/Banner";

function Dashboard() {
  const [pieChartData, setPieChartData] = useState([]);
  const { pieChart } = useSelector((item) => item.rootReducer)
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('userToken')

  useEffect(() => {
    dispatch(pieChartAction(token)).then((resp) => setPieChartData(resp?.data))
  }, [token]);

  return (
    <AppContainer>
      <main>
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <WelcomeBanner />
          <div className="flex flex-col gap-5 flex-wrap">
            <DashboardCard02 pieChartData={pieChartData} />
            <div className="flex flex-wrap justify-between gap-5 max-md:block w-full">
              <DashboardCard03 pieChartData={pieChartData?.discountType} />
              <DashboardCard04 pieChartData={pieChartData?.campaignType} />
            </div>
            <div className="sm:flex sm:justify-end sm:items-center mt-8">
              <div className="flex items-baseline justify-between w-full">
                <div className="text-[25px]">
                  Redemptions Monitoring
                </div>
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* <FilterButton /> */}
                  <Datepicker />
                  {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg
                      className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between max-md:block w-full">
              <DashboardCard07 />
            </div>
            {/* <DashboardCard11 /> */}
            {/* Card (Recent Activity) */}
            {/* <DashboardCard12 /> */}
          </div>
        </div>
      </main>
      <Banner />
    </AppContainer>
  );
}

export default Dashboard;