/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from "react";
// import { List, Checkbox } from "antd";

// const FilterDataList = () => {
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState([]);
//   const [selectedList, setSelectedList] = useState([]);
//   var clickedData = {}

//   const loadMoreData = () => {
//     if (loading) {
//       return;
//     }
//     setLoading(true);
//     fetch(
//       "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
//     )
//       .then((res) => res.json())
//       .then((body) => {
//         var modifiedList = [];
//         body.results.map(item => {
//           const itemList = {
//             ...item,
//             checkedItem: false,
//           }
//           modifiedList.push(itemList)
//           setLoading(false);
//         })
//         setData([...data, modifiedList]);
//       })
//       .catch(() => {
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     loadMoreData();
//   }, []);

//   console.log("dta", data);

//   function findMatchIndex(indexData) {
//     console.log(indexData)
//     return indexData.email === clickedData?.email;
//   }

//   const addDataToList = (event, item, type) => {
//     console.log(event.target.checked, "ee", item, "item", type);
//     clickedData = item;
//     console.log("dta", clickedData);
//     const checkExist = data.length === 0 ? data?.[0].findIndex(findMatchIndex) : data?.findIndex(findMatchIndex);
//     console.log(checkExist, "testing")
//     if (event.target.checked) {
//       if (type === 'All') {
//         console.log("checked", data?.[0]);
//         var updatedData = data?.[0];
//         data?.[0].map(item =>
//           updatedData.push({ ...item, checkedItem: item.checkedItem = true }),
//         )
//         setData(updatedData);
//         setSelectedList([item]);
//       } else if (type === '') {
//         var updatedDataSingle = data?.[0];
//         updatedDataSingle[checkExist].checkedItem = true;
//         setSelectedList([...selectedList, item]);
//       }
//     } else if (!event.target.checked && type === 'All') {
//       console.log("unchecked---", data?.[0]);
//       var uncheckedData = data?.[0];
//       data?.[0].map(item =>
//         uncheckedData.push({ ...item, checkedItem: item.checkedItem = false }),
//       )
//       setData(uncheckedData);
//       setSelectedList([item]);
//     } else if (!event.target.checked && type === '') {
//       var uncheckDataSingle = data?.[0];
//       uncheckDataSingle[checkExist].checkedItem = false;
//       setSelectedList([...selectedList, item]);
//     }
//   }
//   console.log(selectedList, "testingdemo");
//   console.log("updatddta", data, selectedList);

//   return (
//     <div>
//       <div className="flex border-b-2 border-blue-200" style={{ padding: "0 16px" }}>
//         <div className="ml-2">
//           <Checkbox onChange={(e) => addDataToList(e, data, 'All')} />
//         </div>
//         <div className="px-4 pb-6">Product</div>
//       </div>
//       <div
//         id="scrollableDiv"
//         style={{
//           height: 400,
//           overflow: "auto",
//           padding: "0 16px",
//         }}
//       >
//         <List
//           dataSource={data.length === 2 ? data?.[0] : data}
//           renderItem={(item) => (
//             <List.Item key={item.id}>
//               <List.Item.Meta
//                 avatar={
//                   <span className="ml-2">
//                     <Checkbox
//                       checked={item?.checkedItem}
//                       onChange={(e) => addDataToList(e, item, '')}
//                     />
//                   </span>
//                 }
//                 title={<span>{item?.name?.last}</span>}
//                 description={item?.email}
//               />
//               {/* <div>Content</div> */}
//             </List.Item>
//           )}
//         />
//         {/* </InfiniteScroll> */}
//       </div>
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const FilterDataList = (props) => {
  const { orderType, setSelectedListItems, reducerData } = props;

  const initialValue = useSelector(state => state?.rootReducer?.data);

  const reducerDataHandler = () => {
    if (initialValue && initialValue?.discountValue?.wholecart?.order?.anyOrderItem?.length > 0 && orderType === "Any") {
      return ([...initialValue?.discountValue?.wholecart?.order?.anyOrderItem])
    } else if (orderType === "Any" && reducerData?.anyOrderItem) {
      return ([...reducerData?.anyOrderItem])
    } else if (initialValue && initialValue?.discountValue?.wholecart?.order?.everyOrderItem?.length > 0 && orderType === "Every") {
      return ([...initialValue?.discountValue?.wholecart?.order?.everyOrderItem])
    } else if (orderType === "Every" && reducerData?.everyOrderItem) {
      return ([...reducerData?.everyOrderItem])
    } else {
      return []
    }
  };

  const getReducerData = reducerDataHandler();

  const [checkedList, setCheckedList] = useState(getReducerData);
  const [checkAll, setCheckAll] = useState(false);

  const userData = [
    {
      checkedItem: false,
      name: "smith",
      email: "smith123@gmail.com",
      gender: "male",
      nat: "ut"
    },
    {
      checkedItem: false,
      name: "johan",
      email: "johan123@gmail.com",
      gender: "male",
      nat: "xt"
    },
    {
      checkedItem: false,
      name: "sammy",
      email: "sammy123@gmail.com",
      gender: "male",
      nat: "dy"
    },
    {
      checkedItem: false,
      name: "julie",
      email: "julie123@gmail.com",
      gender: "female",
      nat: "qi"
    },
    {
      checkedItem: false,
      name: "garry",
      email: "garry123@gmail.com",
      gender: "male",
      nat: "fo"
    },
    {
      checkedItem: false,
      name: "jackie",
      email: "jackie123@gmail.com",
      gender: "female",
      nat: "lt"
    },
    {
      checkedItem: false,
      name: "perry",
      email: "perry123@gmail.com",
      gender: "female",
      nat: "mw"
    },
  ];

  const onChange = (e) => {
    setCheckedList([...checkedList, e.target.name]);
    if (!e.target.checked) {
      setCheckedList(checkedList.filter(item => item !== e.target.name));
    }
  };
  const onCheckAllChange = () => {
    setCheckAll(!checkAll);
    setCheckedList(userData?.map(li => li.name))
    if (checkAll) {
      setCheckedList([]);
    }
  }
  useEffect(() => {
    setSelectedListItems(checkedList?.filter((item) => { return item !== undefined }));
  }, [checkedList]);

  const orderSummaryHandler = () => {
    if (checkedList?.length !== 0) {
      return checkedList
    } else if (checkedList?.length === 0 && orderType === "Any") {
      return reducerData?.anyOrderItem
    } else if (checkedList?.length === 0 && orderType === "Every") {
      return reducerData?.everyOrderItem
    }
  }

  return (
    <div>
      <>
        <div>
          <label className="mb-5">
            <input
              type="checkbox"
              onChange={onCheckAllChange}
              checked={checkAll}
            />
            check all
          </label>
        </div>
        <div className="flex">
          <div className="block py-2 px-4 w-full">
            <ul className="w-48 text-sm font-medium">
              {
                userData && userData?.map((item) => (
                  <>
                    <input
                      className="m-3"
                      name={item.name}
                      type="checkbox"
                      onChange={(e) => onChange(e)}
                      checked={orderSummaryHandler()?.includes(item?.name)}
                    />
                    <span>{item.name}</span>
                  </>
                ))
              }
            </ul>
          </div>
        </div>
      </>
    </div>
  );
};

export default FilterDataList;