import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import ErrorBoundary from "../Common/ErrorBoundary/ErrorBoundary";

function AppContainer({ children }) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    if (hasError) { setHasError(false) }
  }, [location.key])

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <ErrorBoundary hasError={hasError} setHasError={setHasError}>
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default AppContainer;
