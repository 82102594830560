const initState = {
  isLogin: false,
  loading: false,
  loginData: [],
  userToken: [],
  categoryList: [],
  data: [],
  userKeys: [],
  registerUser: [],
  voucherDetail: {},
  getProduct: [],
  productDetail: [],
  emailList: [],
  lineChart: [],
  pieChart: []
}

const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        userToken: action.payload,
        isLogin: true
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
        isLogin: true
      }
    case 'LOGIN_USER_ERROR': {
      return {
        ...state,
        loginData: action.payload,
        loginError: true
      }
    }
    case 'CATEGORY_LIST': {
      return {
        ...state,
        categoryList: action.payload,
      }
    }
    case 'VOUCHERS':
      return {
        ...state,
        data: action.payload,
        isLogin: true
      }
    case 'CREATE_USER_KEYS':
      return {
        ...state,
        userKeys: action.payload,
        isLogin: true
      }
    case 'GET_USER_KEYS':
      return {
        ...state,
        userKeys: action.payload,
        isLogin: true
      }
    case 'LOGOUT_USER':
      return {
        ...state,
        isLogin: false
      }
    case 'GET_VOUCHER_DETAIL':
      return {
        ...state,
        voucherDetail: action.payload
      }
    case 'REGISTER_USER':
      return {
        ...state,
        registerUser: action.payload
      }
    case 'REGISTER_USER_ERROR':
      return {
        ...state,
        registrationError: action.payload,
      }
    case 'GET_PRODUCT_HISTORY':
      return {
        ...state,
        getProduct: action.payload,
      }
    case 'GET_Product_DETAIL':
      return {
        ...state,
        productDetail: action.payload,
      }
    case 'ALL_EMAIL':
      return {
        ...state,
        emailList: action.payload,
      }
    case 'LINE_CHART_DATA':
      return {
        ...state,
        lineChart: action.payload,
      }
    case 'PIE_CHART_DATA':
      return {
        ...state,
        pieChart: action.payload,
      }
    default: // need this for default case
      return state
  }
}

export default rootReducer;