import React from 'react';
import AppContainer from '../AppContainer';
import PageHead from '../Common/PageHead/Index';
import Index from './Index';


function Products() {
  return (
    <AppContainer>
      <PageHead
        title="Products"
        breadcrumbs={[
          { path: "/", breadcrumbName: "Home" },
          {
            path: "/", breadcrumbName: "Products",
          },
        ]}
        bgNone={true}
        subTitle="">
        <div>
          <Index />
        </div>
      </PageHead>
    </AppContainer>
  )
}

export default Products;