import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import CouponCard01 from './cards/CouponCard01';
import CouponCard02 from './cards/CouponCard02';
import CouponCard03 from './cards/CouponCard03';
import CouponCard04 from './cards/CouponCard04';
import './DashboardCard.scss'

function DashboardCard02() {
  const { pieChart } = useSelector((item) => item?.rootReducer)
  return (
    <div className="flex flex-wrap justify-between gap-5 max-md:block w-full">
      <p className='text-[25px]'>Campaign Monitoring</p>
      {pieChart && pieChart?.tabData &&
        <Row gutter={[0, 24]} className="campaign_wrapper w-full">
          <Col className='campaign_card rounded-[4px]'><CouponCard01 pieChart={pieChart?.tabData[0]} /></Col>
          <Col className='campaign_card rounded-[4px]'><CouponCard02 pieChart={pieChart?.tabData[1]} /></Col>
          <Col className='campaign_card rounded-[4px]'><CouponCard03 pieChart={pieChart?.tabData[2]} /></Col>
          <Col className='campaign_card rounded-[4px]'><CouponCard04 pieChart={pieChart?.tabData[3]} /></Col>
        </Row>}
    </div>
  )
}

export default DashboardCard02;