import axios from 'axios'

// LocalStorageService
const AxiosInstance = axios.create();
// Add a request interceptor
AxiosInstance.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("userToken")
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json';
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)
AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const access_token = sessionStorage.getItem("userToken");
    if (error.response.status === 401 && access_token) {
      //Your logic to refresh token and reattempt request
      sessionStorage.clear()
      window.location.replace("/")
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);
export default AxiosInstance