/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { DownOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import menu from '../../../assets/menu2.png';
import './DropdownHandle.css';
import { Link } from 'react-router-dom';

function DropdownHandle({ demo, id }) {
  const items = useMemo(() => [
    ...(demo === "voucher") ? [
      {
        label: <Link to="#">Bar Code</Link>,
        key: '0',
      },
      {
        label: <Link to="#">QR code</Link>,
        key: '1',
      }] : [{
        label: <Link to={`/products/summary/${id}`}>Details</Link>,
        key: '1',
      }]
  ], [demo]);
  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottomRight"
      trigger={['click']}
    >
      <Link to="#" key={1} onClick={(e) => e.preventDefault()}>
        <Space>
          <img src={menu} alt="menu icon" />
        </Space>
      </Link>
    </Dropdown>
  )
}

export default DropdownHandle;