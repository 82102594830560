import React from 'react';
import send from '../../../assets/cardsImage/send2.png'
import './CouponCards.scss'


function CouponCard03({ pieChart }) {
  return (
    <div className="card_wrapper relative border-0 shadow-none text-[#4a4a4a] box-border h-full w-full">
      <div className='cards'>
        <div><img src={send} alt="gift card logo" className='cards_img' /></div>
        <div className='flex flex-col justify-between'>
          <p className='card_body'>Successfully Delivered Messages</p>
          <div className='text-[25px] font-medium text-[#4a4a4a] leading-9 ml-[30%]'>{pieChart?.emailsCount ? pieChart?.emailsCount : 0}</div>
        </div>
      </div>
    </div>
  )
}

export default CouponCard03;