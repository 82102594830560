import React from 'react';
import Icon from '../../assets/cardsImage/discount1.png';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import './DashboardCard.scss'
import { useSelector } from 'react-redux';

function DashboardCard07() {

  let { lineChart } = useSelector((item) => item.rootReducer)
  const data = [
    {
      Title: "01 Feb",
      pv: 2400,
    },
    {
      Title: "02 Feb",
      pv: 1398,
    },
    {
      Title: "03 Feb",
      pv: 9800,
    },
    {
      Title: "04 Feb",
      pv: 3908,
    },
    {
      Title: "05 Feb",
      pv: 4800,
    },
    {
      Title: "06 Feb",
      pv: 3800,
    },
    {
      Title: "07 Feb",
      pv: 4300,
    },
    {
      Title: "08 Feb",
      pv: 2300,
    },
    {
      Title: "09 Feb",
      pv: 4300,
    },
    {
      Title: "10 Feb",
      pv: 2300,
    },
    {
      Title: "11 Feb",
      pv: 7500,
    },
    {
      Title: "12 Feb",
      pv: 4700,
    },
    {
      Title: "13 Feb",
      pv: 4000,
    },

  ];

  return (
    <div className="dashboard_card07">
      <div className="px-5 py-4">
        <header className="flex items-center">
          {/* Icon */}
          <img src={Icon} width="35" height="35" alt="Icon 03" />
          <h2 className="font-normal text-[#787878] text-[20px] ml-3">Redemptions sorted by result</h2>
        </header>
      </div>
      <ResponsiveContainer width="100%" aspect={3.5}>
        <LineChart
          width={500}
          height={200}
          data={lineChart}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#3e7ce4"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
export default DashboardCard07;